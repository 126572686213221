export enum Currency {
	BEP20 = "BEP20",
	BNB = "BNB",
	TRC20 = "TRC20",
	TRX = "TRX",
	TON = "TON",
	JETTON = "JETTON",
	ERC20 = "ERC20",
	NONE = "",
}
