import {FC} from 'react'
import PropTypes from "prop-types";
import {IconType} from "../../types/auth/icon.type";

const AccountIcon: FC<IconType> = ({width, height, className, isActive}) => {

    const firstColor = isActive ? '#6366F1' : '#475569'
    const SecondColor = isActive ? '#A5B4FC' : '#94A3B8'

    return (
        <svg className={className} width={width} height={height} viewBox="0 0 23 22" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3545 4V18" stroke={firstColor} strokeWidth="2"/>
            <path d="M4.35449 4V18" stroke={firstColor} strokeWidth="2"/>
            <path d="M18.3545 4L4.35449 18" stroke={firstColor} strokeWidth="2"/>
            <circle cx="4.35449" cy="4" r="4" fill={SecondColor}/>
            <circle cx="18.3545" cy="4" r="4" fill={SecondColor}/>
            <circle cx="4.35449" cy="18" r="4" fill={SecondColor}/>
            <circle cx="18.3545" cy="18" r="4" fill={SecondColor}/>
        </svg>
    )
}

AccountIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isActive: PropTypes.bool,
    className: PropTypes.string,
};

AccountIcon.defaultProps = {
    width: 23,
    height: 22
};
export default AccountIcon
