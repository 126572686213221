import {FC, MouseEvent, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Position} from "../../enums/position.enum.ts";
import {TablePaginationType} from "../../types/components/table-pagination.type.ts";
import {usePagination} from "../../hooks/pagination.hook.ts";

const TablePagination: FC<TablePaginationType> = ({
  onPageChange,
  total,
  siblingCount,
  currentPage,
  perPage,
  position,
  separate
}) => {

    const paginationRange = usePagination({
        total,
        perPage,
        siblingCount,
        currentPage
    });

    const onNext = useCallback((e: MouseEvent): void => {
        e.preventDefault();
        onPageChange(currentPage + 1);
    }, [currentPage, onPageChange]);

    const onPrevious = useCallback((e: MouseEvent): void => {
        e.preventDefault();
        onPageChange(currentPage - 1);
    }, [currentPage, onPageChange]);


    if (paginationRange === undefined) {
        return null;
    }

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const lastPage: number = paginationRange[paginationRange.length - 1] as number;

    return (
        <div className="select-none">
            <div className={`flex justify-${position}`}>
                <nav className="flex" role="navigation" aria-label="Navigation">

                    <div className="mr-2">
                        {currentPage === 1
                            ? <span
                                className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 text-slate-300 cursor-not-allowed">
                                <span className="sr-only">Previous</span><wbr/>
                                  <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                    <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z"/>
                                  </svg>
                                </span>
                            : <a href="#" onClick={onPrevious}
                                 className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-slate-200 text-slate-600 hover:text-white shadow-sm">
                                    <span className="sr-only">Next</span>
                                    <wbr/>
                                    <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                        <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z"/>
                                    </svg>
                                </a>
                        }
                    </div>

                    <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
                        {paginationRange.map((pageNumber, i) => {
                            if (pageNumber === separate) {
                                return (<li key={i}><span
                                    className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white border border-slate-200 text-indigo-500 cursor-not-allowed">{separate}</span>
                                </li>);
                            }

                            if (pageNumber === currentPage) {
                                return (<li key={i}><span
                                    className={`inline-flex items-center justify-center ${pageNumber === 1 ? 'rounded-l' : ''} ${pageNumber === lastPage ? 'rounded-r' : ''} leading-5 px-3.5 py-2 bg-white border border-slate-200 text-indigo-500 cursor-not-allowed`}>{pageNumber}</span>
                                </li>)
                            }

                            return (<li key={i} onClick={() => onPageChange(pageNumber as number)}><a
                                className={`inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white hover:bg-indigo-500 border border-slate-200 text-slate-600 hover:text-white ${pageNumber === 1 ? 'rounded-l' : ''} ${pageNumber === lastPage ? 'rounded-r' : ''}`}
                                href="#">{pageNumber}</a></li>);
                        })}
                    </ul>

                    <div className="ml-2">
                        {currentPage === lastPage
                            ? <span
                                className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 text-slate-300 cursor-not-allowed">
                                  <span className="sr-only">Previous</span><wbr/>
                                  <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z"/>
                                  </svg>
                                </span>
                            : <a href="#" onClick={onNext}
                                 className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-slate-200 text-slate-600 hover:text-white shadow-sm">
                                <span className="sr-only">Next</span>
                                <wbr/>
                                <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z"/>
                                </svg>
                            </a>
                        }
                    </div>
                </nav>
            </div>
        </div>
    );


};

TablePagination.propTypes = {
    total: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    perPage: PropTypes.number.isRequired,
    position: PropTypes.oneOf(Object.values(Position)),
    siblingCount: PropTypes.number,
    separate: PropTypes.string,
};

TablePagination.defaultProps = {
    position: Position.CENTER,
    siblingCount: 1,
    separate: '...'
}

export default TablePagination;
