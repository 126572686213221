import 'react-toastify/dist/ReactToastify.css'
import './style/style.scss';

import React from 'react'
import ReactDOM from 'react-dom/client'
// import { registerSW } from "virtual:pwa-register";
import App from './App.tsx'
import {Provider} from "react-redux";
import store from "./store";
import ApolloCustomProvider from "./common/providers/apollo-custom.provider.tsx";


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <ApolloCustomProvider>
                <App/>
            </ApolloCustomProvider>
        </Provider>
    </React.StrictMode>
)


// if ("serviceWorker" in navigator) {
//     // && !/localhost/.test(window.location)) {
//     registerSW();
// }
