import PropTypes from "prop-types";
import {FC} from "react";
import {IconType} from "../../types/auth/icon.type.ts";

const PaymentIcon: FC<IconType> = ({width, height, className, isActive}) => {

    const firstColor = isActive ? '#6366F1' : '#475569'
    const SecondColor = isActive ? '#A5B4FC' : '#94A3B8'

    return (
        <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 16V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6M17 8V5C17 4.73478 16.8946 4.48043 16.7071 4.29289C16.5196 4.10536 16.2652 4 16 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6C4 6.53043 4.21071 7.03914 4.58579 7.41421C4.96086 7.78929 5.46957 8 6 8H18C18.2652 8 18.5196 8.10536 18.7071 8.29289C18.8946 8.48043 19 8.73478 19 9V12L17 8Z"
                stroke={SecondColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M20 12V16H16C15.4696 16 14.9609 15.7893 14.5858 15.4142C14.2107 15.0391 14 14.5304 14 14C14 13.4696 14.2107 12.9609 14.5858 12.5858C14.9609 12.2107 15.4696 12 16 12H20Z"
                stroke={firstColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

PaymentIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isActive: PropTypes.bool,
    className: PropTypes.string,
};

PaymentIcon.defaultProps = {
    width: 24,
    height: 24
};

export default PaymentIcon
