import {FC, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setProfile, userSelector} from "../../../store/user.store.ts";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {MdClose} from "react-icons/md";
import {FiEye, FiEyeOff} from "react-icons/fi";
import {UpdateProfileType} from "../../../common/types/profile/update-profile.type.ts";
import {useMutation} from "@apollo/client";
import {UPDATE_PROFILE_MUTATION} from "../../../api/graphql/mutations/update-profile.mutation.ts";
import {UpdateProfileResponseType} from "../../../common/types/profile/update-profile-response.type.ts";
import {ProfileStateType} from "../../../common/types/store/user-state.type.ts";
import {AppDispatch} from "../../../store";



const validationSchema: Yup.ObjectSchema<UpdateProfileType> = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().min(6).notRequired(),
    passwordConfirmation: Yup.string().when('password', (password: string[], schema: Yup.StringSchema) => {
        return password[0] && password[0].length > 0
            ? schema.min(6)
                .oneOf([Yup.ref('password'), ""], 'Passwords must match')
                .required("Required")
            : schema.notRequired();
    })
});

const PersonalPage: FC = () => {
    const dispatcher: AppDispatch = useDispatch();
    const { profile: {name, email} } = useSelector(userSelector);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
    const [updateProfile, updateProfileData] = useMutation<UpdateProfileResponseType>(UPDATE_PROFILE_MUTATION);

    const handleSubmit = useCallback(async (values: UpdateProfileType, {resetForm}: any): Promise<void> => {
        await updateProfile({
            variables: {dto: values}
        });
        resetForm({password: '', passwordConfirmation: ''});
    }, [updateProfile]);

    useEffect(() => {
        if (updateProfileData.data) {
            const response: ProfileStateType = updateProfileData.data.updateProfile;
            dispatcher(setProfile(response));
        }
    }, [dispatcher, updateProfileData.data]);


    return (
        <div className="grow">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name,
                    email,
                    password: '',
                    passwordConfirmation: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue, errors, touched, isValid }) => (
                    <Form>
                        <div className="p-6 space-y-6 min-h-[500px]">
                            <h2 className="text-2xl text-slate-800 font-bold mb-5">My Account</h2>
                            <section>

                                <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                                    <div className="sm:w-1/3 relative">
                                        <label className="block text-sm font-medium mb-1" htmlFor="name">Name</label>
                                        <div className="relative">
                                            <Field
                                                id="name"
                                                type="name"
                                                name="name"
                                                placeholder="Name"
                                                className={`form-input w-full ${errors.name && touched.name ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                            />
                                            <button onClick={() => setFieldValue('name', '')} className="absolute inset-0 left-auto group" type="button">
                                                <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                            </button>
                                        </div>
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-rose-500 text-xs absolute bottom-auto left-0"
                                        />
                                    </div>
                                    <div className="sm:w-1/3 relative">
                                        <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
                                        <div className="relative">
                                            <Field
                                                id="email"
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                className={`form-input w-full ${errors.email && touched.email ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                            />

                                            <button onClick={() => setFieldValue('email', '')} className="absolute inset-0 left-auto group" type="button">
                                                <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                            </button>
                                        </div>
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="text-rose-500 text-xs absolute bottom-auto left-0"
                                        />
                                    </div>
                                </div>

                            </section>
                            <section>
                                <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">Password</h2>
                                <div className="grid md:grid-cols-3 md:grid-flow-col">
                                    <div className="flex flex-col pt-5 w-full md:col-span-1">
                                        <div className="mb-5 relative">
                                            <label className="block text-sm font-medium mb-1" htmlFor="password">New password</label>
                                            <div className="relative">
                                                <Field
                                                    id="password"
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    placeholder="Password"
                                                    className={`form-input w-full ${errors.password && touched.password ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                                />

                                                <button onClick={() => setShowPassword(!showPassword)} className="absolute inset-0 left-auto right-6 group" type="button" aria-label="Search">
                                                    {showPassword
                                                        ? <FiEye className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                                        : <FiEyeOff className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                                    }
                                                </button>

                                                <button onClick={() => setFieldValue('password', '')} className="absolute inset-0 left-auto group" type="button" aria-label={showPassword ? "Hide password" : "Show password"}>
                                                    <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                                </button>

                                            </div>

                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="text-rose-500 text-xs absolute bottom-auto left-0"
                                            />
                                        </div>
                                        <div className="mb-2 relative">
                                            <label className="block text-sm font-medium mb-1" htmlFor="password_confirmation">Confirm new password</label>
                                            <div className="relative">
                                                <Field
                                                    id="password_confirmation"
                                                    type={showPasswordConfirmation ? "text" : "password"}
                                                    name="passwordConfirmation"
                                                    placeholder="Password Confirmation"
                                                    className={`form-input w-full ${errors.passwordConfirmation && touched.passwordConfirmation ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                                />

                                                <button onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)} className="absolute inset-0 left-auto right-6 group" type="button" aria-label="Search">
                                                    {showPasswordConfirmation
                                                        ? <FiEye className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                                        : <FiEyeOff className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                                    }
                                                </button>

                                                <button onClick={() => setFieldValue('passwordConfirmation', '')} className="absolute inset-0 left-auto group" type="button">
                                                    <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                                </button>
                                            </div>

                                            <ErrorMessage
                                                name="passwordConfirmation"
                                                component="div"
                                                className="text-rose-500 text-xs absolute bottom-auto left-0"
                                            />
                                        </div>
                                    </div>
                                    <div className="pt-5 md:col-span-2 md:pl-10 lg:pl-20">
                                        <p className='font-semibold text-black text-sm mb-2'>Password requirements:</p>
                                        <p className='font-normal text-black text-sm mb-3'>Ensure that these requirements are met:</p>
                                        <ol className='list-disc pl-5'>
                                            <li className='text-sm'>Minimum 8 characters long - the more, the better</li>
                                            <li className='text-sm'>At least one lowercase character</li>
                                            <li className='text-sm'>At least one uppercase character</li>
                                            <li className='text-sm'>At least one number, symbol, or whitespace character</li>
                                        </ol>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <footer>
                            <div className="flex flex-col px-6 py-5 border-t border-slate-200">
                                <div className="flex self-end">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting || !isValid || updateProfileData.loading}
                                        className={`btn ${(isSubmitting || !isValid || updateProfileData.loading) ? 'disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed shadow-none' : ''}bg-indigo-500 hover:bg-indigo-600 text-white ml-3`}
                                    >
                                        {isSubmitting || updateProfileData.loading
                                            ? <>
                                                <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                                </svg>
                                                <span className="ml-2">Loading</span>
                                            </>
                                            : 'Save Changes'
                                        }
                                    </button>
                                </div>
                            </div>
                        </footer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PersonalPage;


