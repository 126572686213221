import { FC, MouseEvent, useCallback, useEffect } from "react";
import {
	formatNumber,
	getDescriptionByType,
	getExplorerLinkByType,
	getIconByCurrency,
} from "../../../utils/currency.util.ts";
import { openInNewTab } from "../../../utils/common.util.ts";
import { TableItemTempAddressType } from "../../../types/components/table-item-temp-address.type.ts";
import { MdOutlineRepeat } from "react-icons/md";
import { CurrencyType } from "../../../enums/currency-type.enum.ts";
import Tooltip from "../../tooltip/tooltip.component.tsx";
import { Position } from "../../../enums/position.enum.ts";
import { TEMP_ADDRESS_UPDATE_BALANCES_MUTATION } from "../../../../api/graphql/mutations/temp-address-update-balances.mutation.ts";
import { useMutation } from "@apollo/client";
import NotificationToast from "../../toasts/notification.toast.tsx";
import { toast } from "react-toastify";
import { NotificationType } from "../../../enums/notification.enum.ts";

const TableItemTempAddress: FC<TableItemTempAddressType> = ({ item }) => {
	const [updateBalance, updateBalanceData] = useMutation(
		TEMP_ADDRESS_UPDATE_BALANCES_MUTATION,
	);

	useEffect(() => {
		if (updateBalanceData.error) {
			toast(
				<NotificationToast
					type={NotificationType.WARNING}
					title="Temp Address"
					message={updateBalanceData.error.message}
				/>,
				{
					toastId: "temp-address-update-balance-error",
				},
			);
		}
	}, []);

	const linkHandler = useCallback(
		(e: MouseEvent<HTMLDivElement>, url?: string): void => {
			e.stopPropagation();
			if (url) {
				openInNewTab(getExplorerLinkByType(item.type, url));
			}
		},
		[item.type],
	);

	const updateBalancesHandler = useCallback(async () => {
		await updateBalance({
			variables: { id: item.id },
		});
	}, [updateBalance, item.id]);

	return (
		<tr className="select-none">
			<td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
				<div className={`font-medium`}>{getDescriptionByType(item.type)}</div>
			</td>
			<td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
				<div
					onClick={(e) => linkHandler(e, item.address)}
					className={`block w-fit font-medium text-indigo-500 hover:cursor-pointer hover:underline`}
				>
					{item.address}
				</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
				<div className="flex flex-col items-start">
					{item.balances
						.slice()
						.sort((a, b) => a.currency.localeCompare(b.currency))
						.map((balance, index) => (
							<div
								key={index}
								className="flex items-center justify-start font-medium"
							>
								<img
									className="w-4 h-4 rounded-full mr-2"
									src={getIconByCurrency(balance.currency)}
									alt={balance.type}
								/>
								<p
									className={`${parseFloat(balance.balance) > 0 && "text-black font-bold"}`}
								>
									{formatNumber(
										balance.balance,
										balance.currencyType === CurrencyType.TOKEN ? 2 : 5,
									)}
								</p>
							</div>
						))}
				</div>
			</td>
		</tr>
	);
};

export default TableItemTempAddress;
