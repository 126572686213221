import {FC, useCallback, MouseEvent, useMemo} from 'react';
import {getExplorerLinkByType, getNetworkIcon} from "../../../utils/currency.util.ts";
import {openInNewTab, toShort2} from "../../../utils/common.util.ts";
import {TableItemPayoutAddressType} from "../../../types/components/table-item-payout-address.type.ts";
import {PayoutAddressStatus} from "../../../enums/payout-address-status.enum.ts";
import {openDiscardModel, setPayoutAddress} from "../../../../store/payout-addresses.store.ts";
import {AppDispatch} from "../../../../store";
import {useDispatch} from "react-redux";
import {Position} from "../../../enums/position.enum.ts";
import AddressComponent from "../../general/address.component.tsx";


const TableItemPayoutAddress: FC<TableItemPayoutAddressType> = ({item}) => {
    const dispatcher: AppDispatch = useDispatch();

    const linkHandler = useCallback((e: MouseEvent<HTMLDivElement>, url?: string): void => {
        e.stopPropagation();
        if (url) {
            openInNewTab(url);
        }
    }, []);

    // const approveHandler = useCallback(() => {
    //     dispatcher(openApprovalModel(item.id));
    //     dispatcher(setPayoutAddress(item));
    // }, [dispatcher, item]);

    const discardHandler = useCallback(() => {
        dispatcher(openDiscardModel(item.id));
        dispatcher(setPayoutAddress(item));
    }, [dispatcher, item]);

    const addressButtons = useMemo(() => {
        switch (item.status) {
            // case PayoutAddressStatus.AWAITED:
            //     return <>
            //         <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-green-500 text-white transition-all active:scale-95 hover:bg-green-600" onClick={approveHandler}>Approve</button>
            //         <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-rose-500 text-white transition-all active:scale-95 hover:bg-rose-600" onClick={discardHandler}>Discard</button>
            //     </>
            // case PayoutAddressStatus.DISCARDED:
            //     return <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-green-500 text-white transition-all active:scale-95 hover:bg-green-600" onClick={approveHandler}>Approve</button>;
            case PayoutAddressStatus.APPROVED:
                return <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-rose-500 text-white transition-all active:scale-95 hover:bg-rose-600" onClick={discardHandler}>Delete</button>
        }
    }, [discardHandler, item.status]);

    return (
        <tr>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className={`font-medium`}>
                    <img className="w-8 h-8 rounded-full" src={ getNetworkIcon(item.walletType)} alt={item.walletType} />
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="block w-fit">
                    <AddressComponent
                        onClick={(e) => linkHandler(e, getExplorerLinkByType(item.walletType, item.address))}
                        address={item.address}
                        showCopyMessageTimeout={500}
                        position={Position.RIGHT}
                    />
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap max-w-[100px]">
                <div className={`font-medium w-fit`}>{toShort2(item.description, 14, 0)}</div>
            </td>
            {/*<td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap`}>*/}
            {/*    <div className="relative h-full w-full">*/}
            {/*        <div className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 font-medium rounded-full px-2.5 py-0.5 ${payoutAddressStatusColor(item.status)}`}>{item.status.toLowerCase()}</div>*/}
            {/*    </div>*/}
            {/*</td>*/}
            <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap `}>
                <div className="relative flex justify-center items-center ">
                    {addressButtons}
                </div>
            </td>
        </tr>
    );
};


export default TableItemPayoutAddress;
