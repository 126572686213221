import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {BALANCE_SLICE_NAME} from "../common/constants/store.constant.ts";
import {RootState} from "./index.ts";
import {BalanceStateType} from "../common/types/store/balance-state.type.ts";
import {Align} from "../common/enums/align.enum.ts";


const initialState: BalanceStateType = {
    headers: [
        {name: 'Date', value: 'paymentAt', align: Align.LEFT},
        {name: 'Network', value: 'walletType', align: Align.LEFT},
        {name: 'Amount', value: 'amount', align: Align.LEFT},
        {name: 'Description', value: 'description', align: Align.LEFT},
        {name: 'Blocknumber', value: 'blocknumber', align: Align.LEFT},
        {name: 'Hash', value: 'txid', align: Align.LEFT},
        {name: 'Status', value: 'status', align: Align.CENTER}
    ],
    balances: [],
    walletAccount: null,
    pagination: {
        page: 1,
        perPage: 10,
        total: 0
    }
}

const slice: Slice = createSlice({
    name: BALANCE_SLICE_NAME,
    initialState,
    reducers: {
        setBalances: (state: Draft<BalanceStateType>, { payload }): void => {
            state.balances = payload;
        },
        setPagination: (state: Draft<BalanceStateType>, { payload }): void => {
            state.pagination = payload;
        },
        setAccount: (state: Draft<BalanceStateType>, { payload }): void => {
            state.walletAccount = payload;
        },
        addBalance: (state: Draft<BalanceStateType>, {payload}) => {
            const index: number = state.balances.findIndex((p: any) => p.id === payload.id);

            if (index === -1) {
                state.balances.unshift(payload);
            }
        },
    }
});

export const balanceSelector = (state: RootState) => state.balance;
export const {
    setBalances,
    setPagination,
    setAccount,
    addBalance
} = slice.actions;
export default slice.reducer;
