import {IoReloadOutline} from "react-icons/io5";
import {FC, useCallback, useEffect, useMemo, useState} from "react";
import TablePayment from "../../common/components/tables/payment/table-payment.component.tsx";
import {Position} from "../../common/enums/position.enum.ts";
import {useDispatch, useSelector} from "react-redux";
import {paymentSelector, setPaymentPagination, setPayments} from "../../store/payment.store.ts";
import {NetworkStatus, QueryResult, useMutation, useQuery} from "@apollo/client";
import {
    GetPaymentsRequestType,
    GetPaymentsResponseType
} from "../../common/types/payment/get-payments-response.type.ts";
import {AppDispatch} from "../../store";
import {PaymentType} from "../../common/types/store/payment-state.type.ts";
import {PaginationType} from "../../common/types/store/pagination.type.ts";
import {toast} from "react-toastify";
import {PAYMENTS_QUERY} from "../../api/graphql/queries/payments.query.ts";
import TablePagination from "../../common/components/tables/table-pagination.component.tsx";
import PaymentPanel from "../../common/components/payment/payment-panel.component.tsx";
import NotificationToast from "../../common/components/toasts/notification.toast.tsx";
import {NotificationType} from "../../common/enums/notification.enum.ts";
import CreatePaymentModal from "../../common/components/modals/create-payment.modal.tsx";
import Tooltip from "../../common/components/tooltip/tooltip.component.tsx";
import Loader from "../../common/components/general/loader.component.tsx";
import SimplePaymentAnalytic from "../../common/components/analytics/simple-payment-analytic.component.tsx";
import PaymentFilters from "../../common/components/payment/payment-filters.component.tsx";
import moment from "moment-timezone";
import {
    GetPaymentsSimpleAnalyticResponse,
    PaymentsSimpleAnalyticType
} from "../../common/types/payment/get-payments-simple-analytic-response.type.ts";
import {PAYMENT_SIMPLE_ANALYTIC_QUERY} from "../../api/graphql/queries/payment-get-simple-analytic.query.ts";
import {OperationType} from "../../common/enums/operation-type.enum.ts";
import {MdCloudDownload} from "react-icons/md";
import {SaveSimplePaymentAnalyticResponseType} from "../../common/types/analytics/analytics.type.ts";
import {
    SAVE_PAYMENT_SIMPLE_ANALYTIC_MUTATION
} from "../../api/graphql/mutations/save-payment-simple-analytic.mutation.ts";


const PaymentsPage: FC = () => {
    // Payment Analytics
    const [analyticCount, setAnalyticCount] = useState(0);
    const [analyticAmount, setAnalyticAmount] = useState(0);
    const [analyticFeeBsc, setAnalyticFeeBsc] = useState(0);
    const [analyticFeeTron, setAnalyticFeeTron] = useState(0);
    const [analyticDownloadLoading, setAnalyticDownloadLoading] = useState(false);

    // Filters
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [dates, setDates] = useState<string[] | undefined>(undefined);

    // Modals
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false)

    const dispatcher: AppDispatch = useDispatch();
    const {headers, payments, pagination: {page, perPage, total}} = useSelector(paymentSelector);

    const showStatistic: boolean = useMemo(() => {
        if (!dates && !search)
            return false
        return true
    }, [dates, search]);

    const paymentRequest: GetPaymentsRequestType = useMemo(() => ({
        page,
        perPage,
        search,
        dateStart: dates?.[0],
        dateEnd: dates?.[1]
    }), [dates, page, perPage, search]);

    const paymentAnalyticRequest = useMemo(() => ({
        search,
        dateStart: dates?.[0],
        dateEnd: dates?.[1],
        type: OperationType.PAYMENT
    }), [dates, search]);

    const {
        data,
        loading,
        error,
        networkStatus
    }: QueryResult<GetPaymentsResponseType> = useQuery<GetPaymentsResponseType>(PAYMENTS_QUERY, {
        variables: {paymentOperationsRequest: {...paymentRequest}},
    });

    const simpleAnalytic: QueryResult<GetPaymentsSimpleAnalyticResponse> = useQuery<GetPaymentsSimpleAnalyticResponse>(PAYMENT_SIMPLE_ANALYTIC_QUERY, {
        variables: {dto: {...paymentAnalyticRequest}},
    });

    const [downloadStatistic, downloadStatisticData] = useMutation<SaveSimplePaymentAnalyticResponseType>(SAVE_PAYMENT_SIMPLE_ANALYTIC_MUTATION);


    // Payment loading
    const isLoading: boolean = useMemo<boolean>(() => {
        return loading || networkStatus === NetworkStatus.refetch;
    }, [loading, networkStatus]);

    const reloadHandler = useCallback(async (): Promise<void> => {
        setSearch(undefined);
        setDates(undefined);
    }, []);

    // Payment Fetch success
    useEffect((): void => {
        if (data) {
            const response: PaymentType[] = data.getPaymentOperations.data;
            const meta: PaginationType = data.getPaymentOperations.meta;

            dispatcher(setPayments(response));
            dispatcher(setPaymentPagination(meta));
        }
    }, [data, dispatcher]);

    // Payment Fetch failed
    useEffect((): void => {
        if (error) {
            toast(<NotificationToast type={NotificationType.ERROR} title="Payments"
                                     message="Failed to fetch payments"/>, {
                toastId: 'payments-fetch-error'
            })
        }
    }, [error]);

    useEffect((): void => {
        if (simpleAnalytic.data) {
            const response: PaymentsSimpleAnalyticType = simpleAnalytic.data.getPaymentSimpleAnalytic;

            setAnalyticCount(response.count);
            setAnalyticAmount(response.amount);
            setAnalyticFeeBsc(response.feeBsc);
            setAnalyticFeeTron(response.feeTron);
        }
    }, [simpleAnalytic.data, dispatcher, analyticFeeBsc]);

    // Payment Simple Analytic Fetch failed
    useEffect((): void => {
        if (simpleAnalytic.error) {
            toast(
                <NotificationToast
                    type={NotificationType.ERROR}
                    title="Payments Analytic"
                    message="Failed to fetch payments analytic"/>,
                {toastId: 'payments-analytic-fetch-error'}
            );
        }
    }, [simpleAnalytic.error]);

    useEffect((): void => {
        if (downloadStatisticData.data) {
            const url: string = downloadStatisticData.data.savePaymentSimpleAnalytic.url;
            setTimeout(() => {
                location.href = url
                setAnalyticDownloadLoading(false)
            }, 500)
        }
    }, [downloadStatisticData.data]);

    // Payment Simple Analytic Download failed
    useEffect((): void => {
        if (downloadStatisticData.error) {
            setAnalyticDownloadLoading(false)
            toast(
                <NotificationToast
                    type={NotificationType.ERROR}
                    title="Payments Statistic "
                    message="Failed to download"/>,
                {toastId: 'payments-analytic-download-error'}
            );
        }
    }, [downloadStatisticData.error]);


    // Pagination handler
    const onChangePage = useCallback((page: number): void => {
        dispatcher(setPaymentPagination({
            page, perPage, total
        }))
    }, [dispatcher, perPage, total])

    const datepickerChange = useCallback(async (dates?: Date[] | undefined): Promise<void> => {
        const prepareDates: string[] | undefined = dates?.map((d: Date) =>
            moment(d).format('YYYY-MM-DD')
        );
        setDates(prepareDates);
    }, []);

    const searchChange = useCallback(async (search?: string): Promise<void> => {
        setSearch(search);
    }, []);

    const prepareStatistic = useCallback(async (): Promise<void> => {
        setAnalyticDownloadLoading(true)
        await downloadStatistic({
            variables: {
                dto: {...paymentAnalyticRequest}
            }
        })
    }, [downloadStatistic, paymentAnalyticRequest]);


    return (
        <div className="relative">
            <main>
                <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
                    {/* Page header */}
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
                        {/* Payment title */}
                        <div className='flex items-center justify-between sm:justify-center mb-4 sm:mb-0'>
                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mr-1">Payments</h1>

                            <div className="m-1">
                                <Tooltip title="Reload payments" message="" position={Position.RIGHT}>
                                    <button
                                        aria-label="Reload Payments"
                                        onClick={reloadHandler}
                                        className={`btn flex items-center w-[30px] h-[30px] rounded-full p-1.5 justify-center bg-white text-indigo-500 font-bold transition-all ${(loading || networkStatus === NetworkStatus.refetch) ? 'opacity-40 cursor-not-allowed' : 'opacity-1 hover:bg-indigo-100 active:scale-95'}`}
                                        disabled={loading || networkStatus === NetworkStatus.refetch}
                                    >
                                        <IoReloadOutline size={20}/>
                                    </button>
                                </Tooltip>
                            </div>
                            <Loader loading={isLoading} size={4}/>
                        </div>

                        <div className="flex">
                            {/* Download statistic  */}
                            <Tooltip title="Download statistic" message="" position={Position.TOP}>
                                <button
                                    onClick={prepareStatistic}
                                    disabled={(downloadStatisticData.loading || analyticDownloadLoading)}
                                    className={`btn mb-2 loading h-full sm:mb-0 sm:ml-3 bg-white text-indigo-500 transition-all active:scale-95 hover:bg-indigo-100 ${(downloadStatisticData.loading || analyticDownloadLoading) && 'pointer-events-none opacity-75'}`}>
                                    {(downloadStatisticData.loading || analyticDownloadLoading)
                                        ? <Loader loading={true} size={4}/>
                                        : <MdCloudDownload size={20} />
                                    }
                                </button>
                            </Tooltip>
                            {/* Create a new Payment  */}
                            <Tooltip title="Create a new payment" message="" position={Position.TOP}>
                                <button
                                    onClick={() => setShowPaymentModal(true)}
                                    className="btn mb-2 sm:mb-0 sm:ml-3 bg-indigo-500 text-white transition-all active:scale-95 hover:bg-indigo-600">
                                    Create payment
                                </button>
                            </Tooltip>
                        </div>

                    </div>

                    <div className={`flex flex-col sm:flex-row ${showStatistic ? 'justify-between' : 'justify-end'} sm:items-center w-full`}>
                        {/* Left: Title */}
                        {showStatistic &&
                            <SimplePaymentAnalytic
                                count={analyticCount}
                                amount={analyticAmount}
                                feeBsc={analyticFeeBsc}
                                feeTron={analyticFeeTron}
                            />
                        }
                        {/* Right: Actions */}
                        <PaymentFilters
                            loading={isLoading}
                            datepickerChange={datepickerChange}
                            searchChange={searchChange}
                        />
                    </div>

                </div>

                <div className="px-4 sm:px-6 lg:px-8 pb-8 pt-4 w-full max-w-9xl mx-auto">
                    <TablePayment
                        headers={headers}
                        items={payments}
                        emptyMessage="You don't have payments"
                    />
                    <div className="mt-8">
                        {/*  pagination  */}
                        <TablePagination
                            total={total}
                            currentPage={page}
                            onPageChange={onChangePage}
                            perPage={perPage}
                            position={Position.END}
                        />
                    </div>
                </div>
            </main>

            <PaymentPanel/>
            <CreatePaymentModal show={showPaymentModal} setShow={setShowPaymentModal}/>
        </div>
    );
};


export default PaymentsPage;
