import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {CALLBACK_SLICE_NAME} from "../common/constants/store.constant.ts";
import {RootState} from "./index.ts";
import {CallbackStateType} from "../common/types/store/callback-state.type.ts";


const initialState: CallbackStateType = {
    url: null,
    operationId: null
}

const slice: Slice<CallbackStateType> = createSlice({
    name: CALLBACK_SLICE_NAME,
    initialState,
    reducers: {
        openCallbackModel: (state: Draft<CallbackStateType>, {payload}): void => {
            state.operationId = payload.operationId;
            state.url = payload.url;
        },
        closeCallbackModel: (state: Draft<CallbackStateType>): void => {
            state.operationId = null;
            state.url = null;
        },
    }
});

export const callbackSelector = (state: RootState) => state.callback;
export const {
    openCallbackModel,
    closeCallbackModel
} = slice.actions;
export default slice.reducer;
