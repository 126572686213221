import {DocumentNode, gql} from "@apollo/client";

export const NODE_INFO_FRAGMENT: DocumentNode = gql(`
    fragment NodeInfo on NetworkInfoResponse {
        network
        blockchains {
          name
          fee
          nativeSymbol
          tetherSymbol
          networkName
          explorerLink
          contractAddress
          faucetCoinLink
          faucetTokenLink
        }
    }
`);
