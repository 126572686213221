import {configureStore} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

import auth from './auth.store';
import user from './user.store';
import payment from './payment.store';
import balance from './balance.store';
import tempAddress from  './temp-address.store';
import payout from './payout.store'
import payoutAddresses from './payout-addresses.store'
import networks from './networks.store'
import callback from './callback.store'

const store = configureStore({
    reducer: {auth, user, payment, balance, tempAddress, payout, payoutAddresses, networks, callback}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export default store;
