import {DocumentNode, gql} from "@apollo/client";

export const PAYMENT_PUBLIC_INFO_FRAGMENT: DocumentNode = gql(`
    fragment PaymentPublicInfo on PublicOperationResponse {
        orderId
        amount
        userName
        address
        currency
        walletType
        description
        status
        createdAt
        paymentAt
        expiredAt
    }
`);
