import {FC} from "react";
import PropTypes from "prop-types";
import TableHeader from "../table-header.component.tsx";
import EmptyIcon from "../../icons/EmptyIcon.tsx";
import {TablePayoutType} from "../../../types/components/table-payout.type.ts";
import TableItemPayoutPayment from "./table-item-payout.component.tsx";


const TablePayout: FC<TablePayoutType> = ({ headers, items,emptyMessage  }) => {
    return (
        <>
            <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
                <div className="overflow-x-auto">
                    { items.length > 0
                        ? <table className="table-auto w-full">
                            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
                            <tr>
                                {headers.map((header, index) => (
                                    <TableHeader
                                        key ={index}
                                        name={header.name}
                                        value={header.value}
                                        align={header.align}
                                    />
                                ))}
                            </tr>
                            </thead>
                            <tbody className="text-sm divide-y divide-slate-200">
                                {items.map((item, index) => (
                                    <TableItemPayoutPayment
                                        key={index}
                                        item={item}
                                    />
                                ))}
                            </tbody>
                        </table>
                        : <div className='h-[500px] w-full flex items-center justify-center flex-col'>
                            <EmptyIcon width={100} height={100} className="mb-4" />
                            <p className='font-semibold text-slate-600 text-2xl'>{emptyMessage}</p>
                        </div>
                    }

                </div>
            </div>
        </>
    );
};

TablePayout.propTypes = {
    headers: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    emptyMessage: PropTypes.string
}

TablePayout.defaultProps = {
    emptyMessage: 'Empty...'
}

export default TablePayout;
