import {DocumentNode, gql} from "@apollo/client";

export const USER_NOTIFICATION: DocumentNode = gql(`
    subscription UserNotification($userId: Float!) {
        userNotification(userId: $userId) {
            ... on UserNotificationOT {
                type
                title
                message
                name
            }
            ... on OperationStatusNotificationOT {
                operationId
                status
                name
            }
            ... on OperationSysStatusNotificationOT {
                operationId
                sysStatus
                name
            }
            ... on OperationUserStatusNotificationOT {
                operationId
                userStatus
                name
            }
            ... on UpdateAdminBalanceNotificationOT {
                walletType
                currency
                balance
                name
            }
            ... on OperationErrorNotificationOT {
                error
                name
                operationType
                operationId
            }
            ... on OperationCreatedNotificationOT {
                name
                operation {
                  id
                  currency
                  walletType
                  status
                  amount
                  paymentAddress
                  paymentFee
                  internalId
                  clientTransactionLink
                  orderId
                  description
                  createdAt
                }
            }
            ... on PayoutAddressStatusNotificationOT {
                name
                addressId
                payoutAddressStatus
            }
            ... on PayoutAddressCreatedNotificationOT {
                name
                address {
                    id
                    address
                    status
                    walletType
                    description
                }
            }
            ... on PayoutCreatedNotificationOT {
                name
                payout {
                    id
                    payoutAddress {
                        id
                        address
                        status
                        walletType
                        description
                    }
                    walletType
                    status
                    amount
                    currency
                    description
                    error
                    createdAt
                }
            }
            ... on UpdateTempAddressBalanceNotificationOT {
                name
                addressId
                currency
                balance
            }
            ... on ChangePayoutNotificationOT {
                name
                operationId
                status
            }
            ... on BalanceOperationNotificationOT {
                name
                operation {
                  id
                  currency
                  walletType
                  status
                  amount
                  description
                  paymentAt
                  incomeHash
                  incomeBlocknumber
                  transactionHashLink
                  transactionBlocknumberLink
                }
            }
        }
    }
`);
