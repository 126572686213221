import { FC, Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PaymentModalType } from "../../types/payment/payment-modal.type.ts";
import { v4 as uuidv4 } from "uuid";
import { Transition } from "@headlessui/react";
import { mixed, number, object, ObjectSchema, string } from "yup";

import moment from "moment-timezone";
import { CreatePaymentType } from "../../types/payment/create-payment.type.ts";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Spinner from "../general/spinner.component.tsx";
import { GetCurrenciesType } from "../../types/payment/get-currencies.type.ts";
import { QueryResult, useMutation, useQuery } from "@apollo/client";
import { GetCurrenciesResponseType } from "../../types/payment/get-currencies-response.type.ts";
import { CURRENCIES_QUERY } from "../../../api/graphql/queries/currencies.query.ts";
import { toast } from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import { NotificationType } from "../../enums/notification.enum.ts";
import { CREATE_PAYMENT_MUTATION } from "../../../api/graphql/mutations/create-payment.mutation.ts";
import { CreatePaymentResponse } from "../../types/payment/create-payment-response.type.ts";
import { PaymentInfoType } from "../../types/payment/payment-info.type.ts";
import { openInNewTab } from "../../utils/common.util.ts";
import { WalletType } from "../../enums/wallet-type.enum.ts";

const initialValues: CreatePaymentType = {
	amount: 1,
	orderId: "",
	wallet: WalletType.BSC,
	description: "",
};

const validationSchema: ObjectSchema<CreatePaymentType> = object({
	amount: number()
		.positive("Amount must be a positive number")
		.min(0.5)
		.required("Required"),
	orderId: string().required("Required"),
	wallet: mixed<WalletType>()
		.oneOf(Object.values(WalletType))
		.required("Required"),
	description: string(),
	callbackUrl: string(),
});

const CreatePaymentModal: FC<PaymentModalType> = ({ show, setShow }) => {
	const [currencies, setCurrencies] = useState<GetCurrenciesType[]>([]);
	const currenciesQuery: QueryResult<GetCurrenciesResponseType> =
		useQuery<GetCurrenciesResponseType>(CURRENCIES_QUERY, {
			skip: !show,
		});
	const [createPayment, createPaymentData] = useMutation<CreatePaymentResponse>(
		CREATE_PAYMENT_MUTATION,
	);

	useEffect(() => {
		if (show) {
			initialValues.orderId = uuidv4();
			initialValues.description = `Test payment ${moment().format("DD/MM/YYYY HH:mm:ss")}`;
		} else {
			initialValues.orderId = "";
			initialValues.description = "";
		}
	}, [show]);

	useEffect(() => {
		if (currenciesQuery.data) {
			const response: GetCurrenciesType[] = currenciesQuery.data.getCurrencies;
			setCurrencies(response);
		}
	}, [currenciesQuery.data]);

	useEffect(() => {
		if (currenciesQuery.error) {
			toast(
				<NotificationToast
					type={NotificationType.ERROR}
					title="Get currencies"
					message="Failed to fetch currencies"
				/>,
				{
					toastId: "currencies-fetch-error",
				},
			);
		}
	}, [currenciesQuery.error]);

	useEffect(() => {
		if (createPaymentData.data) {
			const response: PaymentInfoType = createPaymentData.data.createPayment;
			openInNewTab(response.link);
		}
	}, [createPaymentData.data]);

	useEffect(() => {
		if (createPaymentData.error) {
			toast(
				<NotificationToast
					type={NotificationType.ERROR}
					title="Create Payment"
					message={createPaymentData.error.message}
				/>,
				{
					toastId: "payment-create-error",
				},
			);
		}
	}, [createPaymentData.error]);

	const handleSubmit = useCallback(
		async (values: CreatePaymentType): Promise<void> => {
			await createPayment({
				variables: {
					dto: {
						amount: String(values.amount),
						orderId: values.orderId,
						wallet: values.wallet,
						description: values.description,
					},
				},
			});
		},
		[createPayment],
	);

	return (
		<>
			<Transition
				as={Fragment}
				show={show}
				enter="transition ease-out duration-100"
				enterFrom="opacity-0 translate-y-1"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in duration-80"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-1"
			>
				<div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
			</Transition>
			<Transition
				as={Fragment}
				show={show}
				enter="transition ease-in-out duration-200"
				enterFrom="opacity-0 translate-y-4"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in-out duration-200"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-4"
			>
				<div className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
					<div
						onClick={(e) => e.stopPropagation()}
						className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full"
					>
						<div className="relative">
							{/* Close button */}
							<button
								onClick={() => setShow(false)}
								className="absolute top-6 right-6 text-slate-400 hover:text-slate-500"
							>
								<div className="sr-only">Close</div>
								<svg className="w-4 h-4 fill-current">
									<path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
								</svg>
							</button>

							{/* Modal header */}
							<div className="p-4">
								<div className="text-lg font-semibold text-slate-800">
									Create Payment
								</div>
							</div>

							<div className="divide-y">
								<div></div>
								<div></div>
							</div>
							{/* Modal content */}
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
							>
								{({ errors, touched, isSubmitting, isValid }) => (
									<Form>
										<div className="p-5">
											<section className="mb-4">
												<div className="flex-col items-center space-y-1">
													<label
														className="block text-sm font-medium"
														htmlFor="amount"
													>
														Amount<span className="text-rose-500">*</span>
													</label>
													<div className="relative">
														<Field
															id="amount"
															type="number"
															name="amount"
															placeholder="Amount"
															className={`form-input w-full ${errors.amount && touched.amount ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
														/>
													</div>

													<ErrorMessage
														name="amount"
														component="div"
														className="text-rose-500 text-xs"
													/>
												</div>
											</section>
											<section className="mb-4">
												<div className="flex-col items-center space-y-1">
													<label
														className="block text-sm font-medium"
														htmlFor="orderId"
													>
														Order id <span className="text-rose-500">*</span>
													</label>
													<div className="relative">
														<Field
															id="orderId"
															type="text"
															name="orderId"
															placeholder="Unique string on your system"
															className={`form-input w-full ${errors.orderId && touched.orderId ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
														/>
													</div>

													<ErrorMessage
														name="orderId"
														component="div"
														className="text-rose-500 text-xs"
													/>
												</div>
											</section>
											<section className="mb-4">
												<div className="flex-col items-center space-y-1">
													<label
														className="block text-sm font-medium"
														htmlFor="description"
													>
														Description
													</label>
													<div className="relative">
														<Field
															id="description"
															type="text"
															name="description"
															placeholder="Description"
															className={`form-input w-full ${errors.description && touched.description ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
														/>
													</div>

													<ErrorMessage
														name="description"
														component="div"
														className="text-rose-500 text-xs"
													/>
												</div>
											</section>
											<section className="mb-4">
												<div className="flex-col items-center space-y-1">
													<div className="block text-sm font-medium">
														Select Network :{" "}
														<span className="text-rose-500">*</span>
													</div>
													<div className="relative">
														<div
															role="group"
															aria-labelledby="currency-radio-group"
															className={`${currencies.length > 2 ? "sm:grid sm:grid-cols-2" : ""} `}
														>
															{currenciesQuery.loading ? (
																<div className="flex items-center justify-center">
																	<Spinner className="w-[35px] h-[35px] my-6 mx-auto" />
																</div>
															) : (
																currencies.map(
																	(
																		currency: GetCurrenciesType,
																		index: number,
																	) => (
																		<div key={index} className="m-3 col-span-1">
																			<label className="flex items-center cursor-pointer w-fit">
																				<Field
																					type="radio"
																					name="wallet"
																					value={currency.type}
																					className="form-radio cursor-pointer"
																				/>
																				<span className="text-sm ml-2 text-gray-custom-800 font-semibold">{`${currency.symbol} (${currency.type})`}</span>
																			</label>
																		</div>
																	),
																)
															)}
														</div>

														<ErrorMessage
															name="wallet"
															component="div"
															className="text-rose-500 text-xs"
														/>
													</div>
												</div>
											</section>
										</div>

										{/* Modal footer */}
										<div className="divide-y">
											<div></div>
											<div></div>
										</div>
										<div className="flex justify-end p-4">
											<button
												type="button"
												onClick={() => setShow(false)}
												className="bg-white border text-gray-700 hover:bg-gray-100 btn-sm whitespace-nowrap mr-2"
											>
												Cancel
											</button>
											<button
												type="submit"
												disabled={
													isSubmitting || currenciesQuery.loading || !isValid
												}
												className={`${isSubmitting || currenciesQuery.loading || !isValid ? "opacity-40" : "opacity-1"} bg-indigo-500  hover:bg-indigo-600 btn-sm text-white whitespace-nowrap`}
											>
												Create
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</Transition>
		</>
	);
};

CreatePaymentModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
};

export default CreatePaymentModal;
