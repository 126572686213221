import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {PAYMENT_SLICE_NAME} from "../common/constants/store.constant.ts";
import {Align} from "../common/enums/align.enum.ts";
import {RootState} from "./index.ts";
import {PayoutAddressesStateType} from "../common/types/store/payout-addresses-state.type.ts";


const initialState: PayoutAddressesStateType = {
    headers: [
        {name: 'Type', value: 'walletType', align: Align.LEFT},
        {name: 'Address', value: 'address', align: Align.LEFT},
        {name: 'Description', value: 'description', align: Align.LEFT},
        {name: 'Action', value: 'action', align: Align.CENTER}
    ],
    payoutAddresses: [],
    payoutAddress: null,
    approvalModel: {
        addressId: null
    },
    discardModel: {
        addressId: null
    },
    pagination: {
        page: 1,
        perPage: 8,
        total: 0
    }
}

const slice: Slice<PayoutAddressesStateType> = createSlice({
    name: PAYMENT_SLICE_NAME,
    initialState,
    reducers: {
        setPayoutAddresses: (state: Draft<PayoutAddressesStateType>, {payload}): void => {
            state.payoutAddresses = payload;
        },
        setPayoutAddress: (state: Draft<PayoutAddressesStateType>, {payload}): void => {
            state.payoutAddress = payload;
        },
        setStatus: (state: Draft<PayoutAddressesStateType>, {payload}) => {
            const index: number = state.payoutAddresses.findIndex((p: any) => p.address === payload.address);

            if (index !== -1) {
                state.payoutAddresses[index].status = payload.status;
            }

            if (state.payoutAddress && state.payoutAddress.id === payload.id) {
                state.payoutAddress.status = payload.status;
            }
        },
        addPayoutAddress: (state: Draft<PayoutAddressesStateType>, {payload}) => {
            const index: number = state.payoutAddresses.findIndex((p: any) => p.id === payload.id);

            if (index === -1) {
                state.payoutAddresses.unshift(payload);
            }
        },
        delPayoutAddress: (state: Draft<PayoutAddressesStateType>, {payload}) => {
            const index: number = state.payoutAddresses.findIndex((p: any) => p.address === payload.address);

            if (index !== -1) {
                state.payoutAddresses.splice(index, 1);
            }
        },
        setPagination: (state: Draft<PayoutAddressesStateType>, {payload}): void => {
            state.pagination = payload;
        },
        openApprovalModel: (state: Draft<PayoutAddressesStateType>, {payload}): void => {
            state.approvalModel.addressId = payload;
        },
        closeApprovalModel: (state: Draft<PayoutAddressesStateType>): void => {
            state.approvalModel.addressId = null;
        },
        openDiscardModel: (state: Draft<PayoutAddressesStateType>, {payload}): void => {
            state.discardModel.addressId = payload;
        },
        closeDiscardModel: (state: Draft<PayoutAddressesStateType>): void => {
            state.discardModel.addressId = null;
        },
    }
});

export const payoutAddressesSelector = (state: RootState) => state.payoutAddresses;
export const {
    setPayoutAddresses,
    setPayoutAddress,
    setPagination,
    setStatus,
    openApprovalModel,
    closeApprovalModel,
    openDiscardModel,
    closeDiscardModel,
    addPayoutAddress,
    delPayoutAddress
} = slice.actions;
export default slice.reducer;
