import {DocumentNode, gql} from "@apollo/client";

export const PAYOUT_TABLE_INFO_FRAGMENT: DocumentNode = gql(`
    fragment PayoutTableInfo on Operation {
      id
      currency
      walletType
      status
      amount
      paymentFee
      description
      paymentAt
      createdAt
      error
      callbackUrl
      payoutAddress {
        ...PayoutAddress  
      }
    }
`);
