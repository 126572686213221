import {DocumentNode, gql} from "@apollo/client";

export const ADD_PAYOUT_ADDRESS_TO_WHITELIST_MUTATION: DocumentNode = gql(`
    mutation AddPayoutAddressToWhitelist($dto: AddPayoutAddressToWhitelistRequest!, $tfaCode: String) {
        addPayoutAddressToWhitelist(addPayoutAddressToWhitelistDTO: $dto, tfaCode: $tfaCode) {
            id
            walletType
            address
            status
            description
        }
    }
`);
