import {DocumentNode, gql} from "@apollo/client";

export const CURRENCY_INFO_FRAGMENT: DocumentNode = gql(`
    fragment CurrencyInfo on Currencies {
        name
        type
        currency
        currencyType
        symbol
        contractaddress
        description
    }
`);
