import Cookies from "universal-cookie";
import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {AuthStateType} from "../common/types/store/auth-state.type.ts";
import {IAuthReducers} from "../common/interfaces/store/auth-reducers.interface.ts";
import {AUTH_SLICE_NAME} from "../common/constants/store.constant.ts";
import {RootState} from "./index.ts";

const cookies: Cookies = new Cookies();


const initialState: AuthStateType = {
    isAuth: !!cookies.get('access_token'),
    accessToken: cookies.get('access_token'),
    refreshToken: cookies.get('refresh_token')
}

const slice: Slice<AuthStateType, IAuthReducers> = createSlice({
    name: AUTH_SLICE_NAME,
    initialState,
    reducers: {
        login: (state: Draft<AuthStateType>, { payload }): void => {
            const {
                accessToken,
                accessTokenTTL,
                refreshToken,
                refreshTokenTTL
            } = payload;

            cookies.set("access_token", accessToken, {
                path: '/',
                expires: new Date(Date.now() + accessTokenTTL * 1000),
                sameSite: 'strict'
            });
            cookies.set("refresh_token", refreshToken, {
                path: '/',
                expires: new Date(Date.now() + refreshTokenTTL * 1000),
                sameSite: 'strict',
            });

            state.isAuth = true;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        },
        logout: (state: Draft<AuthStateType>): void => {
            cookies.getAll() // reload cookies

            cookies.remove("access_token", {
                path: '/',
                sameSite: 'strict'
            });
            cookies.remove("refresh_token", {
                path: '/',
                sameSite: 'strict'
            });

            state.isAuth = false;
            state.accessToken = null;
            state.refreshToken = null;
        }
    }
});

export const authSelector = (state: RootState) => state.auth;
export const {
    login,
    logout
} = slice.actions;
export default slice.reducer;
