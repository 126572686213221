import {useCallback, useEffect, useState} from "react";

export const useCopy = (timer = 3000) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = useCallback(async (text: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }

        setIsCopied(true);
    }, [])

    useEffect(() => {
        let timeout: number | null = null;

        if (isCopied) {
            timeout = setTimeout(() => {
                setIsCopied(false);
            }, timer);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isCopied, timer]);

    return [isCopied, copyToClipboard] as const;
}
