import {NavigateFunction, useNavigate} from "react-router-dom";
import {FC, useMemo} from "react";
import {HeaderBalanceType} from "../../types/components/header-balance.type.ts";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {userSelector} from "../../../store/user.store.ts";
import {
    formatCurrencyWithoutSign,
    getHumanDescCurrency,
    getNetworkIcon
} from "../../utils/currency.util.ts";
import {CurrencyType} from "../../enums/currency-type.enum.ts";


const HeaderBalance: FC<HeaderBalanceType> = ({walletLoading}) => {
    const navigate: NavigateFunction = useNavigate()
    const {wallet: {accounts}} = useSelector(userSelector);

    const handleClick = () => {
        navigate('/balance');
    }

    const balances = useMemo(() => {
        return accounts
            .slice()
            .sort((a, b) => a.type.localeCompare(b.type))
            .map((wallet) => {
                return {
                    type: wallet.type,
                    balances: wallet.balances
                        .slice()
                        .sort((a, b) => a.currency.localeCompare(b.currency))
                        .map((balance) => {
                            return {
                                currency: balance.currency,
                                currencyType: balance.currencyType,
                                balance: balance.balance,
                            }
                        })
                }
            });
    }, [accounts]);

    return (
        <div onClick={handleClick}
             className='flex items-center justify-center mr-4 hover:bg-gray-custom-100 hover:rounded p-1.5 transition-all cursor-pointer active:scale-95 select-none'>
            {(walletLoading) ?
                <div className='mr-[24]'>
                    <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                        <path
                            d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"/>
                    </svg>
                </div>
                : balances
                    .map((item, index) => (
                        <div key={index} className='flex items-center justify-center gap-2 w-full'>
                            <div className='flex justify-center w-[45px] h-[45px]'>
                                <img className=" rounded-full" src={getNetworkIcon(item.type)} alt={item.type}/>
                            </div>
                            <div className="flex flex-col items-start justify-center mr-[24px]">
                                {item.balances
                                    .map((balanceItem, i) => (
                                        <div key={index + '-' + i}
                                             className={`flex items-center justify-start gap-1`}>
                                            <p className='text-left text-sm text-gray-700 font-medium m-0'>{formatCurrencyWithoutSign(balanceItem.balance, balanceItem.currencyType === CurrencyType.TOKEN ? 2 : 5)}</p>
                                            <div className='leading-tight flex items-start'>
                                                <p className='text-center text-[12px] text-gray-500 m-0 uppercase'>{getHumanDescCurrency(balanceItem.currency)}</p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>

                    ))}
        </div>
    )
};

HeaderBalance.propTypes = {
    walletLoading: PropTypes.bool.isRequired,
};

export default HeaderBalance;
