import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {payoutAddressesSelector, setPagination, setPayoutAddresses} from "../../../store/payout-addresses.store.ts";
import {QueryResult, useQuery} from "@apollo/client";
import {GetPayoutAddressesResponseType} from "../../../common/types/payment/get-payout-addresses-response.type.ts";
import {PAYOUT_ADDRESSES_QUERY} from "../../../api/graphql/queries/payout-addresses.query.ts";
import {useCallback, useEffect} from "react";
import {PayoutAddressType} from "../../../common/types/store/payment-state.type.ts";
import {PaginationType} from "../../../common/types/store/pagination.type.ts";
import {toast} from "react-toastify";
import NotificationToast from "../../../common/components/toasts/notification.toast.tsx";
import {NotificationType} from "../../../common/enums/notification.enum.ts";
import TablePagination from "../../../common/components/tables/table-pagination.component.tsx";
import {Position} from "../../../common/enums/position.enum.ts";
import TablePayoutAddress from "../../../common/components/tables/payout-address/table-payout-address.component.tsx";


const PayoutAddressesPage = () => {
    const dispatcher: AppDispatch = useDispatch();
    const {headers, payoutAddresses, pagination: {page, perPage, total}} = useSelector(payoutAddressesSelector);

    const {
        data,
        error,
    }: QueryResult<GetPayoutAddressesResponseType> = useQuery<GetPayoutAddressesResponseType>(PAYOUT_ADDRESSES_QUERY, {
        variables: {
            dto: {page, perPage}
        }
    });

    useEffect((): void => {
        if (data) {
            const response: PayoutAddressType[] = data.getPayoutAddresses.data;
            const meta: PaginationType = data.getPayoutAddresses.meta;

            dispatcher(setPayoutAddresses(response));
            dispatcher(setPagination(meta));
        }
    }, [data, dispatcher]);

    useEffect((): void => {
        if (error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="Payout Addresses" message="Failed to fetch payout addresses"/>, {
                toastId: 'payouts-addresses-fetch-error'
            })
        }
    }, [error]);

    const onChangePage = useCallback((page: number): void => {
        dispatcher(setPagination({
            page, perPage, total
        }))
    }, [dispatcher, perPage, total]);

    return (
        <div className="grow">
            <div className="space-y-6 h-full flex flex-col justify-between">
                <div className="w-full max-w-9xl">
                    <TablePayoutAddress
                        headers={headers}
                        items={payoutAddresses}
                        emptyMessage="You don't have payout addresses on the whitelist"
                    />
                </div>
                { total > perPage &&
                    <div className="px-4 sm:px-6 lg:px-8 pb-4 w-full max-w-9xl mx-auto">
                        {/*  pagination  */}
                        <TablePagination
                            total={total}
                            currentPage={page}
                            onPageChange={onChangePage}
                            perPage={perPage}
                            position={Position.END}
                        />
                    </div>
                }
            </div>


        </div>
    );
};

export default PayoutAddressesPage;
