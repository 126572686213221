import {FC, SetStateAction, useCallback, useState} from "react";
import SwitchComponent from "../../../common/components/form/switch.component.tsx";
import {useSelector} from "react-redux";
import {userSelector} from "../../../store/user.store.ts";
import TFAOnModal from "../../../common/components/modals/tfa-on.modal.tsx";
import TfaOffModal from "../../../common/components/modals/tfa-off.modal.tsx";


const SecurityPage: FC = () => {
    const [showTFAOnModel, setShowTFAOnModel] = useState<boolean>(false);
    const [showTFAOffModel, setShowTFAOffModel] = useState<boolean>(false);
    const {profile: {isTfaEnabled}} = useSelector(userSelector);


    const twoFAHandler = useCallback((val: SetStateAction<boolean>) => {
        if (val) {
            if (!isTfaEnabled) {
                setShowTFAOnModel(true);
            }
        } else {
            if (isTfaEnabled) {
                setShowTFAOffModel(true);
            }
        }
    }, [isTfaEnabled]);

    return (
        <>
            <div className="p-6 space-y-6 min-h-[500px] w-full">
                <h2 className="text-2xl text-slate-800 font-bold mb-5">Authorization</h2>
                <div className="grid grid-cols-7">
                    <section className="col-span-7 lg:col-span-3">
                        <div className="flex items-center justify-between">
                            <div>TFA</div>
                            <SwitchComponent value={isTfaEnabled} onChange={e => twoFAHandler(e)} />
                        </div>
                    </section>
                </div>
            </div>

            <TFAOnModal show={showTFAOnModel} setShow={setShowTFAOnModel} />
            <TfaOffModal show={showTFAOffModel} setShow={setShowTFAOffModel} />
        </>
    );
};

export default SecurityPage;


