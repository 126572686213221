import {DocumentNode, gql} from "@apollo/client";

export const USER_INFO_FRAGMENT: DocumentNode = gql(`
    fragment UserInfo on Profile {
        id
        name
        email
        apiKey
        role
        isTfaEnabled
        hasSecretKey
    }
`);
