import {FC, useEffect} from 'react';
import PropTypes from 'prop-types';
import HeaderMenu from "./header-menu.component.tsx";
import HeaderBalance from "./header-balance.component.tsx";
import {HeaderType} from "../../types/components/header.type.ts";
import {Link} from "react-router-dom";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {networksSelector, setBlockchains, setNetwork} from "../../../store/networks.store.ts";
import {QueryResult, useQuery} from "@apollo/client";
import {NODE_INFO_QUERY} from "../../../api/graphql/queries/node-info.query.ts";
import {NetworkInfoResponseType, NetworkInfoType} from "../../types/networks/networks.type.ts";
import {toast} from "react-toastify";
import {NotificationType} from "../../enums/notification.enum.ts";
import NotificationToast from "../toasts/notification.toast.tsx";


const Header: FC<HeaderType> = ({sidebarOpen, setSidebarOpen, profileLoading, walletLoading}) => {
    const dispatcher: AppDispatch = useDispatch();

    const {network} = useSelector(networksSelector);

    const networkInfo: QueryResult<NetworkInfoResponseType> = useQuery<NetworkInfoResponseType>(NODE_INFO_QUERY);

    useEffect((): void => {
        if (networkInfo.data) {
            const response: NetworkInfoType = networkInfo.data.getNodeInfo;
            dispatcher(setNetwork(response.network));
            dispatcher(setBlockchains(response.blockchains));
        }
    }, [networkInfo.data, dispatcher]);


    useEffect((): void => {
        if (networkInfo.error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="Networks Info" message="Failed to fetch networks info"/>, {
                toastId: 'networks-info-fetch-error'
            })
        }
    }, [networkInfo.error]);

    return (
        <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">

                    {/* Header: Left side */}
                    <div className="flex">
                        {/* Hamburger button */}
                        <button
                            className="text-slate-500 hover:text-slate-600 lg:hidden"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="4" y="5" width="16" height="2"/>
                                <rect x="4" y="11" width="16" height="2"/>
                                <rect x="4" y="17" width="16" height="2"/>
                            </svg>
                        </button>
                        <div className="m-1.5">
                            {network &&
                                <Link to="/networks"
                                    className="relative text-xs inline-flex items-center justify-between font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1">
                                    <span className="mr-1">{network.toString().toLowerCase()}</span>
                                    <span className="relative flex h-2 w-2">
                                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
                                      <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-500"></span>
                                    </span>
                                </Link>
                            }
                        </div>
                    </div>
                    {/* Header: Right side */}
                    <div className="flex items-center">
                        {/*  Divider */}
                        <HeaderBalance walletLoading={walletLoading}/>
                        <HeaderMenu align="right" profileLoading={profileLoading}/>
                    </div>

                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    setSidebarOpen: PropTypes.func.isRequired,
    profileLoading: PropTypes.bool.isRequired,
    walletLoading: PropTypes.bool.isRequired,
};

export default Header;
