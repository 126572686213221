import {FC, MouseEvent} from "react";
import PropTypes from "prop-types";
import {toShort2} from "../../utils/common.util.ts";
import {Position} from "../../enums/position.enum.ts";
import Tooltip from "../tooltip/tooltip.component.tsx";
import {RiSendPlaneFill} from "react-icons/ri";

type CallbackComponentType = {
    callbackUrl: string|null;
    shortFirst?: number,
    shortLast?: number,
    disabledSep?: boolean,
    position?: Position;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const CallbackComponent: FC<CallbackComponentType> = ({
    callbackUrl,
    shortFirst,
    shortLast,
    disabledSep,
    position,
    onClick
}) => {

    return (
        <>
            <div className="flex items-center">
                <Tooltip className="whitespace-nowrap" title="URL" message={callbackUrl || 'Not set'} position={position}>
                    <span
                        className="font-medium text-slate-700 text-right">{toShort2(callbackUrl, shortFirst, shortLast, disabledSep) || 'Not set'}</span>
                </Tooltip>
                <Tooltip className="whitespace-nowrap" title="Action" message={"Send" + " " + "callback"}
                         position={position}>
                    <button
                        className="btn btn-xs flex rounded-full p-1.5 justify-center bg-transparent shadow-none text-indigo-500 font-bold transition-all bg-indigo-500 active:scale-95 hover:bg-transparent"
                        onClick={onClick}>
                        <RiSendPlaneFill className="text-indigo-500 cursor-pointer" size={18}/>
                    </button>
                </Tooltip>
            </div>
        </>

    );
};

CallbackComponent.propTypes = {
    callbackUrl: PropTypes.string,
    shortFirst: PropTypes.number,
    shortLast: PropTypes.number,
    disabledSep: PropTypes.bool,
    position: PropTypes.oneOf<Position>(Object.values(Position) as Position[]),
    onClick: PropTypes.func,
};

CallbackComponent.defaultProps = {
    callbackUrl: null,
    shortFirst: 6,
    shortLast: 6,
    disabledSep: false,
    position: Position.RIGHT,
    onClick: () => console.log('click')
}

export default CallbackComponent;
