import {DocumentNode, gql} from "@apollo/client";

export const DISCARD_PAYOUT_ADDRESS_MUTATION: DocumentNode = gql(`
    mutation DiscardPayoutAddress($dto: DiscardPayoutAddressRequest!) {
        discardPayoutAddress(discardPayoutAddressDTO: $dto) {
            address
            status
        }
    }
`);
