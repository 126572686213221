import {DocumentNode, gql} from "@apollo/client";

export const PAYOUTS_QUERY: DocumentNode = gql(`    
    query GetPayoutsOperations($dto: PaymentOperationsRequest!) {
      getPayoutOperations(payoutOperationsDTO: $dto) {
        data {
         ...PayoutTableInfo
        }
        meta {
          page
          perPage
          total
        }
      }
    }
`);
