import {FC, Fragment, useState} from "react";
import {ProfileLayoutType} from "../common/types/layouts/profile-layout.type.ts";
import ProfileNavbar from "../common/components/profile/profile.navbar.tsx";
import Tooltip from "../common/components/tooltip/tooltip.component.tsx";
import {Position} from "../common/enums/position.enum.ts";
import {useLocation} from "react-router-dom";
import {Transition} from "@headlessui/react";
import AddPayoutAddressModal from "../common/components/modals/add-payout-address.modal.tsx";

const ProfileLayout: FC<ProfileLayoutType> = ({children}) => {
    const {pathname} = useLocation();
    const [showAddPayoutAddress, setShowAddPayoutAddress] = useState<boolean>(false);

    return (
        <main>
            <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
                <div className="sm:flex sm:justify-between sm:items-center">
                    <div className='flex items-center'>
                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mr-4">Profile</h1>
                    </div>
                    <Transition
                        as={Fragment}
                        show={pathname.includes('/profile/payout-whitelist')}
                        enter="transition ease-in-out duration-200"
                        enterFrom="opacity-0 translate-y-4"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in-out duration-50"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-4"
                    >
                        <div>
                            <Tooltip title="Add Address to Whitelist" message="" position={Position.TOP}>
                                <button
                                    onClick={() => setShowAddPayoutAddress(true)}
                                    className="btn sm:mb-0 sm:ml-3 bg-indigo-500 text-white transition-all active:scale-95 hover:bg-indigo-600">
                                    Add Address
                                </button>
                            </Tooltip>
                        </div>
                    </Transition>
                </div>

            </div>
            <div className="px-4 sm:px-6 lg:px-8 pb-8 pt-4 w-full max-w-9xl mx-auto">

                <div className="bg-white shadow-lg rounded-sm mb-8">
                    <div className="flex flex-col md:flex-row md:-mr-px min-h-[500px]">
                        <ProfileNavbar />
                        {children}
                    </div>
                </div>

            </div>

            <AddPayoutAddressModal show={showAddPayoutAddress} setShow={setShowAddPayoutAddress} />
        </main>
    );
};

export default ProfileLayout;
