import {DocumentNode, gql} from "@apollo/client";

export const TEMP_ADDRESSES_QUERY: DocumentNode = gql(`    
    query GetTempAddresses($dto: TempAddressesRequest!) {
      getTempAddresses(tempAddressesDTO: $dto) {
        data {
         ...TempAddressInfo
        }
        meta {
          page
          perPage
          total
        }
      }
    }
`);
