import {DocumentNode, gql} from "@apollo/client";

export const PAYMENT_SIMPLE_ANALYTIC_FRAGMENT: DocumentNode = gql(`
    fragment PaymentSimpleAnalytic on PaymentSimpleAnalyticResponse {
        count
        amount
        feeBsc
        feeTron
    }
`);
