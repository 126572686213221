import {FC} from 'react';
import PropTypes from 'prop-types';
import {NotificationType} from "../../enums/notification.enum.ts";
import {Notification} from "../../types/components/notification-toast.type.ts";

const NotificationToast: FC<Notification> = ({type, title, message}) => {
    const typeIcon = () => {
        switch (type) {
            case NotificationType.WARNING:
                return (
                    <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                    </svg>
                );
            case NotificationType.ERROR:
                return (
                    <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                    </svg>
                );
            case NotificationType.SUCCESS:
                return (
                    <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                    </svg>
                );
            case NotificationType.INFO:
                return (
                    <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
                    </svg>
                );
            default:
                return (
                    <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
                    </svg>
                );
        }
    };

    const typeColor = () => {
        switch (type) {
            case NotificationType.WARNING:
                return 'bg-amber-100 border-amber-200 text-amber-600';
            case NotificationType.ERROR:
                return 'bg-rose-100 border-rose-200 text-rose-600';
            case NotificationType.SUCCESS:
                return 'bg-emerald-100 border-emerald-200 text-emerald-600';
            case NotificationType.INFO:
                return 'bg-indigo-100 border-indigo-200 text-indigo-500';
            default:
                return 'bg-indigo-100 border-indigo-200 text-indigo-500';
        }
    };

    return (
        <div className={`w-full h-full px-4 py-2 rounded-sm text-sm ${typeColor()}`}>
            <div className="flex w-full justify-between items-start">
                <div className="flex items-center">
                    {typeIcon()}
                    <div>
                        <div className="font-semibold">{title} </div>
                        <div>{message}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

NotificationToast.propTypes = {
    type: PropTypes.oneOf(Object.values(NotificationType)).isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

export default NotificationToast;
