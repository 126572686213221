import {FC} from 'react';
import PropTypes from 'prop-types';
import {TableHeaderType} from "../../types/components/table-header.type.ts";
import {Align} from "../../enums/align.enum.ts";

const TableHeader: FC<TableHeaderType> = ({name, value, align}) => {
    return (
        <th key={name} className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            {value === 'select'
                ? <div className="flex items-center">
                    <label className="inline-flex">
                        <span className="sr-only">Select all</span>
                        {/*<input className="form-checkbox" type="checkbox" checked={allSelect} onChange={setSelectAll} />*/}
                        <input className="form-checkbox" type="checkbox"/>
                    </label>
                </div>
                : <span className={`text-${align} block w-full`}>{name}</span>
            }
        </th>

    );
};

TableHeader.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    align: PropTypes.oneOf(Object.values(Align))
};

TableHeader.defaultProps = {
    align: Align.LEFT
}

export default TableHeader;
