import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {NETWORKS_SLICE_NAME} from "../common/constants/store.constant.ts";
import {RootState} from "./index.ts";
import {NetworksStateType} from "../common/types/store/networks-state.type.ts";
import {INetworksReducers} from "../common/interfaces/store/networks-reducers.interface.ts";


const initialState: NetworksStateType = {
    network: null,
    blockchains: []
}

const slice: Slice<NetworksStateType, INetworksReducers> = createSlice({
    name: NETWORKS_SLICE_NAME,
    initialState,
    reducers: {
        setNetwork: (state: Draft<NetworksStateType>, { payload }): void => {
            state.network = payload
        },
        setBlockchains: (state: Draft<NetworksStateType>, { payload }): void => {
            state.blockchains = payload
        }
    }
});

export const networksSelector = (state: RootState) => state.networks;
export const {
    setNetwork,
    setBlockchains
} = slice.actions;
export default slice.reducer;
