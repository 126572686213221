import {DocumentNode, gql} from "@apollo/client";

export const PAYMENT_PUBLIC_NOTIFICATION: DocumentNode = gql(`
    subscription PaymentPublicNotification($internalId: String!) {
        publicNotification(internalId: $internalId) {
            ... on PublicNotification {
                type
                message
            }
            ... on PublicNotificationUserStatus {
                userStatus
            }
        }
    }
`);
