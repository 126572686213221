import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {payoutSelector, setPayoutPagination, setPayouts} from "../../store/payout.store.ts";
import TablePagination from "../../common/components/tables/table-pagination.component.tsx";
import {Position} from "../../common/enums/position.enum.ts";
import TablePayout from "../../common/components/tables/payout/table-payout.component.tsx";
import {CurrencyType} from "../../common/enums/currency-type.enum.ts";
import WalletModal from "../../common/components/modals/wallet.modal.tsx";
import DepositModal from "../../common/components/modals/deposit.modal.tsx";
import {NetworkStatus, QueryResult, useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import NotificationToast from "../../common/components/toasts/notification.toast.tsx";
import {NotificationType} from "../../common/enums/notification.enum.ts";
import {GetPayoutsResponseType} from "../../common/types/payment/get-payouts-response.type.ts";
import {AppDispatch} from "../../store";
import {PaymentType} from "../../common/types/store/payment-state.type.ts";
import {PaginationType} from "../../common/types/store/pagination.type.ts";
import {PAYOUTS_QUERY} from "../../api/graphql/queries/payouts.query.ts";
import AcceptPayoutModal from "../../common/components/modals/accept-payout.modal.tsx";
import RejectPayoutModal from "../../common/components/modals/reject-payout.modal.tsx";
import Tooltip from "../../common/components/tooltip/tooltip.component.tsx";
import {IoReloadOutline} from "react-icons/io5";
import DetailPayoutModal from "../../common/components/modals/detail-payout.modal.tsx";
import CompletePayoutModal from "../../common/components/modals/complete-payout.modal.tsx";
import CancelPayoutModal from "../../common/components/modals/cancel-payout.modal.tsx";
import Loader from "../../common/components/general/loader.component.tsx";
import SimplePayoutAnalytic from "../../common/components/analytics/simple-payout-analytic.component.tsx";
import moment from "moment-timezone";
import {OperationType} from "../../common/enums/operation-type.enum.ts";
import {
    GetPaymentsSimpleAnalyticResponse, PaymentsSimpleAnalyticType
} from "../../common/types/payment/get-payments-simple-analytic-response.type.ts";
import {PAYMENT_SIMPLE_ANALYTIC_QUERY} from "../../api/graphql/queries/payment-get-simple-analytic.query.ts";
import PaymentFilters from "../../common/components/payment/payment-filters.component.tsx";
import {MdCloudDownload} from "react-icons/md";
import {SaveSimplePaymentAnalyticResponseType} from "../../common/types/analytics/analytics.type.ts";
import {
    SAVE_PAYMENT_SIMPLE_ANALYTIC_MUTATION
} from "../../api/graphql/mutations/save-payment-simple-analytic.mutation.ts";

const PayoutsPage = () => {
    // Payout Analytics
    const [analyticCount, setAnalyticCount] = useState(0);
    const [analyticAmount, setAnalyticAmount] = useState(0);
    const [analyticDownloadLoading, setAnalyticDownloadLoading] = useState(false);

    // Filters
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [dates, setDates] = useState<string[] | undefined>(undefined);

    const dispatcher: AppDispatch = useDispatch();
    const {headers, payouts, pagination: {page, perPage, total}} = useSelector(payoutSelector);

    const [showWalletModal, setShowWalletModal] = useState<boolean>(false);
    const [showDepositModal, setShowDepositModal] = useState<boolean>(false);

    const showStatistic: boolean = useMemo(() => {
        if (!dates && !search)
            return false
        return true
    }, [dates, search]);

    const payoutRequest = useMemo(() => ({
        page,
        perPage,
        search,
        dateStart: dates?.[0],
        dateEnd: dates?.[1]
    }), [dates, page, perPage, search]);

    const payoutAnalyticRequest = useMemo(() => ({
        search,
        dateStart: dates?.[0],
        dateEnd: dates?.[1],
        type: OperationType.PAYOUT
    }), [dates, search]);

    const {
        data,
        error,
        loading,
        networkStatus
    }: QueryResult<GetPayoutsResponseType> = useQuery<GetPayoutsResponseType>(PAYOUTS_QUERY, {
        variables: {dto: {...payoutRequest}}
    });

    const simpleAnalytic: QueryResult<GetPaymentsSimpleAnalyticResponse> = useQuery<GetPaymentsSimpleAnalyticResponse>(PAYMENT_SIMPLE_ANALYTIC_QUERY, {
        variables: {dto: {...payoutAnalyticRequest}},
    });

    const [downloadStatistic, downloadStatisticData] = useMutation<SaveSimplePaymentAnalyticResponseType>(SAVE_PAYMENT_SIMPLE_ANALYTIC_MUTATION);


    const isLoading: boolean = useMemo<boolean>(() => {
        return loading || networkStatus === NetworkStatus.refetch;
    }, [loading, networkStatus]);

    useEffect((): void => {
        if (data) {
            const response: PaymentType[] = data.getPayoutOperations.data;
            const meta: PaginationType = data.getPayoutOperations.meta;
            dispatcher(setPayouts(response));
            dispatcher(setPayoutPagination(meta));
        }
    }, [data, dispatcher]);

    useEffect((): void => {
        if (error) {
            toast(<NotificationToast type={NotificationType.ERROR} title="Payouts"
                                     message="Failed to fetch payouts"/>, {
                toastId: 'payouts-fetch-error'
            })
        }
    }, [error]);

    useEffect((): void => {
        if (simpleAnalytic.data) {
            const response: PaymentsSimpleAnalyticType = simpleAnalytic.data.getPaymentSimpleAnalytic;

            setAnalyticCount(response.count);
            setAnalyticAmount(response.amount);
        }
    }, [simpleAnalytic.data, dispatcher]);

    // Payment Simple Analytic Fetch failed
    useEffect((): void => {
        if (simpleAnalytic.error) {
            toast(
                <NotificationToast
                    type={NotificationType.ERROR}
                    title="Payments Analytic"
                    message="Failed to fetch payments analytic"/>,
                {toastId: 'payments-analytic-fetch-error'}
            );
        }
    }, [simpleAnalytic.error]);

    useEffect((): void => {
        if (downloadStatisticData.data) {
            const url: string = downloadStatisticData.data.savePaymentSimpleAnalytic.url;
            setTimeout(() => {
                location.href = url
                setAnalyticDownloadLoading(false)
            }, 500)
        }
    }, [downloadStatisticData.data]);

    // Payment Simple Analytic Download failed
    useEffect((): void => {
        if (downloadStatisticData.error) {
            setAnalyticDownloadLoading(false)
            toast(
                <NotificationToast
                    type={NotificationType.ERROR}
                    title="Payments Statistic "
                    message="Failed to download"/>,
                {toastId: 'payments-analytic-download-error'}
            );
        }
    }, [downloadStatisticData.error]);

    const onChangePage = useCallback((page: number): void => {
        dispatcher(setPayoutPagination({
            page, perPage, total
        }))
    }, [dispatcher, perPage, total]);

    const reloadHandler = useCallback(async (): Promise<void> => {
        setDates(undefined);
    }, []);

    const datepickerChange = useCallback(async (dates?: Date[] | undefined): Promise<void> => {
        const prepareDates: string[] | undefined = dates?.map((d: Date) =>
            moment(d).format('YYYY-MM-DD')
        );
        setDates(prepareDates);
    }, []);

    const searchChange = useCallback(async (search?: string): Promise<void> => {
        setSearch(search);
    }, []);

    const prepareStatistic = useCallback(async (): Promise<void> => {
        setAnalyticDownloadLoading(true)
        await downloadStatistic({
            variables: {
                dto: {...payoutAnalyticRequest}
            }
        })
    }, [downloadStatistic, payoutAnalyticRequest]);

    return (
        <div className="relative">
            <main>
                {/* Page header */}
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">

                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
                            {/* Left: Title */}
                            <div className='flex items-center justify-between sm:justify-center mb-4 sm:mb-0'>
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mr-1">Payouts</h1>

                                <div className="m-1">
                                    <Tooltip title="Reload payouts" message="" position={Position.RIGHT}>
                                        <button
                                            aria-label="Reload payouts"
                                            onClick={reloadHandler}
                                            className={`btn flex items-center w-[30px] h-[30px] rounded-full p-1.5 justify-center bg-white text-indigo-500 font-bold transition-all opacity-1 hover:bg-indigo-100 active:scale-95`}
                                            disabled={loading || networkStatus === NetworkStatus.refetch}
                                        >
                                            <IoReloadOutline size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>

                                <Loader loading={isLoading} size={4}/>
                            </div>

                            {/* Download statistic  */}
                            <Tooltip title="Download statistic" message="" position={Position.TOP}>
                                <button
                                    onClick={prepareStatistic}
                                    disabled={(downloadStatisticData.loading || analyticDownloadLoading)}
                                    className={`btn mb-2 loading h-full sm:mb-0 sm:ml-3 bg-white text-indigo-500 transition-all active:scale-95 hover:bg-indigo-100 ${(downloadStatisticData.loading || analyticDownloadLoading) && 'pointer-events-none opacity-75'}`}>
                                    {(downloadStatisticData.loading || analyticDownloadLoading)
                                        ? <Loader loading={true} size={4}/>
                                        : <MdCloudDownload size={20} />
                                    }
                                </button>
                            </Tooltip>
                        </div>

                        <div className={`flex flex-col sm:flex-row ${showStatistic ? 'justify-between' : 'justify-end'} sm:items-center`}>
                            {showStatistic &&
                                <SimplePayoutAnalytic
                                    count={analyticCount}
                                    amount={analyticAmount}
                                />
                            }
                            <PaymentFilters
                                loading={isLoading}
                                datepickerChange={datepickerChange}
                                searchChange={searchChange}
                            />
                        </div>

                    </div>
                </div>
                <div className="px-4 sm:px-6 lg:px-8 pb-8 pt-4 w-full max-w-9xl mx-auto">
                    <TablePayout
                        headers={headers}
                        items={payouts}
                        emptyMessage="You don't have payouts"
                    />
                    <div className="mt-8">
                        {/*  pagination  */}
                        <TablePagination
                            total={total}
                            currentPage={page}
                            onPageChange={onChangePage}
                            perPage={perPage}
                            position={Position.END}
                        />
                    </div>
                </div>
            </main>

            <WalletModal
                show={showWalletModal}
                setShow={setShowWalletModal}
                setShowDepositModal={setShowDepositModal}
                currencyTypes={[CurrencyType.TOKEN]}
            />
            <DepositModal show={showDepositModal} setShow={setShowDepositModal}/>
            <AcceptPayoutModal/>
            <RejectPayoutModal/>
            <DetailPayoutModal/>
            <CompletePayoutModal />
            <CancelPayoutModal />
        </div>
    );
};

export default PayoutsPage;
