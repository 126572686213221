import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {PAYMENT_SLICE_NAME} from "../common/constants/store.constant.ts";
import {Align} from "../common/enums/align.enum.ts";
import {RootState} from "./index.ts";
import {PayoutStateType} from "../common/types/store/payout-state.type.ts";


const initialState: PayoutStateType = {
    headers: [
        {name: 'Date', value: 'createdAt', align: Align.LEFT},
        {name: 'Type', value: 'walletType', align: Align.LEFT},
        {name: 'Address', value: 'paymentAddress', align: Align.LEFT},
        {name: 'Amount', value: 'amount', align: Align.LEFT},
        {name: 'Description', value: 'description', align: Align.LEFT},
        {name: 'Status', value: 'status', align: Align.CENTER},
        {name: 'Action', value: 'action', align: Align.CENTER},
    ],
    payouts: [],
    payout: null,
    payoutId: null,
    acceptModal: {
        payoutId: null
    },
    rejectModal: {
        payoutId: null
    },
    completeModal: {
        payoutId: null
    },
    detailModal: {
        payoutId: null
    },
    cancelModal: {
        payoutId: null
    },
    pagination: {
        page: 1,
        perPage: 10,
        total: 0
    }
}

const slice: Slice<PayoutStateType> = createSlice({
    name: PAYMENT_SLICE_NAME,
    initialState,
    reducers: {
        setPayouts: (state: Draft<PayoutStateType>, {payload}): void => {
            state.payouts = payload;
        },
        setPayout: (state: Draft<PayoutStateType>, {payload}): void => {
            state.payout = payload;
        },
        setStatus: (state: Draft<PayoutStateType>, {payload}) => {
            const index: number = state.payouts.findIndex((p: any) => p.id === payload.id);

            if (index !== -1) {
                state.payouts[index].status = payload.status;
            }

            if (state.payout && state.payout.id === payload.id) {
                state.payout.status = payload.status;
            }
        },
        updatePayoutAddressStatus: (state: Draft<PayoutStateType>, {payload}) => {
            state.payouts.forEach((p, index) => {
                if (payload.addressId === p.payoutAddress?.id) {
                    state.payouts[index].payoutAddress.status = payload.status
                }
            });
        },
        addPayout: (state: Draft<PayoutStateType>, {payload}) => {
            const index: number = state.payouts.findIndex((p: any) => p.id === payload.id);

            if (index === -1) {
                state.payouts.unshift(payload);
            }
        },
        setPayoutPagination: (state: Draft<PayoutStateType>, {payload}): void => {
            state.pagination = payload;
        },
        setPayoutError: (state: Draft<PayoutStateType>, {payload}): void => {
            const index: number = state.payouts.findIndex((p: any) => p.id === payload.operationId);

            if (index !== -1) {
                state.payouts[index].error = payload.error;
            }
        },
        openAcceptPayoutModal: (state: Draft<PayoutStateType>, {payload}): void => {
            state.acceptModal.payoutId = payload;
        },
        closeAcceptPayoutModal: (state: Draft<PayoutStateType>): void => {
            state.acceptModal.payoutId = null;
        },
        openRejectPayoutModal: (state: Draft<PayoutStateType>, {payload}): void => {
            state.rejectModal.payoutId = payload;
        },
        closeRejectPayoutModal: (state: Draft<PayoutStateType>): void => {
            state.rejectModal.payoutId = null;
        },
        openDetailPayoutModal: (state: Draft<PayoutStateType>, {payload}): void => {
            state.detailModal.payoutId = payload;
        },
        closeDetailPayoutModal: (state: Draft<PayoutStateType>): void => {
            state.detailModal.payoutId = null;
        },
        openCompletePayoutModal: (state: Draft<PayoutStateType>, {payload}): void => {
            state.completeModal.payoutId = payload;
        },
        closeCompletePayoutModal: (state: Draft<PayoutStateType>): void => {
            state.completeModal.payoutId = null;
        },
        openCancelPayoutModal: (state: Draft<PayoutStateType>, {payload}): void => {
            state.cancelModal.payoutId = payload;
        },
        closeCancelPayoutModal: (state: Draft<PayoutStateType>): void => {
            state.cancelModal.payoutId = null;
        },
    }
});

export const payoutSelector = (state: RootState) => state.payout;
export const {
    setPayouts,
    setPayout,
    setPayoutPagination,
    setStatus,
    setPayoutError,
    updatePayoutAddressStatus,
    openAcceptPayoutModal,
    closeAcceptPayoutModal,
    openRejectPayoutModal,
    closeRejectPayoutModal,
    addPayout,
    openCompletePayoutModal,
    closeCompletePayoutModal,
    openDetailPayoutModal,
    closeDetailPayoutModal,
    openCancelPayoutModal,
    closeCancelPayoutModal
} = slice.actions;
export default slice.reducer;
