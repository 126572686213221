import {DocumentNode, gql} from "@apollo/client";

export const BALANCE_TABLE_INFO_FRAGMENT: DocumentNode = gql(`
    fragment BalanceTableInfo on Operation {
      id
      currency
      walletType
      status
      amount
      description
      paymentAt
      createdAt
      incomeHash
      incomeBlocknumber
      transactionHashLink
      transactionBlocknumberLink
    }
`);
