import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {Transition} from "@headlessui/react";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";

import {
    closeCancelPayoutModal,
    payoutSelector,
    setPayout
} from "../../../store/payout.store.ts";
import {useMutation} from "@apollo/client";
import {toast} from "react-toastify";
import {NotificationType} from "../../enums/notification.enum.ts";
import NotificationToast from "../toasts/notification.toast.tsx";
import { formatCurrency } from '../../utils/currency.util.ts';
import {CHANGE_ERROR_PAYOUT_MUTATION} from "../../../api/graphql/mutations/change-payout.mutation.ts";
import {ChangePayoutResponse} from "../../types/payment/change-payout-response.type.ts";
import {OperationStatus} from "../../enums/operation-status.enum.ts";


const CancelPayoutModal: FC = () => {
    const dispatcher: AppDispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    const {cancelModal: {payoutId}, payout} = useSelector(payoutSelector);

    const [cancelPayout, cancelPayoutData] = useMutation<ChangePayoutResponse>(CHANGE_ERROR_PAYOUT_MUTATION);


    useEffect((): void => {
        if (!payoutId) {
            setShow(false)
        } else {
            setShow(true)
        }

    }, [payoutId]);

    useEffect((): void => {
        if (!show) {
            dispatcher(closeCancelPayoutModal(null));
            dispatcher(setPayout(null));
        }
    }, [dispatcher, show]);

    useEffect(() => {
        if (cancelPayoutData.error) {
            toast(<NotificationToast type={NotificationType.WARNING} title="Payout" message={cancelPayoutData.error.message} />, {
                toastId: 'cancel-payout-error'
            });
        }
    }, [cancelPayoutData.error]);

    useEffect(() => {
        if (cancelPayoutData.data) {
            toast(<NotificationToast type={NotificationType.SUCCESS} title="Payout" message={cancelPayoutData.data.changeErrorPayoutOperation.message} />, {
                toastId: 'cancel-payout-success'
            });

            setShow(false);
        }
    }, [cancelPayoutData.data]);

    const accept = useCallback(async () => {
        await cancelPayout({
            variables: {
                operationId: payoutId,
                status: OperationStatus.CANCELED
            }
        });
    }, [cancelPayout, payoutId]);

    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div onClick={(e) => e.stopPropagation()}
                         className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full">

                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Cancel Payout Operation</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <div className="p-4 pb-0">
                            <div className="text-xs italic mb-6 text-center">
                                By submitting this form, you confirm that you are marking this payment as cancelled.
                            </div>

                            <div className="p-2">
                                <div className="text-sm flex items-center justify-between mr-1 font-medium"><span className="font-bold">Address:</span>{payout?.payoutAddress.address}</div>
                                <div className="text-sm flex items-center justify-between mr-1 font-medium"><span className="font-bold">Amount:</span>{formatCurrency(payout?.amount || '0')} {payout?.currency}</div>
                            </div>

                        </div>
                        {/* Modal footer */}

                        <div className="flex justify-end p-4">
                            <button onClick={() => setShow(false)}
                                    className='bg-white border text-gray-700 hover:bg-gray-100 hover btn-sm whitespace-nowrap mr-2'>
                                Cancel
                            </button>
                            <button
                                onClick={accept}
                                className={`${cancelPayoutData.loading && 'opacity-40'} bg-indigo-500 hover:bg-indigo-600 btn-sm text-white whitespace-nowrap`}>
                                {cancelPayoutData.loading
                                    ? <svg className="animate-spin w-4 h-4 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                    </svg>
                                    : ''
                                }
                                Mark as Canceled
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default CancelPayoutModal;
