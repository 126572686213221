import {DocumentNode, gql} from "@apollo/client";

export const TEMP_ADDRESS_INFO_FRAGMENT: DocumentNode = gql(`
    fragment TempAddressInfo on TempAddress {
      id
      isFree
      type
      address
      balances {
        type
        currency
        currencyType
        balance
      }
    }
`);
