import {Dispatch, FC, SetStateAction} from "react";
import PropTypes from "prop-types";

type SwitchComponentType = {
    value: boolean;
    onChange: Dispatch<SetStateAction<boolean>>;
}

const SwitchComponent: FC<SwitchComponentType> = ({value, onChange}) => {
    return (
        <div className="flex items-center">
            <div className="form-switch">
                <input type="checkbox" id="switch-1" className="sr-only" checked={value} onChange={() => onChange(!value)} />
                <label className="bg-slate-400" htmlFor="switch-1">
                    <span className="bg-white shadow-sm" aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                </label>
            </div>
        </div>
    );
};

SwitchComponent.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export default SwitchComponent;
