import {gql} from "@apollo/client";

export const TFA_GENERATE_MUTATION = gql(`
    mutation GenerateTFA {
      generateTFA {
        uri
        secret
      }
    }
`);
