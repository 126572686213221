import {Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import {QueryResult, useMutation, useQuery} from "@apollo/client";
import {userSelector} from "../../../store/user.store.ts";
import Spinner from "../general/spinner.component.tsx";
import {GetCurrenciesType} from "../../types/payment/get-currencies.type.ts";
import {GetCurrenciesResponseType} from "../../types/payment/get-currencies-response.type.ts";
import {CURRENCIES_QUERY} from "../../../api/graphql/queries/currencies.query.ts";
import {toast} from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import {NotificationType} from "../../enums/notification.enum.ts";
import {WalletType} from "../../enums/wallet-type.enum.ts";
import {AddAddressToWhitelistType} from "../../types/payment/add-address-to-whitelist.type.ts";
import {mixed, object, ObjectSchema, string} from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    ADD_PAYOUT_ADDRESS_TO_WHITELIST_MUTATION
} from "../../../api/graphql/mutations/add-payout-address-to-whitelist.mutation.ts";
import {
    AddPayoutAddressToWhitelistResponseType
} from "../../types/payment/add-payout-address-to-whitelist-response.type.ts";
import {AppDispatch} from "../../../store";
import {addPayoutAddress} from "../../../store/payout-addresses.store.ts";


const initialValues: AddAddressToWhitelistType = {
    address: '',
    wallet: WalletType.BSC,
    description: '',
    tfaCode: ''
};

const validationSchema: ObjectSchema<AddAddressToWhitelistType> = object({
    address: string().required("Required"),
    wallet: mixed<WalletType>().oneOf(Object.values(WalletType)).required("Required"),
    description: string(),
    tfaCode: string()
});

type AddPayoutAddressModalType = {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>
}

const AddPayoutAddressModal: FC<AddPayoutAddressModalType> = ({show, setShow}) => {
    const dispatcher: AppDispatch = useDispatch();

    const [showTfaField, setShowTfaField] = useState<boolean>(false);
    const [currencies, setCurrencies] = useState<GetCurrenciesType[]>([])

    const currenciesQuery: QueryResult<GetCurrenciesResponseType> = useQuery<GetCurrenciesResponseType>(CURRENCIES_QUERY, {
        skip: !show
    });

    const {profile: {isTfaEnabled}} = useSelector(userSelector);

    const [addToWhitelist, addToWhitelistData] = useMutation<AddPayoutAddressToWhitelistResponseType>(ADD_PAYOUT_ADDRESS_TO_WHITELIST_MUTATION);

    useEffect(() => {
        setShowTfaField(isTfaEnabled);
    }, [isTfaEnabled]);

    useEffect(() => {
        if (currenciesQuery.data) {
            const response: GetCurrenciesType[] = currenciesQuery.data.getCurrencies;
            setCurrencies(response)
        }
    }, [currenciesQuery.data])

    useEffect(() => {
        if (currenciesQuery.error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="Get currencies" message="Failed to fetch currencies"/>, {
                toastId: 'currencies-fetch-error'
            })
        }
    }, [currenciesQuery.error])

    useEffect(() => {
        if (addToWhitelistData.error) {
            toast(<NotificationToast type={NotificationType.WARNING} title="Add to Whitelist" message={addToWhitelistData.error.message} />, {
                toastId: 'add-to-whitelist-error'
            });
        }
    }, [addToWhitelistData.error]);

    useEffect(() => {
        if (addToWhitelistData.data) {
            toast(<NotificationToast type={NotificationType.SUCCESS} title="Add to Whitelist" message="Successfully added" />, {
                toastId: 'approve-address-success'
            });
            const response = addToWhitelistData.data.addPayoutAddressToWhitelist
            dispatcher(addPayoutAddress(response))
            setShow(false);
        }
    }, [addToWhitelistData.data, dispatcher, setShow]);

    const handleSubmit = useCallback(async (values: AddAddressToWhitelistType) => {
        await addToWhitelist({
            variables: {
                dto: {
                    address: values.address,
                    wallet: values.wallet,
                    description: values.description
                },
                tfaCode: values.tfaCode
            }
        });
    }, [addToWhitelist]);

    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div onClick={(e) => e.stopPropagation()}
                         className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full">

                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Add Address to Whitelist</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({errors, touched, isSubmitting, isValid}) => (
                                <Form>
                                    <div className="p-4 pb-0">
                                        <div className="text-xs italic mb-6 text-center">
                                            By submitting this form, you confirm that this is a trusted address and all further
                                            payments will be processed automatically.
                                        </div>

                                        <label className="block text-sm font-medium mb-1" htmlFor="safe_password">Address:</label>
                                        <div className="relative mb-2">
                                            <Field
                                                id='payout_address'
                                                type="text"
                                                name="address"
                                                placeholder="Address"
                                                className={`form-input w-full ${errors.address && touched.address ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                            />
                                        </div>

                                        <ErrorMessage
                                            name="address"
                                            component="div"
                                            className="text-rose-500 text-xs"
                                        />

                                        <label className="block text-sm font-medium mb-1" htmlFor="safe_password">Select Network:</label>
                                        <div className="relative mb-2">
                                            <div role="group" aria-labelledby="currency-radio-group" className={`${currencies.length > 2 ? 'sm:grid sm:grid-cols-2' : ''} `}>
                                                {currenciesQuery.loading
                                                    ? <div className='flex items-center justify-center'>
                                                        <Spinner className="w-[35px] h-[35px] my-6 mx-auto"/>
                                                    </div>
                                                    : currencies.map((currency: GetCurrenciesType, index: number) => (
                                                        <div key={index} className="m-3 col-span-1">
                                                            <label className="flex items-center cursor-pointer w-fit">
                                                                <Field type="radio" name="wallet" value={currency.type} className="form-radio cursor-pointer" />
                                                                <span className="text-sm ml-2 text-gray-custom-800 font-semibold">{currency.type}</span>
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        <ErrorMessage
                                            name="wallet"
                                            component="div"
                                            className="text-rose-500 text-xs"
                                        />

                                        <label className="block text-sm font-medium mb-1" htmlFor="safe_password">Description:</label>
                                        <div className="relative">
                                            <Field
                                                id='description'
                                                type="text"
                                                name="description"
                                                placeholder="Description"
                                                className={`form-input w-full ${errors.description && touched.description ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                            />
                                        </div>

                                        <ErrorMessage
                                            name="description"
                                            component="div"
                                            className="text-rose-500 text-xs"
                                        />

                                        <Transition
                                            as={Fragment}
                                            show={showTfaField}
                                            enter="transition ease-in-out duration-600"
                                            enterFrom="opacity-0 translate-y-4"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in-out duration-300"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-4"
                                        >
                                            <div className="mt-2">
                                                <label className="block text-sm font-medium mb-1" htmlFor="tfa_code">TFA Code:</label>
                                                <div className="relative">
                                                    <Field
                                                        id="tfa_code"
                                                        type="text"
                                                        name="tfaCode"
                                                        placeholder="TFA Code"
                                                        className={`form-input w-full ${errors.tfaCode && touched.tfaCode ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                                    />
                                                </div>

                                                <ErrorMessage
                                                    name="tfaCode"
                                                    component="div"
                                                    className="text-rose-500 text-xs"
                                                />
                                            </div>
                                        </Transition>
                                    </div>
                                    {/* Modal footer */}

                                    <div className="flex justify-end p-4">
                                        <button onClick={() => setShow(false)}
                                                className='bg-white border text-gray-700 hover:bg-gray-100 hover btn-sm whitespace-nowrap mr-2'>
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting || currenciesQuery.loading || !isValid}
                                            className={`${(isSubmitting || currenciesQuery.loading || !isValid) ? 'opacity-40' : 'opacity-1'} bg-indigo-500  hover:bg-indigo-600 btn-sm text-white whitespace-nowrap`}
                                        >
                                            {isSubmitting
                                                ? <svg className="animate-spin w-4 h-4 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                                </svg>
                                                : ''
                                            }
                                            Add
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default AddPayoutAddressModal;
