import {DocumentNode, gql} from "@apollo/client";

export const PAYOUT_ADDRESSES_QUERY: DocumentNode = gql(`    
    query GetPayoutAddresses($dto: PayoutAddressesRequest!) {
      getPayoutAddresses(payoutAddressesDTO: $dto) {
        data {
         ...PayoutAddress
        }
        meta {
          page
          perPage
          total
        }
      }
    }
`);
