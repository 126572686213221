import { createSlice, Draft, Slice } from "@reduxjs/toolkit";
import { PAYMENT_SLICE_NAME } from "../common/constants/store.constant.ts";
import { RootState } from "./index.ts";
import { Align } from "../common/enums/align.enum.ts";
import { TempAddressStateType } from "../common/types/store/temp-address-state.type.ts";

const initialState: TempAddressStateType = {
	headers: [
		{ name: "Type", value: "walletType", align: Align.LEFT },
		{ name: "Address", value: "address", align: Align.LEFT },
		{ name: "Balance", value: "balance", align: Align.LEFT },
	],
	addresses: [],
	pagination: {
		page: 1,
		perPage: 10,
		total: 0,
	},
};

const slice: Slice = createSlice({
	name: PAYMENT_SLICE_NAME,
	initialState,
	reducers: {
		setTempAddresses: (
			state: Draft<TempAddressStateType>,
			{ payload },
		): void => {
			state.addresses = payload;
		},
		updateBalance: (state: Draft<TempAddressStateType>, { payload }): void => {
			const index: number = state.addresses.findIndex(
				(a) => a.id === payload.addressId,
			);

			if (index !== -1) {
				const bIndex: number = state.addresses[index].balances.findIndex(
					(b) => b.currency === payload.currency,
				);

				if (bIndex !== -1) {
					state.addresses[index].balances[bIndex].balance = payload.balance;
				}
			}
		},
		setPagination: (state: Draft<TempAddressStateType>, { payload }): void => {
			state.pagination = payload;
		},
	},
});

export const tempAddressSelector = (state: RootState) => state.tempAddress;
export const { setTempAddresses, setPagination, updateBalance } = slice.actions;
export default slice.reducer;
