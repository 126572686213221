import {DocumentNode, gql} from "@apollo/client";

export const PAYOUT_ADDRESS_FRAGMENT: DocumentNode = gql(`
    fragment PayoutAddress on PayoutAddress {
        id
        address
        name
        status
        walletType
        currency
        description
        createdAt 
    }
`);
