import { DocumentNode, gql } from "@apollo/client";

export const PAYMENT_TABLE_INFO_FRAGMENT: DocumentNode = gql(`
    fragment PaymentTableInfo on Operation {
      id
      currency
      walletType
      status
      amount
      paymentAddress
      paymentFee
      internalId
      clientTransactionLink
      paymentLink
      orderId
      comment
      description
      paymentAt
      expiredAt
      createdAt
      error
    }
`);
