import {FC} from "react";
import {TbError404} from 'react-icons/tb'


const PaymentNotFound: FC = () => {
    return (
        <div className="bg-white px-8 pb-5 rounded-[10px] shadow-lg border">

            <div className="text-center mb-4 pt-7"></div>

            <div className="flex flex-col items-center justify-center mb-16">
                <div className="rounded-full bg-orange-100 p-5 mb-5">
                    <TbError404 className='text-orange-500' size={130} />
                </div>
                <p className='font-medium text-center'>Payment not found!</p>
            </div>
        </div>
    );
};


export default PaymentNotFound;
