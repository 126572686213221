import {useMemo} from "react";
import {UsePaginationType} from "../types/hooks/use-pagination.type";


const range = (start: number, end: number): number[] => {
    const length: number = end - start + 1;
    return Array.from({length}, (_, idx: number) => idx + start);
}

export const usePagination = ({total, perPage, currentPage, siblingCount = 1,  dots = '...'}: UsePaginationType) => {
    return useMemo(() => {
        const totalPageCount: number = Math.ceil(total / perPage);

        const totalPageNumbers: number = siblingCount + 5;

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSiblingIndex: number = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex: number = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        const shouldShowLeftDots: boolean = leftSiblingIndex > 2;
        const shouldShowRightDots: boolean = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex: number = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount: number = 3 + 2 * siblingCount;
            const leftRange: number[] = range(1, leftItemCount);

            return [...leftRange, dots, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount: number = 3 + 2 * siblingCount;
            const rightRange: number[] = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, dots, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange: number[] = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex];
        }
    }, [total, perPage, siblingCount, currentPage, dots]);
};
