import {FC} from "react";
import DatepickerRange from "../general/datepicker-range.component.tsx";
import Search from "../general/search.component.tsx";
import {IPaymentFilters} from "../../interfaces/payments/payment-filters.interface.ts";
import PropTypes from "prop-types";

const PaymentFilters: FC<IPaymentFilters> = ({
    loading,
    datepickerChange,
    searchChange
}) => {
    return (
        <div className="flex flex-col-reverse sm:flex-row">
            {/*DatepickerRange*/}
            <DatepickerRange
                label="Select date range"
                className="mr-2 h-10"
                onChange={datepickerChange}
                isDisabled={loading}
            />
            {/* Search */}
            <Search
                onChange={searchChange}
                isDisabled={loading}
            />
        </div>
    )
}

PaymentFilters.propTypes = {
    loading: PropTypes.bool,
    datepickerChange: PropTypes.func,
    searchChange: PropTypes.func,
};

PaymentFilters.defaultProps = {
    loading: true,
    datepickerChange: (dates?: Date[]) => console.log(`[datepickerChange]: ${dates}`),
    searchChange: (search?: string) => console.log(`[searchChange]: ${search}`),
}

export default PaymentFilters
