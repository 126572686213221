import PropTypes from "prop-types";
import {IconType} from "../../types/auth/icon.type";
import {FC} from "react";

const ApiReferenceIcon: FC<IconType> = ({ width, height, className, isActive }) => {

    const firstColor = isActive ? '#6366F1' : '#475569'
    const SecondColor = isActive ? '#A5B4FC' : '#94A3B8'

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3545 7H6.35449C5.82406 7 5.31535 7.21071 4.94028 7.58579C4.56521 7.96086 4.35449 8.46957 4.35449 9V18C4.35449 18.5304 4.56521 19.0391 4.94028 19.4142C5.31535 19.7893 5.82406 20 6.35449 20H15.3545C15.8849 20 16.3936 19.7893 16.7687 19.4142C17.1438 19.0391 17.3545 18.5304 17.3545 18V13" stroke={SecondColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.3545 14L20.3545 4" stroke={firstColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.3545 4H20.3545V9" stroke={firstColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

ApiReferenceIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isActive: PropTypes.bool,
    className: PropTypes.string,
};

ApiReferenceIcon.defaultProps = {
    width: 25,
    height: 24
};

export default ApiReferenceIcon
