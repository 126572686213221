import {NavLink, useLocation, Location} from "react-router-dom";
import {CgUserList} from 'react-icons/cg'
import {BsPersonFill, BsFillShieldFill} from 'react-icons/bs'
import {MdNotificationsActive} from 'react-icons/md';

const ProfileNavbar = () => {
    const location: Location = useLocation();
    const { pathname }: {pathname: string} = location;

    return (
        <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 min-w-60 md:space-y-3">
            <div>
                <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
                    <li className="mr-0.5 md:mr-0 md:mb-0.5">
                        <NavLink end to="/profile/personal" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/profile/personal') && 'bg-indigo-50'}`}>
                            <BsPersonFill size={22} className={`w-4 h-4 shrink-0 fill-current text-slate-400 mr-2 ${pathname.includes('/profile/personal') ? 'text-[#6366F1]' : 'text-[#94A3B8]'}`}/>
                            <span className={`text-sm font-medium ${pathname.includes('/profile/personal') ? 'text-indigo-500' : 'hover:text-slate-700'}`}>Personal Settings</span>
                        </NavLink>
                    </li>
                    <li className="mr-0.5 md:mr-0 md:mb-0.5">
                        <NavLink end to="/profile/payout-whitelist" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/profile/payout-whitelist') && 'bg-indigo-50'}`}>
                            <CgUserList size={22} className={`shrink-0 fill-current text-slate-400 mr-1 ${pathname.includes('/profile/payout-whitelist') ? 'text-[#6366F1]' : 'text-[#94A3B8]'}`}/>
                            <span className={`text-sm font-medium ${pathname.includes('/profile/payout-whitelist') ? 'text-indigo-500' : 'hover:text-slate-700'}`}>Payout Whitelist</span>
                        </NavLink>
                    </li>
                    <li className="mr-0.5 md:mr-0 md:mb-0.5">
                        <NavLink end to="/profile/notification" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/profile/notification') && 'bg-indigo-50'}`}>
                            <MdNotificationsActive size={22} className={`shrink-0 fill-current text-slate-400 mr-2 ${pathname.includes('/profile/notification') ? 'text-[#6366F1]' : 'text-[#94A3B8]'}`}/>
                            <span className={`text-sm font-medium ${pathname.includes('/profile/notification') ? 'text-indigo-500' : 'hover:text-slate-700'}`}>Notification</span>
                        </NavLink>
                    </li>
                    <li className="mr-0.5 md:mr-0 md:mb-0.5">
                        <NavLink end to="/profile/security" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/profile/security') && 'bg-indigo-50'}`}>
                            <BsFillShieldFill size={18} className={`shrink-0 fill-current text-slate-400 mr-2 ${pathname.includes('/profile/security') ? 'text-[#6366F1]' : 'text-[#94A3B8]'}`}/>
                            <span className={`text-sm font-medium ${pathname.includes('/profile/security') ? 'text-indigo-500' : 'hover:text-slate-700'}`}>Security</span>
                        </NavLink>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default ProfileNavbar;
