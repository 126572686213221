import { FC, useMemo } from "react";
import PropTypes from "prop-types";
import { NetworkInfo } from "../../types/networks/networks.type.ts";
import { WalletType } from "../../enums/wallet-type.enum.ts";
import { Currency } from "../../enums/currency.enum.ts";
import { NetworkName } from "../../enums/networks.enum.ts";
import {
	getDescriptionByType,
	getNetworkIcon,
} from "../../utils/currency.util.ts";
import { FiExternalLink } from "react-icons/fi";

const NetworkItem: FC<NetworkInfo> = (params: NetworkInfo) => {
	const tetherExplorerLink: string = useMemo(() => {
		switch (params.tetherSymbol) {
			case Currency.BEP20:
				return `${params.explorerLink}/address/${params.contractAddress}`;
			case Currency.TRC20:
				return `${params.explorerLink}/#/contract/${params.contractAddress}`;
			case Currency.JETTON:
				return `${params.explorerLink}/address/${params.contractAddress}#source`;
			default:
				return "#";
		}
	}, [params.contractAddress, params.explorerLink, params.tetherSymbol]);

	return (
		<div className="bg-white rounded shadow text-center p-5">
			<div className="flex flex-col h-full">
				<div className="grow mb-2">
					{/* Icon */}
					<img
						className="inline-flex w-12 h-12 rounded-full bg-indigo-400"
						src={getNetworkIcon(params.name)}
						alt={params.name.toString()}
					/>
					{/* Content */}
					<h3 className="text-lg font-semibold text-slate-800 mb-1">
						{getDescriptionByType(params.name)}
					</h3>
					<div className="text-sm">
						<div className="flex items-center justify-between">
							<strong>Coin</strong>
							<span className="font-medium">{params.nativeSymbol}</span>
						</div>
						<div className="flex items-center justify-between">
							<strong>Token</strong>
							<a
								href={tetherExplorerLink}
								target="_blank"
								className="flex items-center justify-center gap-1 text-indigo-500 font-medium"
							>
								{params.tetherSymbol}
								<FiExternalLink />
							</a>
						</div>
						<div className="flex items-center justify-between">
							<strong>Network Name</strong>
							<span className="font-medium">{params.networkName}</span>
						</div>
						<div className="flex items-center justify-between">
							<strong>Explorer</strong>
							<a
								href={params.explorerLink}
								target="_blank"
								className="flex items-center justify-center gap-1 text-indigo-500 font-medium"
							>
								Link
								<FiExternalLink />
							</a>
						</div>
						<div className="flex items-center justify-between">
							<strong>Faucet({params.nativeSymbol})</strong>
							<a
								href={params.faucetCoinLink}
								target="_blank"
								className="flex items-center justify-center gap-1 text-indigo-500 font-medium"
							>
								Link
								<FiExternalLink />
							</a>
						</div>
						<div className="flex items-center justify-between">
							<strong>Faucet({params.tetherSymbol})</strong>
							<a
								href={params.faucetTokenLink}
								target="_blank"
								className="flex items-center justify-center gap-1 text-indigo-500 font-medium"
							>
								Link
								<FiExternalLink />
							</a>
						</div>
					</div>
				</div>
				{/*/!* Link *!/*/}
				{/*<div>*/}
				{/*    <a className="text-sm font-medium text-indigo-500 hover:text-indigo-600" href="#0">*/}
				{/*        Explore -&gt;*/}
				{/*    </a>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

NetworkItem.propTypes = {
	name: PropTypes.oneOf(Object.values(WalletType)).isRequired,
	fee: PropTypes.string.isRequired,
	nativeSymbol: PropTypes.oneOf([Currency.TRX, Currency.BNB]).isRequired,
	tetherSymbol: PropTypes.oneOf([Currency.TRC20, Currency.BEP20]).isRequired,
	networkName: PropTypes.oneOf(Object.values(NetworkName)).isRequired,
	explorerLink: PropTypes.string.isRequired,
	contractAddress: PropTypes.string.isRequired,
	faucetCoinLink: PropTypes.string.isRequired,
	faucetTokenLink: PropTypes.string.isRequired,
};

export default NetworkItem;
