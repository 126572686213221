import {DocumentNode, gql} from "@apollo/client";

export const UPDATE_ADMIN_BALANCE_MUTATION: DocumentNode = gql(`
    mutation UpdateAdminBalance($type: WalletType!, $currency: Currency!) {
        updateAdminBalance(type: $type, currency: $currency) {
            address
            type
            balance
        }
    }
`);
