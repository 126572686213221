export enum NotificationName {
    USER = 'USER',
    OPERATION_STATUS = 'OPERATION_STATUS',
    OPERATION_SYS_STATUS = 'OPERATION_SYS_STATUS',
    OPERATION_USER_STATUS = 'OPERATION_USER_STATUS',
    OPERATION_CREATED = 'OPERATION_CREATED',
    UPDATE_ADMIN_BALANCE = 'UPDATE_ADMIN_BALANCE',
    OPERATION_ERROR = 'OPERATION_ERROR',
    PAYOUT_ADDRESS_STATUS = 'PAYOUT_ADDRESS_STATUS',
    PAYOUT_ADDRESS_CREATED = 'PAYOUT_ADDRESS_CREATED',
    PAYOUT_CREATED = 'PAYOUT_CREATED',
    PAYOUT_CHANGED = 'PAYOUT_CHANGED',
    UPDATE_TEMP_ADDRESS_BALANCE = 'UPDATE_TEMP_ADDRESS_BALANCE',
    BALANCE_OPERATION_CREATED = 'BALANCE_OPERATION_CREATED'
}
