import {Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Transition} from "@headlessui/react";
import {useSelector} from "react-redux";
import {userSelector} from "../../../store/user.store.ts";

import {UpdateSecretKeyResponseType} from "../../types/payment/update-secret-key-response.type.ts";
import {useMutation} from "@apollo/client";
import {UPDATE_SECRET_KEY_MUTATION} from "../../../api/graphql/mutations/update-secret-key.mutation.ts";
import {toast} from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import {NotificationType} from "../../enums/notification.enum.ts";

type UpdateSecretKeyModalType = {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>,
    setSecretKey: Dispatch<SetStateAction<string>>,
}

const UpdateSecretKeyModal: FC<UpdateSecretKeyModalType> = ({show, setShow, setSecretKey}) => {
    const [tfaCode, setTfaCode] = useState<string>('');
    const [showTfaField, setShowTfaField] = useState<boolean>(false);

    const {profile: {isTfaEnabled}} = useSelector(userSelector);

    const [updateSecretKey, updateSecretData] = useMutation<UpdateSecretKeyResponseType>(UPDATE_SECRET_KEY_MUTATION);

    useEffect(() => {
        setShowTfaField(isTfaEnabled);
    }, [isTfaEnabled]);

    useEffect(() => {
        if (!show) {
            setTfaCode('')
        }
    }, [show]);


    useEffect(() => {
        if (updateSecretData.error) {
            toast(<NotificationToast type={NotificationType.WARNING} title="Secret Key" message={updateSecretData.error.message} />, {
                toastId: 'update-secret-error'
            });
        }
    }, [updateSecretData.error]);

    useEffect(() => {
        if (updateSecretData.data) {
            const {secretKey} = updateSecretData.data.updateSecretKey
            setSecretKey(secretKey)

            setShow(false);
        }
    }, [setSecretKey, setShow, updateSecretData.data]);

    const handleSubmit = useCallback(async () => {
        await updateSecretKey({
            variables: {
                tfaCode
            }
        });
    }, [tfaCode, updateSecretKey]);

    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div onClick={(e) => e.stopPropagation()}
                         className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full">

                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Generate Secret Key</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <div className="p-4 pb-0">
                            <div className="text-xs italic mb-6 text-center">
                                By submitting this form, you confirm that you are generating a new secret key.
                                <b>It will be shown only once and will be hidden after copying</b>
                            </div>

                            <Transition
                                as={Fragment}
                                show={showTfaField}
                                enter="transition ease-in-out duration-600"
                                enterFrom="opacity-0 translate-y-4"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-4"
                            >
                                <div className="mt-2">
                                    <label className="block text-sm font-medium mb-1" htmlFor="tfa_code">TFA
                                        Code:</label>
                                    <div className="relative">
                                        <input
                                            name="tfa_code"
                                            value={tfaCode}
                                            onChange={(e) => setTfaCode(e.target.value)}
                                            placeholder="TFA Code"
                                            className='form-input w-full max-w-[646px] mr-1'
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </Transition>
                        </div>
                        {/* Modal footer */}

                        <div className="flex justify-end p-4">
                            <button onClick={() => setShow(false)}
                                    className='bg-white border text-gray-700 hover:bg-gray-100 hover btn-sm whitespace-nowrap mr-2'>
                                Cancel
                            </button>
                            <button
                                onClick={handleSubmit}
                                className={`${updateSecretData.loading && 'opacity-40'} bg-indigo-500 hover:bg-indigo-600 btn-sm text-white whitespace-nowrap`}>
                                {updateSecretData.loading
                                    ? <svg className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                           viewBox="0 0 16 16">
                                        <path
                                            d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"/>
                                    </svg>
                                    : ''
                                }
                                Generate
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default UpdateSecretKeyModal;
