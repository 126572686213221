import {FC, Fragment, MouseEvent, useCallback, useEffect, useState} from 'react';
import {Transition} from "@headlessui/react";
import {
    formatCurrency,
    getExplorerLinkByType,
    getHumanDescCurrency,
    getNetworkIcon
} from "../../utils/currency.util.ts";
import {openInNewTab, statusColor} from "../../utils/common.util.ts";
import useTimezone from "../../hooks/timezone.hook.ts";
import moment from "moment-timezone";
import {OperationError, OperationErrorMsg} from "../../enums/operation-error.enum.ts";
import AddressComponent from "../general/address.component.tsx";
import {Position} from "../../enums/position.enum.ts";
import {useDispatch, useSelector} from "react-redux";
import {closeDetailPayoutModal, payoutSelector, setPayout} from "../../../store/payout.store.ts";
import {AppDispatch} from "../../../store";
import Spinner from "../general/spinner.component.tsx";
import {openCallbackModel} from "../../../store/callback.store.ts";
import CallbackComponent from "../general/callback.component.tsx";


const DetailPayoutModal: FC = () => {
    const dispatcher: AppDispatch = useDispatch();
    const timezone: string = useTimezone();
    const [show, setShow] = useState<boolean>(false);
    const {detailModal: {payoutId}, payout} = useSelector(payoutSelector);

    useEffect((): void => {
        if (payoutId) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [payoutId]);

    useEffect(() => {
        if (!show) {
            dispatcher(closeDetailPayoutModal(null));
            dispatcher(setPayout(null));
        }
    }, [dispatcher, show]);

    const linkHandler = useCallback((e: MouseEvent<HTMLDivElement>, url?: string): void => {
        e.stopPropagation();
        if (url) {
            openInNewTab(url);
        }
    }, []);

    const callbackHandler = useCallback(async () => {
        dispatcher(openCallbackModel({
            operationId: payout?.id,
            url: payout?.callbackUrl
        }))
    }, [dispatcher, payout?.id, payout?.callbackUrl]);

    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div
                    onClick={() => setShow(false)}
                    className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
                ></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    onClick={() => setShow(false)}
                    className="fixed inset-0 z-50 flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div
                        onClick={e => e.stopPropagation()}
                        className="relative bg-white rounded shadow-lg overflow-auto max-w-screen-sm w-full max-h-full"
                    >
                        {!payout &&
                            <div
                                className='absolute left-0 right-0 w-full h-full bg-white z-50 flex items-center justify-center'>
                                <Spinner className="w-[35px] h-[35px] my-6 mx-auto"/>
                            </div>
                        }

                        <button className="absolute top-6 right-6 text-slate-400 hover:text-slate-500" onClick={(e) => {
                            e.stopPropagation();
                            setShow(false);
                        }}>
                            <div className="sr-only">Close</div>
                            <svg className="w-4 h-4 fill-current">
                                <path
                                    d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"/>
                            </svg>
                        </button>

                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Payout #{payout?.id}</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <div className="p-4 divide-y">
                            <fieldset className="mb-2">
                                <legend className="font-extrabold mb-3">Operations details:</legend>
                                <div className="sm:columns-2 columns-1">
                                    <div className="text-sm font-medium flex items-center justify-between mb-2"><span
                                        className="font-semibold">Network:</span>
                                        <div className="flex items-center justify-center">
                                            <span className="mr-2">{payout?.walletType}</span>
                                            <img className="w-7 h-7 rounded-full "
                                                 src={getNetworkIcon(payout?.walletType)}
                                                 alt="Network icon"/>
                                        </div>
                                    </div>
                                    <div className="text-sm font-medium flex items-center justify-between mb-2"><span
                                        className="font-semibold">Amount:</span> {formatCurrency(payout?.amount || '0') + ` ${getHumanDescCurrency(payout?.currency)}`}
                                    </div>
                                    <div className="text-sm font-medium flex items-center justify-between mb-2"><span
                                        className="font-semibold">Status:</span>
                                        <span
                                            className={`w-fit font-medium rounded-full px-2.5 py-0.5 ${statusColor(payout?.status)}`}>{payout?.status?.toLowerCase()}</span>
                                    </div>
                                    <div className="text-sm font-medium flex items-center justify-between mb-2"><span
                                        className="font-semibold">CreatedAt:</span>
                                        <span className="font-medium text-slate-700 text-right">{
                                            payout?.createdAt ? moment(payout.createdAt).tz(timezone).format('DD/MM/YYYY HH:mm') : '-'
                                        }</span>
                                    </div>
                                    <div className="text-sm font-medium flex items-center justify-between mb-2"><span
                                        className="font-semibold">PaymentAt:</span>
                                        <span className="font-medium text-slate-700 text-right">{
                                            payout?.paymentAt ? <span
                                                className="text-emerald-500">{moment(payout.paymentAt).tz(timezone).format('DD/MM/YYYY HH:mm')}</span> : '-'
                                        }</span>
                                    </div>
                                    <div className="text-sm font-medium flex items-center justify-between mb-2">
                                        <CallbackComponent
                                            callbackUrl={payout?.callbackUrl || null}
                                            position={Position.CENTER}
                                            shortFirst={17}
                                            shortLast={0}
                                            onClick={callbackHandler}
                                        />
                                        {/*<span*/}
                                        {/*    className="text-center leading-4 text-xs text-rose-500 w-full">{OperationErrorMsg[payout?.error || OperationError.NONE]}</span>*/}
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="mb-2">
                                <legend className="font-bold mb-3 pr-2">Address details:</legend>
                                <div className="columns-1">
                                    <div className="text-sm font-medium flex items-center justify-between mb-2"><span
                                        className="font-semibold">Address:</span>
                                        <AddressComponent
                                            onClick={(e) => linkHandler(e, getExplorerLinkByType(payout?.walletType, payout?.payoutAddress?.address || ''))}
                                            address={payout?.payoutAddress?.address || ''}
                                            showCopyMessageTimeout={500}
                                            position={Position.LEFT}
                                            shortFirst={payout?.payoutAddress?.address.length || 0}
                                            shortLast={payout?.payoutAddress?.address.length || 0}
                                        />
                                    </div>
                                    <div className="text-sm font-medium flex items-center justify-between mb-2"><span
                                        className="font-semibold">Description:</span>
                                        {payout?.payoutAddress?.description}
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="mb-2">
                                <legend className="font-extrabold mb-3 pr-2">Description:</legend>
                                <span
                                    className="text-center leading-4 text-xs w-full">{payout?.description || '-'}</span>
                            </fieldset>
                            <fieldset className="mb-2">
                                <legend className="font-extrabold mb-3 pr-2">Errors:</legend>
                                <span
                                    className="text-center leading-4 text-xs text-rose-500 w-full">{OperationErrorMsg[payout?.error || OperationError.NONE]}</span>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
};


export default DetailPayoutModal;
