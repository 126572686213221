import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {USER_SLICE_NAME} from "../common/constants/store.constant.ts";
import {ProfileStateType, UserStateType, WalletStateType} from "../common/types/store/user-state.type.ts";
import {Role} from "../common/enums/role.enum.ts";
import {RootState} from "./index.ts";
import {IUserReducers} from "../common/interfaces/store/user-reducers.interface.ts";
import {NotificationSettingType} from "../common/types/notification-setting/notification-setting.type.ts";


export const initialProfileState: ProfileStateType = {
    id: 0,
    role: Role.CLIENT,
    name: '',
    email: '',
    apiKey: '',
    isTfaEnabled: false,
    hasSecretKey: false
}

export const initialWalletState: WalletStateType = {
    isActive: true,
    name: '',
    accounts: []
}

export const initialNotificationSettingState: NotificationSettingType = {
    balance: false,
    email: ''
}

const initialState: UserStateType = {
    profile: initialProfileState,
    wallet: initialWalletState,
    notificationSetting: initialNotificationSettingState,
    masterAddresses: []
}

const slice: Slice<UserStateType, IUserReducers> = createSlice({
    name: USER_SLICE_NAME,
    initialState,
    reducers: {
        setProfile: (state: Draft<UserStateType>, { payload }): void => {
            state.profile = payload
        },
        setWallet: (state: Draft<UserStateType>, { payload }): void => {
            state.wallet = payload
        },
        setApiKey: (state: Draft<UserStateType>, { payload }): void => {
            state.profile.apiKey = payload;
        },
        setMasterAddresses: (state: Draft<UserStateType>, { payload }): void => {
            state.masterAddresses = payload;
        },
        setNotificationSetting: (state: Draft<UserStateType>, { payload }): void => {
            state.notificationSetting = payload;
        },
        setMasterAddressByType: (state: Draft<UserStateType>, { payload }): void => {
            const index: number = state.masterAddresses.findIndex((a) => a.type === payload.type);
            state.masterAddresses[index] = payload;
        },
        updateBalanceByType: (state: Draft<UserStateType>, { payload }): void => {
            const index: number = state.wallet.accounts.findIndex(
                (a) => a.type === payload.type
            );
            if (index !== -1) {
                const bIndex: number = state.wallet.accounts[index].balances.findIndex(
                    (b) => b.currency === payload.currency
                );

                if (bIndex !== -1) {
                    state.wallet.accounts[index].balances[bIndex].balance = payload.balance
                }

            }
        },
        setTfaEnabled: (state: Draft<UserStateType>, { payload }): void => {
            state.profile.isTfaEnabled = payload;
        },
    }
});

export const userSelector = (state: RootState) => state.user;
export const {
    setProfile,
    setWallet,
    setApiKey,
    setMasterAddresses,
    setMasterAddressByType,
    updateBalanceByType,
    setTfaEnabled,
    setNotificationSetting
} = slice.actions;
export default slice.reducer;
