import {FC, Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Menu, Transition} from "@headlessui/react";
import {Link} from "react-router-dom";
import {userSelector} from "../../../store/user.store.ts";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../store";
import {logout} from "../../../store/auth.store.ts";
import {HeaderMenuType} from "../../types/components/header-menu.type.ts";

const HeaderMenu: FC<HeaderMenuType> = ({align, profileLoading}) => {
    const dispatcher: AppDispatch = useDispatch();
    const { profile: {name, role} } = useSelector(userSelector);

    const logoutHandler = useCallback((): void => {
        dispatcher(logout())
    }, [dispatcher]);

    return (
        <Menu as="div">
            <div className="flex items-center justify-center">
                <Menu.Button disabled={profileLoading} className="inline-flex justify-center items-center group">
                    {/*<img className="w-8 h-8 rounded-full" src={UserAvatar} width="32" height="32" alt="User"/>*/}
                    <div className="flex items-center truncate">
                        <span className="truncate mr-2 text-sm font-medium group-hover:text-slate-800">
                            {(profileLoading)
                                ? <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                                    <path
                                        d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"/>
                                </svg>
                                : name
                            }
                        </span>
                        <svg className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400" viewBox="0 0 12 12">
                            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"/>
                        </svg>
                    </div>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Menu.Items
                    className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-1' : 'left-1'}`}>
                    <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
                        <div className="font-medium text-slate-800">{name}</div>
                        <div className="text-xs text-slate-500 italic">{role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}</div>
                    </div>
                    <ul>
                        <li>
                            <Link
                                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                                to="/profile/personal"
                            >
                                Personal Settings
                            </Link>
                        </li>
                        <li>
                            <p
                                className="cursor-pointer font-medium text-sm text-red-500 hover:text-red-600 flex items-center py-1 px-3"
                                onClick={logoutHandler}
                            >
                                Logout
                            </p>
                        </li>
                    </ul>
                </Menu.Items>
            </Transition>
        </Menu>
    )
};

HeaderMenu.propTypes = {
    align: PropTypes.string.isRequired,
    profileLoading: PropTypes.bool.isRequired
};

export default HeaderMenu;
