import {FC} from 'react';
import PropTypes from 'prop-types';

type SpinnerType = {
    size?: string;
    color?: string;
    className?: string;
}

const Spinner: FC<SpinnerType> = ({color, className,}) => {
    return (
        <div className={`sk-cube-grid ${className}`}>
            <div className={`sk-cube sk-cube1 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube2 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube3 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube4 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube5 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube6 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube7 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube8 bg-${color}-500`}></div>
            <div className={`sk-cube sk-cube9 bg-${color}-500`}></div>
        </div>
    );
};

Spinner.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
};

Spinner.defaultProps = {
    size: '50',
    color: 'indigo',
    className: ''
}

export default Spinner;
