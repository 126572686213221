import {GraphQLError} from "graphql/error";
import {ApolloError, ServerError, ServerParseError} from "@apollo/client";
import {ErrorResponse} from "@apollo/client/link/error";
import {ErrorEnum} from "../enums/error.enum";


const hasGqlErrors = (error: ErrorResponse): boolean => {
    return hasPopulatedGqlErrors(error) || hasUnpopulatedGqlErrors(error);
}

const hasPopulatedGqlErrors = (error: ErrorResponse): boolean => {
    return Boolean(
        error &&
        typeof error === "object" &&
        Array.isArray((error as Partial<ApolloError>).graphQLErrors) &&
        error.graphQLErrors?.length
    );
}

const hasUnpopulatedGqlErrors = (error: ErrorResponse): boolean => {
    const networkError: Record<string, any> = error?.networkError as Record<string, any>;
    return Boolean(
        hasNetworkError(error) &&
        "result" in networkError &&
        Array.isArray(networkError.result.errors) &&
        networkError.errors.length
    );
}

const hasNetworkError = (error: unknown): error is { networkError: Error | ServerError | ServerParseError } => {
    return Boolean(error && typeof error === "object" && (error as ApolloError).networkError);
}


export const isNetworkError = (error: ErrorResponse): boolean => {
    return hasNetworkError(error) && !hasGqlErrors(error);
}

export const isTFACodeRequired = (error: ErrorResponse): boolean => {
    return Boolean(
        hasPopulatedGqlErrors(error) &&
        error.graphQLErrors?.some((err: GraphQLError): boolean => {
            return err.extensions?.code === ErrorEnum.TFA_CODE_REQUIRED
        })
    );
}

export const isError = (error: ErrorResponse|ApolloError, needleError: ErrorEnum): boolean => {
    return Boolean(
        error.graphQLErrors?.some((err: GraphQLError): boolean => {
            return err.extensions?.code === needleError
        })
    );
}

export const isGQlError = (error: ErrorResponse): boolean => {
    return hasGqlErrors(error)
}
