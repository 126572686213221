export enum ErrorEnum {
    INTERNAL_SERVER = 'INTERNAL_SERVER',
    UNAUTHORIZED = 'UNAUTHORIZED',
    BAD_REQUEST = 'BAD_REQUEST',
    UNIQUE = 'UNIQUE',

    TFA_CODE_REQUIRED = 'TFA_CODE_REQUIRED',
    TFA_CODE_INVALID = 'TFA_CODE_INVALID',
    TFA_SECRET_NOT_FOUND = 'TFA_SECRET_NOT_FOUND',
    TFA_ALREADY_ENABLED = 'TFA_ALREADY_ENABLED',
    TFA_ALREADY_DISABLED = 'TFA_ALREADY_DISABLED',

    JWT_TOKEN_EXPIRED = 'JWT_TOKEN_EXPIRED',
    JWT_TOKEN_INVALID = 'JWT_TOKEN_INVALID',
    JWT_REFRESH_TOKEN_EXPIRED = 'JWT_TOKEN_EXPIRED',
    JWT_REFRESH_TOKEN_INVALID = 'JWT_REFRESH_TOKEN_INVALID',

    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_WRONG_PASSWORD = 'USER_WRONG_PASSWORD',
    USER_WITH_EMAIL_ALREADY_EXISTS = 'USER_WITH_EMAIL_ALREADY_EXISTS',
    USER_WALLET_NOT_FOUND = 'USER_WALLET_NOT_FOUND',

    PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
    PAYMENT_ACCEPT_ERROR = 'PAYMENT_ACCEPT_ERROR',
    PAYMENT_REJECT_ERROR = 'PAYMENT_REJECT_ERROR',

    PAYMENT_ADDRESS_NOT_FOUND = 'PAYMENT_ADDRESS_NOT_FOUND',

    PAYOUT_ADDRESS_NOT_FOUND = 'PAYOUT_ADDRESS_NOT_FOUND',
    PAYOUT_ALREADY_APPROVED = 'PAYOUT_ALREADY_APPROVED',
    PAYOUT_ALREADY_DISCARDED = 'PAYOUT_ALREADY_DISCARDED',

    MASTER_ADDRESS_NOT_FOUND = 'MASTER_ADDRESS_NOT_FOUND',

    WALLET_ADDRESS_INVALID = 'WALLET_ADDRESS_INVALID',
    WALLET_ADDRESS_NOT_FOUND = 'WALLET_ADDRESS_NOT_FOUND',

    WALLET_UNAVAILABLE_CURRENCY = 'WALLET_UNAVAILABLE_CURRENCY'
}
