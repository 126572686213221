import {FC, MouseEvent, useCallback, useMemo} from 'react';
import {
    formatCurrency,
    getExplorerLinkByType,
    getHumanDescCurrency,
    getNetworkIcon
} from "../../../utils/currency.util.ts";
import {openInNewTab, statusColor, toShort2} from "../../../utils/common.util.ts";
import {TableItemPayoutType} from "../../../types/components/table-item-payout.type.ts";
import {PayoutAddressStatus} from "../../../enums/payout-address-status.enum.ts";
import {AppDispatch} from "../../../../store";
import {useDispatch} from "react-redux";
import {OperationStatus} from "../../../enums/operation-status.enum.ts";
import {
    openAcceptPayoutModal, openCancelPayoutModal,
    openCompletePayoutModal, openDetailPayoutModal,
    openRejectPayoutModal,
    setPayout
} from "../../../../store/payout.store.ts";
import {OperationError} from "../../../enums/operation-error.enum.ts";
import useTimezone from "../../../hooks/timezone.hook.ts";
import moment from 'moment-timezone'
import {Position} from "../../../enums/position.enum.ts";
import AddressComponent from "../../general/address.component.tsx";


const TableItemPayoutPayment: FC<TableItemPayoutType> = ({item}) => {
    const dispatcher: AppDispatch = useDispatch();
    const timezone = useTimezone()

    const linkHandler = useCallback((e: MouseEvent<HTMLDivElement>, url?: string): void => {
        e.stopPropagation();
        if (url) {
            openInNewTab(url);
        }
    }, []);

    const acceptHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatcher(openAcceptPayoutModal(item.id));
        dispatcher(setPayout(item));
    }, [dispatcher, item]);

    const rejectHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatcher(openRejectPayoutModal(item.id));
        dispatcher(setPayout(item));
    }, [dispatcher, item]);

    const openPayoutDetails = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        dispatcher(openDetailPayoutModal(item.id));
        dispatcher(setPayout(item));
    }, [dispatcher, item]);

    const markAsCompletedHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatcher(openCompletePayoutModal(item.id));
        dispatcher(setPayout(item));
    }, [dispatcher, item]);

    const markAsCanceledHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatcher(openCancelPayoutModal(item.id));
        dispatcher(setPayout(item));
    }, [dispatcher, item]);




    const operationButtons = useMemo(() => {
        switch (item.status) {
            case OperationStatus.NEW:
                return <>
                    <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-green-500 text-white transition-all active:scale-95 hover:bg-green-600" onClick={acceptHandler}>Accept</button>
                    {(item?.payoutAddress?.status === PayoutAddressStatus.DISCARDED || item?.payoutAddress?.status === PayoutAddressStatus.AWAITED) &&
                        <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-rose-500 text-white transition-all active:scale-95 hover:bg-rose-600" onClick={rejectHandler}>Reject</button>
                    }
                </>
            case OperationStatus.ERROR:
                return (
                    item.error === OperationError.SYSTEM_ERROR &&
                    <>
                        <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-green-500 text-white transition-all active:scale-95 hover:bg-green-600" onClick={markAsCompletedHandler}>Complete</button>
                        <button className="btn btn-sm text-xs mb-2 sm:mb-0 sm:ml-3 bg-rose-500 text-white transition-all active:scale-95 hover:bg-rose-600" onClick={markAsCanceledHandler}>Cancel</button>
                    </>
                )
            case OperationStatus.SUCCESS:
                return ''
        }
    }, [item.status, item?.payoutAddress?.status, item.error, acceptHandler, rejectHandler, markAsCompletedHandler, markAsCanceledHandler]);


    return (
        <tr className="cursor-pointer hover:bg-gray-100 select-none" onClick={openPayoutDetails}>
            <td className="px-2 first:pl-5 last:pr-5 py-3 pl-3 whitespace-nowrap">
                <div className={`font-medium`}>{moment(item.createdAt).tz(timezone).format('DD/MM/YYYY HH:mm')}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className={`font-medium`}>
                    <img className="w-8 h-8 rounded-full" src={getNetworkIcon(item.walletType)} alt="BNB Icon" />
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="block w-fit">
                    {item?.payoutAddress?.address ? <AddressComponent
                        onClick={(e) => linkHandler(e, getExplorerLinkByType(item.walletType, item.payoutAddress.address))}
                        address={item.payoutAddress.address || ''}
                        showCopyMessageTimeout={500}
                        position={Position.RIGHT}
                    /> : "-"}
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className={`font-medium`}>{formatCurrency(item.amount) + ` ${getHumanDescCurrency(item.currency)}`}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap max-w-[100px]">
                <div className={`font-medium w-fit`}>{toShort2(item.description, 14, 0)}</div>
            </td>
            <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap`}>
                {item.status === OperationStatus.ERROR
                    ? <div className="relative w-full h-full">
                        <div className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 font-medium rounded-full px-2.5 py-0.5 ${statusColor(item.status)}`}>{item.status.toLowerCase()}</div>
                    </div>

                    : <div className="relative w-full h-full">
                        <div className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 font-medium rounded-full px-2.5 py-0.5 ${statusColor(item.status)}`}>{item.status.toLowerCase()}</div>
                    </div>
                }
            </td>
            <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap `}>
                <div className="relative flex justify-center items-center ">
                    {operationButtons}
                </div>
            </td>
        </tr>
    );
};


export default TableItemPayoutPayment;
