export enum OperationSystemStatus {
    WAIT_PAYMENT = 'WAIT_PAYMENT',
    IN_CHECK_PAYMENT = 'IN_CHECK_PAYMENT', // here user see success payment if it true, send callback
    WAIT_FEE = 'WAIT_FEE',
    IN_ACTIVATE_PAYMENT = 'IN_ACTIVATE_PAYMENT',
    WAIT_SEND = 'WAIT_SEND',
    IN_SEND_PAYMENT = 'IN_SEND_PAYMENT',
    // WAIT_WITHDRAWAL = 'WAIT_WITHDRAWAL',
    // IN_WITHDRAWAL = 'IN_WITHDRAWAL',
    COMPLETED = 'COMPLETED'
}
