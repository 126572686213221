import {
	allowIPaidPayment,
	allowRestartPayment,
	getFeeCurrency,
	getStateByStatus,
	openInNewTab,
	statusColor,
} from "../../utils/common.util.ts";
import { FC, useCallback, useEffect } from "react";
import { PaymentComponentType } from "../../types/payment/payment-component.type.ts";
import useTimezone from "../../hooks/timezone.hook.ts";
import moment from "moment-timezone";
import {
	formatCurrency,
	formatNumber,
	getNetworkIcon,
} from "../../utils/currency.util.ts";
import { MdPayment } from "react-icons/md";
import { IoReloadOutline } from "react-icons/io5";
import { BsPatchCheck } from "react-icons/bs";
import { useMutation } from "@apollo/client";
import { CheckPaymentResponse } from "../../types/payment/check-payment-response.type.ts";
import { CHECK_PAYMENT_MUTATION } from "../../../api/graphql/mutations/check-payment.mutation.ts";
import { OperationErrorMsg } from "../../enums/operation-error.enum.ts";
import { toast } from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import { NotificationType } from "../../enums/notification.enum.ts";
import { Position } from "../../enums/position.enum.ts";
import AddressComponent from "../general/address.component.tsx";
import CallbackComponent from "../general/callback.component.tsx";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { openCallbackModel } from "../../../store/callback.store.ts";

const Payment: FC<PaymentComponentType> = ({ payment }) => {
	const dispatcher: AppDispatch = useDispatch();
	const timezone: string = useTimezone();

	const [checkPayment, checkPaymentData] = useMutation<CheckPaymentResponse>(
		CHECK_PAYMENT_MUTATION,
	);

	useEffect(() => {
		if (checkPaymentData.error) {
			toast(
				<NotificationToast
					type={NotificationType.ERROR}
					title="Payment"
					message={checkPaymentData.error.message}
				/>,
				{
					toastId: "payment-check-bad-request",
				},
			);
		}
	}, [checkPaymentData.error]);

	const checkHandler = useCallback(async () => {
		if (payment?.id) {
			await checkPayment({
				variables: {
					operationId: payment.id,
				},
			});
		}
	}, [checkPayment, payment?.id]);

	const callbackHandler = useCallback(async () => {
		dispatcher(
			openCallbackModel({
				operationId: payment?.id,
				url: payment?.callbackUrl,
			}),
		);
	}, [dispatcher, payment?.id, payment?.callbackUrl]);

	return (
		<div className="max-w-sm mx-auto lg:max-w-none">
			<div className="text-slate-800 font-semibold text-center mb-1">
				Payment: <span className="text-indigo-500">{payment?.orderId}</span>
			</div>

			<div className="text-sm text-center italic">
				{moment(payment?.createdAt).tz(timezone).format("DD/MM/YYYY HH:mm")}
			</div>
			{/* Details */}
			<div className="drop-shadow-lg mt-12">
				{/* Top */}
				<div className="bg-white rounded-t-xl px-5 pb-2.5 text-center">
					<div className="mb-3 text-center">
						<img
							className="inline-flex w-12 h-12 rounded-full -mt-6"
							src={getNetworkIcon(payment?.walletType)}
							width="48"
							height="48"
							alt="payment icon"
						/>
					</div>
					<div className="text-2xl font-semibold text-emerald-500 mb-1">
						{formatCurrency(payment?.amount || "0")}
					</div>
					<div className="text-sm font-medium text-slate-800 mb-3">
						{payment?.description}
					</div>
					<div
						className={`text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 ${statusColor(payment?.status)}`}
					>
						{payment?.status.toLowerCase()}
					</div>

					<div className="text-xs text-center text-rose-500 mt-4">
						{OperationErrorMsg[payment?.error ?? "NONE"]}
					</div>
				</div>
				{/* Divider */}
				<div className="flex justify-between items-center" aria-hidden="true">
					<svg
						className="w-5 h-5 fill-white"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
					</svg>
					<div className="grow w-full h-5 bg-white flex flex-col justify-center">
						<div className="h-px w-full border-t border-dashed border-slate-200" />
					</div>
					<svg
						className="w-5 h-5 fill-white rotate-180"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
					</svg>
				</div>
				{/* Bottom */}
				<div className="bg-white rounded-b-xl p-5 pt-2.5 text-sm space-y-3">
					<div className="flex justify-between space-x-1">
						<span className="italic">State:</span>
						<span className="font-medium text-slate-700 text-right">
							{getStateByStatus(payment?.sysStatus)}
						</span>
					</div>
					<div className="flex justify-between space-x-1">
						<span className="italic">Address:</span>
						<div className="text-right">
							<AddressComponent
								address={payment?.address.address || ""}
								showCopyMessageTimeout={500}
								position={Position.LEFT}
							/>
						</div>
					</div>
					<div className="flex justify-between space-x-1">
						<span className="italic">Fee:</span>
						<span className="font-medium text-slate-700 text-right">
							{formatNumber(payment?.paymentFee || "0")}{" "}
							{getFeeCurrency(payment?.walletType).toLowerCase()}
						</span>
					</div>
					<div className="flex justify-between space-x-1">
						<span className="italic">Payment At:</span>
						<span className="font-medium text-slate-700 text-right">
							{payment?.paymentAt ? (
								<span className="text-emerald-500">
									{moment(payment?.paymentAt)
										.tz(timezone)
										.format("DD/MM/YYYY HH:mm")}
								</span>
							) : (
								"-"
							)}
						</span>
					</div>
					<div className="flex justify-between space-x-1">
						<span className="italic">Expired At:</span>
						<span className="font-medium text-slate-700 text-right">
							{payment?.expiredAt ? (
								<span className="text-rose-500">
									{moment(payment?.expiredAt)
										.tz(timezone)
										.format("DD/MM/YYYY HH:mm")}
								</span>
							) : (
								"-"
							)}
						</span>
					</div>
					<div className="flex items-center justify-between space-x-1">
						<span className="italic">Callback URL:</span>
						<CallbackComponent
							callbackUrl={payment?.callbackUrl || null}
							position={Position.CENTER}
							shortFirst={17}
							shortLast={0}
							onClick={callbackHandler}
						/>
					</div>

					<div className="flex items-center justify-between space-x-1">
						<span className="italic">Comment:</span>
						<span className="text-xs">{payment?.comment || "-"}</span>
					</div>
				</div>
			</div>

			<div className="flex items-center justify-center space-x-3 mt-6">
				<button
					aria-label="I paid"
					onClick={checkHandler}
					disabled={
						checkPaymentData.loading || !allowIPaidPayment(payment?.sysStatus)
					}
					className={`btn ${checkPaymentData.loading || !allowIPaidPayment(payment?.sysStatus) ? "opacity-40 cursor-not-allowed" : "hover:border-slate-300 active:scale-95"} bg-indigo-500 text-white ml-3 transition-all`}
				>
					<BsPatchCheck size={18} />
					<span className="ml-1">Paid</span>
				</button>
			</div>
			<div className="flex items-center justify-center space-x-3 mt-6">
				<div className="w-1/2">
					<button
						onClick={() =>
							openInNewTab(
								`${import.meta.env.VITE_APP_URL}/i/${payment?.internalId}`,
							)
						}
						className="btn bg-indigo-500 text-white ml-3 transition-all active:scale-95 hover:bg-indigo-600"
					>
						<MdPayment size={18} />
						<span className="ml-1">Payment page</span>
					</button>
				</div>
				<div className="w-1/2">
					<button
						aria-label="Restart Payment"
						onClick={checkHandler}
						disabled={
							checkPaymentData.loading ||
							!allowRestartPayment(payment?.sysStatus)
						}
						className={`btn ${checkPaymentData.loading || !allowRestartPayment(payment?.sysStatus) ? "opacity-40 cursor-not-allowed" : "hover:border-slate-300 active:scale-95"} w-full border-slate-200 text-rose-500`}
					>
						<IoReloadOutline size={18} />
						<span className="ml-2">Restart</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Payment;
