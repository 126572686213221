import {DocumentNode, gql} from "@apollo/client";

export const WALLET_ACCOUNT_FRAGMENT: DocumentNode = gql(`
    fragment AccountInfo on TransferAddress {
      type
      name
      address
      balances {
        currency
        currencyType
        balance
      }
    }
`);
