import {DocumentNode, gql} from "@apollo/client";

export const PAYMENT_INFO_FRAGMENT: DocumentNode = gql(`
    fragment PaymentInfo on CreatePaymentResponse {
        orderId
        status
        userStatus
        address
        amount
        amountRemaining
        received
        fee
        description
        error
        expTime
        link
        paymentAt
        expiredAt
        createdAt
    }
`);
