import {DocumentNode, gql} from "@apollo/client";

export const WALLET_QUERY: DocumentNode = gql(`    
    query GetWallet {
      getWallet {
        accounts {
          ...AccountInfo
        }
      }
    }
`);
