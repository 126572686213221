import {DocumentNode, gql} from "@apollo/client";

export const AUTH_INFO_FRAGMENT: DocumentNode = gql(`
    fragment AuthInfo on AuthResponse {
      accessToken
      refreshToken
      accessTokenTTL
      refreshTokenTTL
    }
`);
