import {DocumentNode, gql} from "@apollo/client";

export const MASTER_ADDRESS_INFO_FRAGMENT: DocumentNode = gql(`
    fragment MasterAddressInfo on MasterAddress {
      isActive
      name
      type
      currency
      address
    }
`);
