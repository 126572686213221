import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Transition} from "@headlessui/react";
import {MasterAddressModalType} from "../../types/account/master-address-modal.type.ts";
import {useMutation} from "@apollo/client";
import {UpdateMasterAddressResponseType} from "../../types/account/update-master-address.type.ts";
import {UPDATE_MASTER_ADDRESS_MUTATION} from "../../../api/graphql/mutations/update-master-address.mutation.ts";
import {toast} from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import {NotificationType} from "../../enums/notification.enum.ts";
import {setMasterAddressByType, userSelector} from "../../../store/user.store.ts";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {isError} from "../../helpers/apollo.helper.ts";
import {ErrorEnum} from "../../enums/error.enum.ts";


const MasterAddressModal: FC<MasterAddressModalType> = ({show, setShow, type, }) => {
    const dispatcher: AppDispatch = useDispatch();

    const [address, setAddress] = useState<string|undefined>(undefined);
    const [tfaCode, setTfaCode] = useState<string>();
    const [showTfaField, setShowTfaField] = useState<boolean>(false);

    const {profile: {isTfaEnabled}} = useSelector(userSelector);

    const [updateMasterAddress, updateMasterAddressData] = useMutation<UpdateMasterAddressResponseType>(UPDATE_MASTER_ADDRESS_MUTATION);

    useEffect(() => {
        setShowTfaField(isTfaEnabled);
    }, [isTfaEnabled]);

    useEffect(() => {
        if (!show) {
            setAddress('');
            setTfaCode('');
        }
    }, [show]);

    const sendHandler = useCallback(() => {
        updateMasterAddress({
            variables: {dto: {type, address}, tfaCode}
        }).then()

    }, [address, tfaCode, type, updateMasterAddress]);

    useEffect(() => {
        if (updateMasterAddressData.data) {
            dispatcher(setMasterAddressByType(updateMasterAddressData.data.updateMasterAddress));
            setShow(false)
        }
    }, [dispatcher, setShow, updateMasterAddressData.data]);

    useEffect(() => {
        if (updateMasterAddressData.error) {
            if (isError(updateMasterAddressData.error, ErrorEnum.TFA_CODE_REQUIRED)) {
                setShowTfaField(true);
            }
            toast(<NotificationToast type={NotificationType.WARNING} title="Master Address" message={updateMasterAddressData.error.message} />, {
                toastId: 'update-master-address-error'
            });
        }
    }, [updateMasterAddressData.error]);


    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full"
                    >
                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Add the Master Address</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <div className="p-4 pb-0">

                            <label className="block text-sm font-medium mb-1" htmlFor="email">Master Address:</label>
                            <div className="relative">
                                <input
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder={`Address`}
                                    className='form-input w-full max-w-[646px] mr-1'
                                    type="text"
                                />
                            </div>

                            <Transition
                                as={Fragment}
                                show={showTfaField}
                                enter="transition ease-in-out duration-600"
                                enterFrom="opacity-0 translate-y-4"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in-out duration-300"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-4"
                            >
                                <div className="mt-2">
                                    <label className="block text-sm font-medium mb-1" htmlFor="tfa_code">TFA Code:</label>
                                    <div className="relative">
                                        <input
                                            name="tfa_code"
                                            value={tfaCode}
                                            onChange={(e) => setTfaCode(e.target.value)}
                                            placeholder="TFA Code"
                                            className='form-input w-full max-w-[646px] mr-1'
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </Transition>

                        </div>
                        {/* Modal footer */}

                        <div className="flex justify-end p-4">
                            <button
                                onClick={() => setShow(false)}
                                className='bg-white border text-gray-700 hover:bg-gray-100 hover btn-sm whitespace-nowrap mr-2'
                            >
                                Cancel
                            </button>
                            <button
                                onClick={sendHandler}
                                disabled={updateMasterAddressData.loading || !address}
                                className={`${updateMasterAddressData.loading || !address && 'opacity-40'} bg-indigo-500 text-white hover:bg-indigo-600 btn-sm whitespace-nowrap`}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
};

MasterAddressModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default MasterAddressModal;
