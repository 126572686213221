import {FC, Fragment, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Transition} from "@headlessui/react";
import {WalletModalType} from "../../types/balance/wallet-modal.type.ts";
import {QueryResult, useQuery} from "@apollo/client";
import {GetWalletResponseType} from "../../types/user/get-wallet-response.type.ts";
import {WALLET_QUERY} from "../../../api/graphql/queries/wallet.query.ts";
import {WalletAccountType, WalletStateType} from "../../types/store/user-state.type.ts";
import {setWallet, userSelector} from "../../../store/user.store.ts";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {
    getCoinNameWIthTether,
    getDescriptionByType,
    getIconByCurrency
} from "../../utils/currency.util.ts";
import {balanceSelector, setAccount} from "../../../store/balance.store.ts";
import {toast} from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import {NotificationType} from "../../enums/notification.enum.ts";
import {Currency} from "../../enums/currency.enum.ts";
import {CurrencyType} from "../../enums/currency-type.enum.ts";


const WalletModal: FC<WalletModalType> = ({show, setShow, setShowDepositModal, currencyTypes}) => {
    const dispatcher: AppDispatch = useDispatch();
    const getWallet: QueryResult<GetWalletResponseType> = useQuery<GetWalletResponseType>(WALLET_QUERY);
    const { wallet: {accounts} } = useSelector(userSelector);
    const { walletAccount } = useSelector(balanceSelector);

    useEffect(() => {
        if (show) {
            getWallet.refetch()
                .then()
                .catch((e) => {
                    setShow(false);
                    toast(<NotificationToast  type={NotificationType.ERROR} title="Wallet" message={e.message} />, {
                        toastId: 'get-wallet-request'
                    });
                })
        }
    }, [getWallet, setShow, show]);

    useEffect(() => {
        if (getWallet.data) {
            const response: WalletStateType = getWallet.data.getWallet
            dispatcher(setWallet(response));
        }
    }, [getWallet, dispatcher]);

    const setCurrency = useCallback((account: WalletAccountType, currency: Currency): void => {
        const data = {...account, currency}
        dispatcher(setAccount(data))
    }, [dispatcher]);

    const nextHandler = useCallback(() => {
        if (walletAccount !== null) {
            setShow(false);
            setShowDepositModal(true);
        }
    }, [setShow, setShowDepositModal, walletAccount]);

    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div onClick={(e) => e.stopPropagation()} className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full">
                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Top up Balance</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <div className="p-4 pb-0">
                            <div className="text-sm mb-6">Select currency:</div>
                            { getWallet.loading
                                ? <div className='flex items-center justify-center'>
                                    <svg className="text-center animate-spin w-4 h-4 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                    </svg>
                                </div>
                                : <div className='box-border'>
                                    {accounts.map((account, index) => (
                                        account.balances
                                            .slice()
                                            .sort((a, b) => a.currency.localeCompare(b.currency))
                                            .filter(b => currencyTypes.includes(b.currencyType))
                                            .map((b, i) => (
                                            <button key={index+'-'+i} onClick={() => setCurrency(account, b.currency)} className={`w-full flex items-center p-3 mb-2 rounded cursor-pointer border-2 ${walletAccount?.currency === b.currency && 'border-indigo-500'} transition-all shadow overflow-hidden hover:border-indigo-500`}>
                                                <div className='mr-4'>
                                                    <img className="w-12 h-12 rounded-full" src={getIconByCurrency(b.currency)} alt={account.type} />
                                                </div>
                                                <div className="flex-col text-left overflow-hidden">
                                                    <p className='text-gray-700 font-medium m-0 overflow-hidden text-ellipsis whitespace-nowrap'>{getCoinNameWIthTether(account.type)}</p>
                                                    {/*<p className='text-sm text-gray-500 m-0 overflow-hidden text-ellipsis whitespace-nowrap'>{account.name} {b.currency}</p>*/}
                                                    <p className='text-sm text-gray-500 m-0 overflow-hidden text-ellipsis whitespace-nowrap'>{getDescriptionByType(account.type)}</p>
                                                </div>
                                            </button>
                                        ))
                                    ))}
                                </div>
                            }

                        </div>
                        {/* Modal footer */}

                        <div className="flex justify-end p-4">
                            <button onClick={() => setShow(false)} className='bg-white border text-gray-700 hover:bg-gray-100 hover btn-sm whitespace-nowrap mr-2'>
                                Cancel
                            </button>
                            <button
                                onClick={nextHandler}
                                disabled={walletAccount === null}
                                className={`${walletAccount === null ? 'opacity-40' : 'opacity-1'} bg-indigo-500 text-white hover:bg-indigo-600 btn-sm whitespace-nowrap`}
                            >
                                Select
                            </button>
                        </div>

                    </div>
                </div>
            </Transition>
        </>
    );
};

WalletModal.defaultProps = {
    currencyTypes: [...Object.values(CurrencyType)]
}

WalletModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
};

export default WalletModal;
