import {FC, MouseEvent, useCallback} from 'react';
import moment from 'moment-timezone'
import {getHumanAmount, getNetworkIcon} from "../../../utils/currency.util.ts";
import {openInNewTab, statusColor, toShort2} from "../../../utils/common.util.ts";
import useTimezone from "../../../hooks/timezone.hook.ts";
import {TableItemBalanceType} from "../../../types/components/table-item-balance.type.ts";


const TableItemBalance: FC<TableItemBalanceType> = ({item}) => {
    // const dispatcher: AppDispatch = useDispatch();
    const timezone = useTimezone()

    const linkHandler = useCallback((e: MouseEvent<HTMLDivElement>, url?: string): void => {
        e.stopPropagation();
        if (url) {
            openInNewTab(url);
        }
    }, []);

    return (
        <tr className="cursor-pointer hover:bg-gray-100 select-none">
            <td className="px-2 first:pl-5 last:pr-5 py-3 pl-3 whitespace-nowrap">
                <div className={`font-medium`}>{moment(item.paymentAt).tz(timezone).format('DD/MM/YYYY HH:mm')}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className={`font-medium`}>
                    <img className="w-8 h-8 rounded-full" src={getNetworkIcon(item.walletType)} alt={item.walletType}/>
                </div>
            </td>

            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className={`font-medium`}>
                    {getHumanAmount(item.amount, item.currency)}
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap max-w-[100px]">
                <div className={`font-medium w-fit`}>{
                    toShort2(item.description, 14, 0)
                }</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                    onClick={(e) => linkHandler(e, item?.transactionBlocknumberLink || '')}
                    className={`w-fit block font-medium text-indigo-500 hover:cursor-pointer hover:underline`}>
                    {item.incomeBlocknumber}
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                    onClick={(e) => linkHandler(e, item?.transactionHashLink || '')}
                    className={`w-fit block font-medium text-indigo-500 hover:cursor-pointer hover:underline`}>{toShort2(item.incomeHash, 6, 6)}</div>
            </td>
            <td className={`px-2 flex justify-center items-center first:pl-5 last:pr-5 py-3 whitespace-nowrap `}>
                <div
                    className={`inline-flex font-medium rounded-full px-2.5 py-0.5 ${statusColor(item.status)}`}>{item.status.toLowerCase()}</div>
            </td>
        </tr>
    );
};


export default TableItemBalance;
