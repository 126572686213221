import {FC, Fragment, ReactNode, useMemo, useState} from "react";
import {Position} from "../../enums/position.enum.ts";
import PropTypes from "prop-types";
import {Transition} from "@headlessui/react";


export enum Theme {
    DARK,
    LIGHT
}

export enum Size {
    LG,
    MD,
    SM,
    XS
}

export type TooltipType = {
    children: ReactNode,
    title: string;
    message: string;
    className?: string;
    bg?: Theme;
    size?: Size;
    position?: Position;
}

const Tooltip: FC<TooltipType> = ({
     children,
     title,
     message,
     className,
     bg,
     size,
     position,
 }) => {

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

    const positionOuterClasses = useMemo(() => {
        switch (position) {
            case Position.RIGHT:
                return 'left-full top-1/2 transform -translate-y-1/2';
            case Position.LEFT:
                return 'right-full top-1/2 transform -translate-y-1/2';
            case Position.BOTTOM:
                return 'top-full left-1/2 transform -translate-x-1/2';
            default:
                return 'bottom-full left-1/2 transform -translate-x-1/2';
        }
    }, [position]);

    const sizeClasses = useMemo(() => {
        switch (size) {
            case Size.LG:
                return 'max-w-72  p-3';
            case Size.MD:
                return 'max-w-56 p-3';
            case Size.SM:
                return 'max-w-44 p-2';
            case Size.XS:
                return 'max-w-34 p-2';
            default:
                return 'p-2';
        }
    }, [size]);

    const positionInnerClasses = useMemo(() => {
        switch (position) {
            case Position.RIGHT:
                return 'ml-2';
            case Position.LEFT:
                return 'mr-2';
            case Position.BOTTOM:
                return 'mt-2';
            default:
                return 'mb-0';
        }
    }, [position]);

    return (
        <div
            className={`relative ${className}`}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            onFocus={() => setTooltipOpen(true)}
            onBlur={() => setTooltipOpen(false)}
        >
            {children}
            <div className={`z-40 absolute ${positionOuterClasses}`}>
                <Transition
                    as={Fragment}
                    show={tooltipOpen}
                    enter="transition ease-in-out duration-200"
                    enterFrom="opacity-0 translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-200"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-4"
                >
                    <div className={`rounded overflow-hidden ${bg === Theme.DARK ? 'bg-slate-800' : 'bg-white border border-slate-200 shadow-lg'} ${sizeClasses} ${positionInnerClasses}`}>
                        <div className="text-xs">
                            <div className="font-medium text-slate-200 mb-0.5 w-max">{title}</div>
                            <div className="text-slate-400">{message}</div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    className: PropTypes.string,
    bg: PropTypes.oneOf<Theme>(Object.values(Theme) as Theme[]),
    size: PropTypes.oneOf<Size>(Object.values(Size) as Size[]),
    position: PropTypes.oneOf<Position>(Object.values(Position) as Position[])
}

Tooltip.defaultProps = {
    className: '',
    bg: Theme.DARK,
    size: Size.SM,
    position: Position.RIGHT
}

export default Tooltip;
