import {DocumentNode, gql} from "@apollo/client";

export const APPROVE_PAYOUT_ADDRESS_MUTATION: DocumentNode = gql(`
    mutation ApprovePayoutAddress($dto: ApprovePayoutAddressRequest!, $tfaCode: String) {
        approvePayoutAddress(approvePayoutAddressDTO: $dto, tfaCode: $tfaCode) {
            address
            status
        }
    }
`);
