import {FC} from "react";
import {RiErrorWarningFill} from 'react-icons/ri'
import {PaymentPublicBlockType} from "../../../types/payment/payment-public-block.type.ts";



const PaymentExpired: FC<PaymentPublicBlockType> = () => {
    return (
        <div className="bg-white px-8 pb-5 rounded-[10px] shadow-lg border">

            <div className="text-center mb-4 pt-7"></div>
            <div className="flex flex-col items-center justify-center mb-16">
                <div className="rounded-full bg-rose-100 p-5 mb-5">
                    <RiErrorWarningFill className='text-rose-500' size={130} />
                </div>
            </div>

            <div className='text-rose-500 text-center text-[14px]'>
                Time is up! This invoice is no more valid. DO NOT PAY to this address, you will lost your money.
            </div>
        </div>
    );
};


export default PaymentExpired;
