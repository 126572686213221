import {FC, useCallback, useEffect, useState} from "react";
import SwitchComponent from "../../../common/components/form/switch.component.tsx";
import {MdClose} from "react-icons/md";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {NotificationSettingType} from "../../../common/types/notification-setting/notification-setting.type.ts";
import {QueryResult, useMutation, useQuery} from "@apollo/client";
import {
    GetNotificationSettingResponseType
} from "../../../common/types/notification-setting/get-notification-setting-response.type.ts";
import {NOTIFICATION_SETTING_QUERY} from "../../../api/graphql/queries/notification-setting.query.ts";
import {
    UpdateNotificationSettingResponseType
} from "../../../common/types/notification-setting/update-notification-setting-response.type.ts";
import {
    UPDATE_NOTIFICATION_SETTING_MUTATION
} from "../../../api/graphql/mutations/update-notification-setting.mutation.ts";


const validationSchema: Yup.ObjectSchema<NotificationSettingType> = Yup.object({
    balance: Yup.boolean().default(false),
    email: Yup.string().email("Invalid email address").optional()
});

const NotificationPage: FC = () => {
    const [initialFormData, setInitialFormData] = useState<NotificationSettingType | null>(null);

    const {data}: QueryResult<GetNotificationSettingResponseType> = useQuery<GetNotificationSettingResponseType>(NOTIFICATION_SETTING_QUERY);
    const [updateNotification, updateNotificationData] = useMutation<UpdateNotificationSettingResponseType>(UPDATE_NOTIFICATION_SETTING_MUTATION);

    useEffect(() => {
        if (data) {
            const response: NotificationSettingType = data.getNotificationSetting;
            setInitialFormData(response);
        }
    }, [data]);

    useEffect(() => {
        if (updateNotificationData.data) {
            const response: NotificationSettingType = updateNotificationData.data.updateNotificationSettings;
            setInitialFormData(response);
        }
    }, [updateNotificationData.data]);

    const handleSubmit = useCallback(async ({balance, email}: NotificationSettingType) => {
        await updateNotification({
            variables: {
                dto: {
                    balance,
                    email
                }
            }
        })
    }, [updateNotification]);

    return (
        <div className="grow">
            <Formik
                enableReinitialize={true}
                initialValues={initialFormData || {
                    balance: false,
                    email: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, setFieldValue, errors, values, touched, isValid}) => (
                    <Form>
                        <div className="p-6 space-y-6 min-h-[500px] w-full">
                            <h2 className="text-2xl text-slate-800 font-bold mb-5">Notifications</h2>
                            <div className="grid grid-cols-7 gap-6">
                                <section className="col-span-7 lg:col-span-3">
                                    <div className="flex items-center justify-between mb-8">
                                        <div>Balance</div>
                                        <SwitchComponent value={values.balance}
                                                         onChange={e => setFieldValue('balance', e)}/>
                                    </div>

                                    <div className="flex items-center justify-between mb-4">
                                        <div className="w-full relative">
                                            <label className="block text-sm font-medium mb-1" htmlFor="email">Notification
                                                email</label>
                                            <div className="relative">
                                                <Field
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email for notification"
                                                    className={`form-input w-full ${errors.email && touched.email ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                                />

                                                <button onClick={() => setFieldValue('email', '')}
                                                        className="absolute inset-0 left-auto group" type="button">
                                                    <MdClose
                                                        className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2"/>
                                                </button>
                                            </div>
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="text-rose-500 text-xs absolute bottom-auto left-0"
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <footer>
                            <div className="flex flex-col px-6 py-5 border-t border-slate-200">
                                <div className="flex self-end">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting || !isValid}
                                        aria-label="Sign In"
                                        className={`btn ${(isSubmitting || !isValid) ? 'disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed shadow-none' : ''} bg-indigo-500 hover:bg-indigo-600 text-white ml-3`}
                                    >
                                        {isSubmitting
                                            ? <>
                                                <svg className="animate-spin w-4 h-4 fill-current shrink-0"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"/>
                                                </svg>
                                                <span className="ml-2">Loading</span>
                                            </>
                                            : 'Save'
                                        }

                                    </button>
                                </div>
                            </div>
                        </footer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default NotificationPage;


