import {FC, ReactNode, useEffect, useMemo, useRef} from 'react';
import PropTypes from "prop-types";
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split
} from "@apollo/client";
import {createPersistedQueryLink} from "@apollo/client/link/persisted-queries";
import {sha256} from "crypto-hash";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import useCookie from 'react-use-cookie';
import {setContext} from "@apollo/client/link/context";
import {getMainDefinition} from "@apollo/client/utilities";
import {createFragmentRegistry} from "@apollo/client/cache";
import {AUTH_INFO_FRAGMENT} from "../../api/graphql/fragments/auth-info.fragment.ts";
import {USER_INFO_FRAGMENT} from "../../api/graphql/fragments/user-info.fragment.ts";
import {WALLET_ACCOUNT_FRAGMENT} from "../../api/graphql/fragments/wallet-account.fragment.ts";
import {PAYMENT_TABLE_INFO_FRAGMENT} from "../../api/graphql/fragments/payment-table-info.fragment.ts";
import {PAYMENT_PANEL_INFO_FRAGMENT} from "../../api/graphql/fragments/payment-panel-info.fragment.ts";
import {CURRENCY_INFO_FRAGMENT} from "../../api/graphql/fragments/currency-info.fragment.ts";
import {PAYMENT_INFO_FRAGMENT} from "../../api/graphql/fragments/payment-info.fragment.ts";
import {PAYMENT_PUBLIC_INFO_FRAGMENT} from "../../api/graphql/fragments/payment-public-info.fragment.ts";
import {MASTER_ADDRESS_INFO_FRAGMENT} from "../../api/graphql/fragments/master-address.fragment.ts";
import {BALANCE_TABLE_INFO_FRAGMENT} from "../../api/graphql/fragments/balance-table-info.fragment.ts";
import {TEMP_ADDRESS_INFO_FRAGMENT} from "../../api/graphql/fragments/temp-address-info.fragment.ts";
import {PAYOUT_ADDRESS_FRAGMENT} from "../../api/graphql/fragments/payout-address.fragment.ts";
import {PAYOUT_TABLE_INFO_FRAGMENT} from "../../api/graphql/fragments/payout-table-info.fragment.ts";
import {isError, isGQlError} from "../helpers/apollo.helper.ts";
import {ErrorResponse, onError} from "@apollo/client/link/error";
import {ErrorEnum} from "../enums/error.enum.ts";
import {AppDispatch} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, logout} from "../../store/auth.store.ts";
import {NOTIFICATION_SETTING_INFO_FRAGMENT} from "../../api/graphql/fragments/notification-setting.fragment.ts";
import {PAYMENT_SIMPLE_ANALYTIC_FRAGMENT} from "../../api/graphql/fragments/payment-simple-analytic.fragment.ts";
import {NODE_INFO_FRAGMENT} from "../../api/graphql/fragments/node-info.fragment.ts";


const API_URL = import.meta.env.VITE_API_URL;
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;
const WS_URL = import.meta.env.VITE_WS_URL;
const WS_ENDPOINT = import.meta.env.VITE_WS_ENDPOINT;

type ApolloCustomProviderType = {
    children: ReactNode | ReactNode[] | null;
}

const ApolloCustomProvider: FC<ApolloCustomProviderType> = ({children}) => {
    const dispatcher: AppDispatch = useDispatch();
    const [_accessToken, setAccessToken] = useCookie('access_token');
    const [_refreshToken, setRefreshToken] = useCookie('refresh_token');

    const {accessToken, refreshToken} = useSelector(authSelector)

    const apolloClientRef = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

    useEffect(() => {
        if (accessToken) {
            setAccessToken(accessToken)
        }
        if (refreshToken) {
            setRefreshToken(refreshToken)
        }
    }, [accessToken, refreshToken, setAccessToken, setRefreshToken]);

    // const refreshTokens = useCallback(async () => {
    //     if (apolloClientRef.current) {
    //         const { data } = await apolloClientRef.current.mutate({
    //             mutation: REFRESH_TOKENS_MUTATION,
    //             variables: {
    //                 refreshTokensDto: {refreshToken: _refreshToken}
    //             },
    //         });
    //
    //         const result = data.refreshTokens;
    //
    //         setAccessToken(result.accessToken);
    //         setRefreshToken(result.refreshToken);
    //
    //         return result
    //     }
    // }, [_refreshToken, setAccessToken, setRefreshToken]);

    // для отправки сигнатуры вместо полного текста запроса
    const persistedQueriesLink: ApolloLink = useMemo(() => {
        return createPersistedQueryLink({sha256})
    }, []);

    const httpLink: ApolloLink = useMemo(() => {
        return createHttpLink({
            uri: `${API_URL}/${API_ENDPOINT}`
        })
    }, []);

    const wsLink: GraphQLWsLink = useMemo(() => {
        return new GraphQLWsLink(
            createClient({
                url: `${WS_URL}/${WS_ENDPOINT}`,
                connectionParams: {
                    authToken: _accessToken,
                }
            })
        );
    }, [_accessToken]);

    const authLink: ApolloLink = useMemo(() => {
        return setContext((req, {headers}) => {
            const result = {
                headers: {
                    ...headers
                }
            }
            const token: string | null = _accessToken;
            const freeRoutes: string[] = ['SignIn', 'SignUp', 'RefreshTokens'];
            const operation: string | undefined = req.operationName;

            if (operation !== undefined && !freeRoutes.includes(operation)) {
                result.headers['authorization'] = token ? `Bearer ${token}` : null;
            }

            return result;
        });
    }, [_accessToken]);

    const errorLink: ApolloLink = useMemo(() => {
        return onError((error: ErrorResponse)  => {
            if (isGQlError(error)) {
                if (isError(error, ErrorEnum.JWT_TOKEN_EXPIRED)) {
                    // TODO: обновить токены
                    dispatcher(logout());

                    // if (!_refreshToken) {
                    //     dispatcher(logout());
                    // } else {
                    //     const tokensPromise = refreshTokens()
                    //     return fromPromise(tokensPromise).flatMap(({ accessToken }) => {
                    //         if (!accessToken) {
                    //             dispatcher(logout());
                    //         }
                    //
                    //         // Повторение запроса с новым токеном
                    //         const oldHeaders = error.operation.getContext().headers;
                    //
                    //         error.operation.setContext({
                    //             headers: {
                    //                 ...oldHeaders,
                    //                 authorization: accessToken ? `Bearer ${accessToken}` : null,
                    //             },
                    //         });
                    //
                    //         return error.forward(error.operation);
                    //     });
                    // }
                }

                if (
                    isError(error, ErrorEnum.JWT_TOKEN_INVALID) ||
                    isError(error, ErrorEnum.JWT_REFRESH_TOKEN_EXPIRED) ||
                    isError(error, ErrorEnum.JWT_REFRESH_TOKEN_INVALID)
                ) {
                    dispatcher(logout());
                }
            }

        })
    }, [dispatcher]);

    const splitLink: ApolloLink = useMemo(() => {
        return split(
            ({query}) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        )
    }, [httpLink, wsLink]);

    const link: ApolloLink = useMemo(() => {
        return ApolloLink.from([
            errorLink,
            authLink,
            splitLink
        ]);
    }, [authLink, errorLink, splitLink]);

    // TODO: убрать хеширование для запросов??
    const client: ApolloClient<NormalizedCacheObject> = useMemo(() => {
        const c: ApolloClient<NormalizedCacheObject> =  new ApolloClient({
            link: persistedQueriesLink.concat(link),
            cache: new InMemoryCache({
                fragments: createFragmentRegistry(
                    AUTH_INFO_FRAGMENT,
                    USER_INFO_FRAGMENT,
                    WALLET_ACCOUNT_FRAGMENT,
                    PAYMENT_TABLE_INFO_FRAGMENT,
                    PAYMENT_PANEL_INFO_FRAGMENT,
                    CURRENCY_INFO_FRAGMENT,
                    PAYMENT_INFO_FRAGMENT,
                    PAYMENT_PUBLIC_INFO_FRAGMENT,
                    MASTER_ADDRESS_INFO_FRAGMENT,
                    BALANCE_TABLE_INFO_FRAGMENT,
                    TEMP_ADDRESS_INFO_FRAGMENT,
                    PAYOUT_ADDRESS_FRAGMENT,
                    PAYOUT_TABLE_INFO_FRAGMENT,
                    NOTIFICATION_SETTING_INFO_FRAGMENT,
                    PAYMENT_SIMPLE_ANALYTIC_FRAGMENT,
                    NODE_INFO_FRAGMENT
                )
            }),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                }
            },
        });

        apolloClientRef.current = c;

        return c;
    }, [link, persistedQueriesLink]);

    return (
        <>
            <ApolloProvider client={client}>
                {children}
            </ApolloProvider>
        </>
    );
};

ApolloCustomProvider.propTypes = {
    children: PropTypes.node
};

export default ApolloCustomProvider;
