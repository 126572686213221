import {FC, useCallback, useEffect} from "react";
import {NetworkStatus, QueryResult, useQuery} from "@apollo/client";
import {TEMP_ADDRESSES_QUERY} from "../../api/graphql/queries/temp-addresses.query.ts";
import {GetTempAddressesResponse} from "../../common/types/temp-address/get-temp-addresses-response.ts";
import {AppDispatch} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {setPagination, setTempAddresses, tempAddressSelector} from "../../store/temp-address.store.ts";
import {TempAddressType} from "../../common/types/store/temp-address-state.type.ts";
import {PaginationType} from "../../common/types/store/pagination.type.ts";
import NotificationToast from "../../common/components/toasts/notification.toast.tsx";
import {toast} from "react-toastify";
import {NotificationType} from "../../common/enums/notification.enum.ts";
import TablePagination from "../../common/components/tables/table-pagination.component.tsx";
import {Position} from "../../common/enums/position.enum.ts";
import TableTempAddress from "../../common/components/tables/temp-address/table-tem-address.component.tsx";


const TempAddressesPage: FC = () => {
    const dispatcher: AppDispatch = useDispatch();
    const {addresses, headers, pagination: {page, perPage, total}} = useSelector(tempAddressSelector);

    const {data, error, networkStatus, loading}: QueryResult<GetTempAddressesResponse> = useQuery<GetTempAddressesResponse>(TEMP_ADDRESSES_QUERY, {
        variables: {
            dto: {page, perPage}
        }
    });

    useEffect((): void => {
        if (data) {
            const response: TempAddressType[] = data.getTempAddresses.data;
            const meta: PaginationType = data.getTempAddresses.meta;
            dispatcher(setTempAddresses(response));
            dispatcher(setPagination(meta));
        }
    }, [data, dispatcher]);

    useEffect((): void => {
        if (error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="Payment addresses" message="Failed to fetch payment addresses"/>, {
                toastId: 'temp-addresses-fetch-error'
            })
        }
    }, [error]);

    const onChangePage = useCallback((page: number): void => {
        dispatcher(setPagination({
            page, perPage, total
        }))
    }, [dispatcher, perPage, total]);

    return (
        <div className="relative">
            <main>
                <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
                    {/* Page header */}
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                        {/* Left: Title */}
                        <div className='flex items-center justify-between sm:justify-center mb-4 sm:mb-0'>
                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mr-1">Payment addresses</h1>

                            <div className="m-1">
                                {/*<button*/}
                                {/*    aria-label="Reload Payments"*/}
                                {/*    onClick={reloadHandler}*/}
                                {/*    className={`btn flex items-center w-[30px] h-[30px] rounded-full p-1.5 justify-center bg-white text-indigo-500 font-bold transition-all ${(loading || networkStatus === NetworkStatus.refetch) ? 'opacity-40 cursor-not-allowed' : 'opacity-1 hover:scale-105 active:scale-95'}`}*/}
                                {/*    disabled={loading || networkStatus === NetworkStatus.refetch}*/}
                                {/*>*/}
                                {/*    <IoReloadOutline size={20}/>*/}
                                {/*</button>*/}
                            </div>

                            {loading || networkStatus === NetworkStatus.refetch
                                ? <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                                    <path
                                        d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"/>
                                </svg>
                                : ''
                            }
                        </div>
                    </div>

                </div>
                <div className="px-4 sm:px-6 lg:px-8 pb-8 pt-4 w-full max-w-9xl mx-auto">
                    <TableTempAddress
                        headers={headers}
                        items={addresses}
                        emptyMessage="You have no payment addresses"
                    />
                    <div className="mt-8">
                        {/*/!*  pagination  *!/*/}
                        <TablePagination
                            total={total}
                            currentPage={page}
                            onPageChange={onChangePage}
                            perPage={perPage}
                            position={Position.END}
                        />
                    </div>
                </div>
            </main>

        </div>
    );
};

export default TempAddressesPage;
