import {Link, Navigate} from "react-router-dom";
import {FC, useCallback, useEffect, useState} from "react";
import {MdClose} from "react-icons/md";
import {FiEye, FiEyeOff} from "react-icons/fi";
import * as Yup from "yup";
import {SignUpType} from "../../common/types/auth/sign-up.type.ts";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useMutation} from "@apollo/client";
import {SignUpResponseType} from "../../common/types/auth/sign-up-response.type.ts";
import {SIGN_UP_MUTATION} from "../../api/graphql/mutations/sign-up.mutation.ts";
import {toast} from "react-toastify";
import {AuthInfoType} from "../../common/types/auth/auth-info.type.ts";
import {login} from "../../store/auth.store.ts";
import {AppDispatch} from "../../store";
import {useDispatch} from "react-redux";
import NotificationToast from "../../common/components/toasts/notification.toast.tsx";
import {NotificationType} from "../../common/enums/notification.enum.ts";

const initialValues: SignUpType = {
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    terms: false
};

const validationSchema: Yup.ObjectSchema<SignUpType> = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().min(6).required("Required"),
    passwordConfirmation: Yup.string()
        .min(6)
        .oneOf([Yup.ref('password'), ""], 'Passwords must match')
        .required("Required"),
    terms: Yup.boolean().required('Required').isTrue('Required')
});

const SignUpPage: FC = () => {
    const dispatcher: AppDispatch = useDispatch()
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
    const [signUp, { data, loading, error }] = useMutation<SignUpResponseType>(SIGN_UP_MUTATION);

    useEffect((): void => {
        if (error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="AuthError" message={error.message} />, {
                toastId: 'sign-up-bad-request'
            })
        }
    }, [error]);

    useEffect((): void => {
        if (data) {
            const response: AuthInfoType = data.signUp
            dispatcher(login(response))
        }
    }, [data, dispatcher]);

    const handleSubmit = useCallback(async (values: SignUpType): Promise<void> => {
        await signUp({
            variables: {
                signUpDto: {
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    passwordConfirmation: values.passwordConfirmation,
                }
            },
        });
    }, [signUp]);

    if (data) {
        return <Navigate to="/" />;
    }

    return (
        <div className="max-w-sm w-96 mx-auto px-4 py-8">
            <h1 className="text-3xl text-slate-800 font-bold mb-6">Create your Account</h1>
            {/* Form */}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue, errors, touched, isValid }) => (
                    <Form>
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="name">Your name <span className="text-rose-500">*</span></label>
                                <div className="relative">
                                    <Field
                                        id="name"
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        className={`form-input w-full ${errors.name && touched.name ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                    />

                                    <button
                                        onClick={() => setFieldValue('name', '')}
                                        className="absolute inset-0 left-auto group"
                                        type="button"
                                        tabIndex={-1}
                                        aria-label="Clear name"
                                    >
                                        <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                    </button>
                                </div>

                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="text-rose-500 text-xs"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-rose-500">*</span></label>
                                <div className="relative">
                                    <Field
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className={`form-input w-full ${errors.email && touched.email ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                    />

                                    <button
                                        onClick={() => setFieldValue('email', '')}
                                        className="absolute inset-0 left-auto group"
                                        type="button"
                                        tabIndex={-1}
                                        aria-label="Clear email"
                                    >
                                        <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                    </button>
                                </div>

                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="text-rose-500 text-xs"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="password">Password <span className="text-rose-500">*</span></label>
                                <div className="relative">
                                    <Field
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        placeholder="Password"
                                        className={`form-input w-full ${errors.password && touched.password ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                    />

                                    <button
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="absolute inset-0 left-auto right-6 group"
                                        type="button"
                                        tabIndex={-1}
                                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                                    >
                                        {showPassword
                                            ? <FiEye className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                            : <FiEyeOff className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                        }
                                    </button>

                                    <button
                                        onClick={() => setFieldValue('password', '')}
                                        className="absolute inset-0 left-auto group"
                                        type="button"
                                        tabIndex={-1}
                                        aria-label="Clear password"
                                    >
                                        <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                    </button>
                                </div>

                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="text-rose-500 text-xs"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="password_confirmation">Confirm password <span className="text-rose-500">*</span></label>
                                <div className="relative">
                                    <Field
                                        id="password_confirmation"
                                        type={showPasswordConfirmation ? "text" : "password"}
                                        name="passwordConfirmation"
                                        placeholder="Password Confirmation"
                                        className={`form-input w-full ${errors.passwordConfirmation && touched.passwordConfirmation ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""}`}
                                    />

                                    <button
                                        onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                                        className="absolute inset-0 left-auto right-6 group"
                                        type="button"
                                        tabIndex={-1}
                                        aria-label={showPasswordConfirmation ? 'Hide password confirmation' : 'Show password confirmation'}
                                    >
                                        {showPasswordConfirmation
                                            ? <FiEye className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                            : <FiEyeOff className="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                        }
                                    </button>

                                    <button
                                        onClick={() => setFieldValue('passwordConfirmation', '')}
                                        className="absolute inset-0 left-auto group"
                                        type="button"
                                        tabIndex={-1}
                                        aria-label="Clear password confirmation"
                                    >
                                        <MdClose className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2" />
                                    </button>
                                </div>

                                <ErrorMessage
                                    name="passwordConfirmation"
                                    component="div"
                                    className="text-rose-500 text-xs"
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                            <div className="mr-1">
                                <label className="flex items-center cursor-pointer" htmlFor="terms">
                                    <Field
                                        id="terms"
                                        type="checkbox"
                                        name="terms"
                                        className={`${errors.terms && touched.terms ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""} form-checkbox cursor-pointer`}
                                    />
                                    <span className={`${errors.terms && touched.terms ? "border-rose-300 text-rose-500 focus:border-rose-300" : ""} text-sm ml-2`}>
                                        I agree with the <Link aria-label="Privacy Policy Link" className="font-medium text-indigo-500 hover:text-indigo-600" target="_blank" to="/">Privacy Policy</Link>
                                    </span>
                                </label>

                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting || loading || !isValid}
                                aria-label="Sign Up"
                                className={`btn ${(isSubmitting || loading || !isValid) ? 'disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed shadow-none' : ''}bg-indigo-500 hover:bg-indigo-600 text-white ml-3`}
                            >
                                {loading
                                    ? <>
                                        <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                        </svg>
                                        <span className="ml-2">Loading</span>
                                    </>
                                    : 'Sign Up'
                                }
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {/* Footer */}
            <div className="pt-5 mt-6 border-t border-slate-200">
                <div className="text-sm">
                    Have an account? <Link aria-label="Sign In Link" className="font-medium text-indigo-500 hover:text-indigo-600" to="/auth/sign-in">Sign In</Link>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
