export enum OperationError {
    WRONG_BALANCE_COMMISSION = 'WRONG_BALANCE_COMMISSION',
    WRONG_BALANCE_PAYMENT = 'WRONG_BALANCE_PAYMENT', // когда нет баланса для отправки платежа
    INVALID_ADDRESS = 'INVALID_ADDRESS', // когда адрес отправителя или получателя не верный,
    // только в том случае если как то обошли валидацию
    // или обращались напрямую к grpc
    SENDER_NOT_EQUAL_WITH_PRIVATE_KEY = 'SENDER_NOT_EQUAL_WITH_PRIVATE_KEY', // когда адрес отправителя не совпадает с адресом из закрытого ключа
    WRONG_AMOUNT = 'WRONG_AMOUNT', // когда сумма платежа меньше или равна 0
    SYSTEM_ERROR = 'SYSTEM_ERROR', // invalid json response body или 400, 403 или что-то связанное с нодой или с нашим сервером
    CONTRACT_VALIDATE_ERROR = 'CONTRACT_VALIDATE_ERROR',
    UNDEFINED_USER = 'UNDEFINED_USER',
    UNDEFINED_TRANSFER_ADDRESS = 'UNDEFINED_TRANSFER_ADDRESS',
    MASTER_ADDRESS_NOT_FOUND = 'MASTER_ADDRESS_NOT_FOUND',
    NONE = 'NONE'
}

export enum OperationErrorMsg {
    WRONG_BALANCE_COMMISSION = 'Insufficient funds, balance replenishment',
    WRONG_BALANCE_PAYMENT = 'There are not enough funds',// когда нет баланса для отправки платежа
    SYSTEM_ERROR = 'We are having trouble sending the transaction, please check the transaction in the explorer network.', // invalid json response body или 400, 403 или что-то связанное с нодой или с нашим сервером
    INVALID_ADDRESS = 'Invalid address address',
    SENDER_NOT_EQUAL_WITH_PRIVATE_KEY = 'The "from" address does not match the private key address. Check the data is correct!',
    WRONG_AMOUNT = 'Payment was not received in full!',
    CONTRACT_VALIDATE_ERROR = 'Insufficient balance',
    UNDEFINED_USER = 'User not found',
    UNDEFINED_TRANSFER_ADDRESS = 'Transfer address not found',
    MASTER_ADDRESS_NOT_FOUND = 'Master address not found',
    NONE = ''
}
