import {FC, RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    closePaymentPanel,
    paymentSelector,
    setPayment,
} from "../../../store/payment.store.ts";
import {AppDispatch} from "../../../store";
import Payment from "./payment.component.tsx";
import Spinner from "../general/spinner.component.tsx";
import {QueryResult, useQuery} from "@apollo/client";
import {GetPaymentResponseType} from "../../types/payment/get-payment-response.type.ts";
import {PAYMENT_QUERY} from "../../../api/graphql/queries/payment.query.ts";
import {PaymentType} from "../../types/store/payment-state.type.ts";
import {toast} from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import {NotificationType} from "../../enums/notification.enum.ts";

const PaymentPanel: FC = () => {
    const dispatcher: AppDispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    const panelContent: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const {paymentId, payment} = useSelector(paymentSelector);

    const {data, loading, error}: QueryResult<GetPaymentResponseType> = useQuery<GetPaymentResponseType>(PAYMENT_QUERY, {
        variables: {
            id: paymentId
        },
        skip: !paymentId
    });

    useEffect((): void => {
        if (!show) {
            dispatcher(closePaymentPanel(null));
            dispatcher(setPayment(null));
        }
    }, [dispatcher, show]);

    useEffect((): void => {
        if (!paymentId) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [paymentId]);

    useEffect((): void => {
        if (data) {
            const response: PaymentType = data.getPaymentOperation;
            dispatcher(setPayment(response))
        }
    }, [data, dispatcher]);

    useEffect((): void => {
        if (error) {
            toast(<NotificationToast  type={NotificationType.ERROR} title="Fetch" message="Failed to fetch payment"/>, {
                toastId: 'payment-fetch-error'
            })
            dispatcher(setPayment(null))
        }
    }, [error, dispatcher]);

    // outside click
    const clickHandler = useCallback(({ target }: MouseEvent): void => {
        if (panelContent.current !== null) {
            if (!panelContent.current.contains(target as Node) && paymentId) {
                setShow(false)
            }
        }
    }, [paymentId]);

    const keyHandler = useCallback(({key}: KeyboardEvent): void => {
        if (key === 'Escape' && show) {
            setShow(false)
        }
    }, [show])

    useEffect(() => {
        if (show) {
            document.addEventListener('click', clickHandler);
        } else {
            document.removeEventListener('click', clickHandler);
        }
        return () => document.removeEventListener('click', clickHandler);
    }, [clickHandler, show]);

    useEffect(() => {
        if (show) {
            document.addEventListener('keydown', keyHandler);
        } else {
            document.removeEventListener('keydown', keyHandler);
        }
        return () => document.removeEventListener('keydown', keyHandler);
    }, [keyHandler, show]);

    return (
        <div
            ref={panelContent}
            className={`absolute inset-0 sm:left-auto z-20 transform shadow-xl transition-transform duration-200 ease-in-out ${
                show ? 'translate-x-' : 'translate-x-full'
            }`}>
            <div className="sticky top-16 bg-slate-50 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 w-full sm:w-[390px] h-[calc(100vh-64px)]">
                <button
                    onClick={() => setShow(false)}
                    className="absolute top-0 right-0 mt-6 mr-6 group p-2"
                >
                    <svg className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                    </svg>
                </button>

                {loading && <div className='fixed w-full h-screen l-0 t-0 flex items-center justify-center bg-slate-100 transition-all z-50'>
                    <Spinner />
                </div>}
                <div className="py-8 px-4 lg:px-8">
                    {!loading &&  <Payment payment={payment} />}
                </div>
            </div>
        </div>
    );
};

export default PaymentPanel;
