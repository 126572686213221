import { DocumentNode, gql } from "@apollo/client";

export const PAYMENT_PANEL_INFO_FRAGMENT: DocumentNode = gql(`
    fragment PaymentPanelInfo on Operation {
        id
        internalId
        currency
        walletType
        status
        sysStatus
        userStatus
        amount
        orderId
        description
        error
        callbackUrl
        successUrl
        errorUrl
        comment
        paymentAt
        expiredAt
        createdAt
        paymentLink
        paymentFee
        address {
            address
            balances {
                currency
                currencyType
                balance
            }
        }
    }
`);
