
const EmptyPage = () => {
    return (
        <div className="flex items-center justify-center w-full">
            Choice a setting
        </div>
    );
};

export default EmptyPage;
