import {FC} from "react";
import {ISmallPaymentAnalytics} from "../../interfaces/analytics/analytics.interfce.ts";
import PropTypes from "prop-types";
import {formatCurrency, formatNumber, getHumanDescCurrency} from "../../utils/currency.util.ts";
import {Currency} from "../../enums/currency.enum.ts";

const SimplePayoutAnalytic: FC<ISmallPaymentAnalytics> = ({
   count,
   amount,
}) => {
    return (
        <div className="bg-white px-4 rounded shadow flex flex-wrap">
            <div className="flex items-center py-1">
                <div className="mr-5">
                    <div className="flex items-center">
                        <div className="text-sm font-bold text-slate-500 mr-2">Count:</div>
                        <div className="text-sm text-slate-500">{formatNumber(count || 0, 0)}</div>
                    </div>
                </div>
                <div className="hidden md:block w-px h-8 bg-slate-200 mr-5" aria-hidden="true"></div>
            </div>
            <div className="flex items-center py-1">
                <div className="mr-5">
                    <div className="flex items-center">
                        <div className="text-sm font-bold text-slate-500 mr-2">Amount:</div>
                        <div className="text-sm text-slate-500">
                            {formatCurrency(amount || 0) + ` ${getHumanDescCurrency(Currency.BEP20)}`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SimplePayoutAnalytic.propTypes = {
    count: PropTypes.number,
    amount: PropTypes.number
};

SimplePayoutAnalytic.defaultProps = {
    count: 0,
    amount: 0
}

export default SimplePayoutAnalytic
