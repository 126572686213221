import {DocumentNode, gql} from "@apollo/client";

export const SEND_CALLBACK_MUTATION: DocumentNode = gql(`
    mutation SendCallback($operationId: Float!, $url: String) {
      sendCallbackOperation(operationId: $operationId, url: $url) {
        sent
        message
        response
      }
    }
`);
