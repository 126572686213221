import { useParams } from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from "react";
import { validate } from "uuid";
import Spinner from "../../common/components/general/spinner.component.tsx";

import {
	SubscriptionResult,
	useMutation,
	useQuery,
	useSubscription,
} from "@apollo/client";
import { GetPaymentPublicResponseType } from "../../common/types/payment/get-payment-public-response.type.ts";
import { PAYMENT_PUBLIC_QUERY } from "../../api/graphql/queries/payment-public.query.ts";
import { PaymentPublicInfoType } from "../../common/types/payment/payment-public-info.type.ts";
import PaymentNew from "../../common/components/payment/payment-public/payment-new.component.tsx";
import { OperationUserStatus } from "../../common/enums/operation-user-status.enum.ts";
import PaymentSuccess from "../../common/components/payment/payment-public/payment-success.component.tsx";
import PaymentProcessed from "../../common/components/payment/payment-public/payment-processed.component.tsx";
import PaymentExpired from "../../common/components/payment/payment-public/payment-expired.component.tsx";
import PaymentExtended from "../../common/components/payment/payment-public/payment-extended.component.tsx";
import {
	getTrustWalletLingByCurrency,
	openInNewTab,
} from "../../common/utils/common.util.ts";
import { PAYMENT_PUBLIC_NOTIFICATION } from "../../api/graphql/subscriptions/payment-public.subscription.ts";
import { PaymentPublicNotificationResponse } from "../../common/types/notification/payment-public-notification-response.type.ts";
import { Slide, toast, ToastContainer } from "react-toastify";
import NotificationToast from "../../common/components/toasts/notification.toast.tsx";
import { Transition } from "@headlessui/react";
import { PaymentPublicNotificationType } from "../../common/types/notification/payment-public-notification.type.ts";
import PaymentNotFound from "../../common/components/payment/payment-public/payment-not-found.component.tsx";
import { NotificationType } from "../../common/enums/notification.enum.ts";
import { CheckPaymentType } from "../../common/types/payment/check-payment.type.ts";
import { CheckPaymentResponse } from "../../common/types/payment/check-payment-response.type.ts";
import { CHECK_PAYMENT_PUBlIC_MUTATION } from "../../api/graphql/mutations/check-payment-public.mutation.ts";

const PaymentPage = () => {
	const { internalId } = useParams();
	const [payment, setPayment] = useState<PaymentPublicInfoType>();

	const { data, loading } = useQuery<GetPaymentPublicResponseType>(
		PAYMENT_PUBLIC_QUERY,
		{
			variables: { internalId },
			skip: !validate(String(internalId)),
		},
	);

	const paymentPublicNotificationSub: SubscriptionResult<PaymentPublicNotificationResponse> =
		useSubscription(PAYMENT_PUBLIC_NOTIFICATION, {
			shouldResubscribe: true,
			variables: { internalId },
			fetchPolicy: "no-cache",
			skip: !validate(String(internalId)),
		});

	console.log(payment);
	const [checkPaymentPublic, checkPaymentData] =
		useMutation<CheckPaymentResponse>(CHECK_PAYMENT_PUBlIC_MUTATION);

	useEffect(() => {
		if (paymentPublicNotificationSub.data !== undefined) {
			const response: PaymentPublicNotificationType =
				paymentPublicNotificationSub.data.publicNotification;
			if (
				response.__typename === "PublicNotification" &&
				response.type &&
				response.message
			) {
				toast(
					<NotificationToast
						type={response.type}
						title="Payment"
						message={response.message}
					/>,
					{
						toastId: "public-payment-notification-" + response.message,
						containerId: "public-payment",
						autoClose: 2000,
					},
				);
			}

			if (
				response.__typename === "PublicNotificationUserStatus" &&
				response.userStatus
			) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				setPayment((prevPayment) => {
					return {
						...prevPayment,
						status: response.userStatus,
					};
				});
			}
		}
	}, [paymentPublicNotificationSub.data, paymentPublicNotificationSub.loading]);

	useEffect(() => {
		if (checkPaymentData.data) {
			const response: CheckPaymentType =
				checkPaymentData.data?.checkPaymentPublic;
			toast(
				<NotificationToast
					type={NotificationType.INFO}
					title="Payment"
					message={response.message}
				/>,
				{
					toastId: "public-payment-notification-" + response.message,
					containerId: "public-payment",
					autoClose: 4000,
				},
			);
		}
	}, [checkPaymentData.data, checkPaymentData.loading]);

	useEffect(() => {
		if (!validate(String(internalId))) {
			window.close();
		}
	}, [internalId]);

	useEffect(() => {
		if (data) {
			const response: PaymentPublicInfoType = data.getPublicPaymentOperation;
			setPayment(response);
		}
	}, [data]);

	useEffect((): void => {
		if (checkPaymentData.error) {
			toast(
				<NotificationToast
					type={NotificationType.WARNING}
					title="Check Payment"
					message={checkPaymentData.error.message}
				/>,
				{
					toastId: "check-payment-public-fetch-error",
					containerId: "public-payment",
					autoClose: 4000,
				},
			);
		}
	}, [checkPaymentData.error]);

	const openTrustWallet = useCallback(() => {
		const link = getTrustWalletLingByCurrency(
			payment?.currency,
			payment?.address,
			payment?.amount,
		);

		console.log(link);

		if (link !== undefined) {
			openInNewTab(link);
		}
	}, [payment?.address, payment?.amount, payment?.currency]);

	const paid = useCallback(async () => {
		await checkPaymentPublic({
			variables: {
				internalId,
			},
		});
	}, [checkPaymentPublic, internalId]);

	return (
		<>
			{loading && (
				<div className="fixed w-full h-screen l-0 t-0 flex items-center justify-center bg-slate-100 transition-all z-50">
					<Spinner className="w-14 h-14" />
				</div>
			)}

			<header>
				<div className="px-4 sm:px-6 lg:px-8"></div>
			</header>

			<main className="mb-4">
				<div className="pt-8 text-center flex flex-col items-center justify-center">
					{payment && (
						<>
							<h1 className="text-gray-800 font-bold text-[20px] uppercase mb-2">
								{payment?.userName}
							</h1>
							<div className="text-gray-500 block max-w-[370px] text-[16px] mb-2">
								<span className="uppercase">Invoice #</span>
								{payment?.orderId}
							</div>
						</>
					)}
				</div>

				<div className="max-w-[380px] mx-auto mt-2">
					<Transition
						as={Fragment}
						show={!loading}
						enter="transition ease-in-out duration-600"
						enterFrom="opacity-0 translate-y-4"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in-out duration-300"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-4"
					>
						<div>
							{payment?.status === OperationUserStatus.NEW && (
								<PaymentNew payment={payment} />
							)}
							{payment?.status === OperationUserStatus.SUCCESS && (
								<PaymentSuccess payment={payment} />
							)}
							{payment?.status === OperationUserStatus.PROCESSED && (
								<PaymentProcessed payment={payment} />
							)}
							{payment?.status === OperationUserStatus.EXPIRED && (
								<PaymentExpired payment={payment} />
							)}
							{payment?.status === OperationUserStatus.EXTENDED && (
								<PaymentExtended payment={payment} />
							)}

							{!payment && <PaymentNotFound />}
						</div>
					</Transition>
				</div>
			</main>

			{payment?.status === OperationUserStatus.NEW && (
				<footer>
					<div className="flex items-center justify-center py-4">
						{/*{payment.currency === Currency.JETTON && (*/}
							<button
								disabled={checkPaymentData.loading}
								onClick={paid}
								className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
							>
								I paid
							</button>
						{/*)}*/}
						<button
							onClick={openTrustWallet}
							className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
						>
							Open in TrustWallet
						</button>
					</div>
				</footer>
			)}

			<ToastContainer
				containerId="public-payment"
				position="bottom-right"
				autoClose={60 * 1000}
				hideProgressBar
				newestOnTop={true}
				closeOnClick
				rtl={false}
				draggable
				theme="colored"
				closeButton={false}
				pauseOnHover={true}
				icon={false}
				transition={Slide}
				limit={4}
			/>
		</>
	);
};
export default PaymentPage;
