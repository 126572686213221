import {FC, useEffect} from 'react';
import AuthImage from "../assets/img/auth-img.jpg";
import AuthDecoration from "../assets/img/auth-decoration.png";
import {AuthLayoutType} from "../common/types/layouts/auth-layout.type.ts";
import {ToastContainer, Slide} from "react-toastify";
import {useSelector} from "react-redux";
import {authSelector} from "../store/auth.store.ts";
import {NavigateFunction, useNavigate} from "react-router-dom";


const AuthLayout: FC<AuthLayoutType> = ({ children }) => {
    const navigate: NavigateFunction = useNavigate();
    const { isAuth} = useSelector(authSelector);

    useEffect(() => {
        if (isAuth) {
            navigate('/')
        }
    }, [isAuth, navigate])

    return (
        <main className="bg-white">
            <div className="relative md:flex">
                {/* Content */}
                <div className="md:w-1/2">
                    <div className="min-h-screen h-full flex flex-col after:flex-1">
                        {/* Header */}
                        <div className="flex-1">
                            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"></div>
                        </div>
                        {/* content */}
                        {children}
                    </div>
                </div>

                {/* Image */}
                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
                    <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
                </div>

            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
                closeButton={false}
                icon={false}
                transition={Slide}
            />
        </main>
    );
};

export default AuthLayout;
