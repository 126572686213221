import {DocumentNode, gql} from "@apollo/client";

export const PAYMENTS_QUERY: DocumentNode = gql(`    
    query GetPaymentOperations($paymentOperationsRequest: PaymentOperationsRequest!) {
      getPaymentOperations(paymentOperationsDTO: $paymentOperationsRequest) {
        data {
         ...PaymentTableInfo
        }
        meta {
          page
          perPage
          total
        }
      }
    }
`);
