import {Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Transition} from "@headlessui/react";
import {useMutation} from "@apollo/client";
import {AiOutlineLoading} from 'react-icons/ai'
import {TFA_GENERATE_MUTATION} from "../../../api/graphql/mutations/tfa-generate.mutation.ts";
import {QRCode} from "react-qrcode-logo";
import {TFA_ON_MUTATION} from "../../../api/graphql/mutations/tfa-on.mutation.ts";
import {toast} from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import {NotificationType} from "../../enums/notification.enum.ts";
import {AppDispatch} from "../../../store";
import {useDispatch} from "react-redux";
import {setTfaEnabled} from "../../../store/user.store.ts";

type TFAOnModalProps = {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}

const TFAOnModal: FC<TFAOnModalProps> = ({show, setShow}) => {
    const dispatcher: AppDispatch = useDispatch();
    const [code, setCode] = useState<string>('');
    const [tfaUri, setTfaUri] = useState<string>('');
    const [tfaSecret, setTfaSecret] = useState<string>('');

    const [generateTfa, generateTfaData] = useMutation(TFA_GENERATE_MUTATION);
    const [onTfa, onTfaData] = useMutation(TFA_ON_MUTATION);

    useEffect(() => {
        if (show) {
            generateTfa()
                .then()
        } else {
            setCode('');
            setTfaUri('');
        }
    }, [generateTfa, show]);

    useEffect(() => {
        if (generateTfaData.data) {
            const {generateTFA: {secret, uri}} = generateTfaData.data;
            setTfaSecret(secret);
            setTfaUri(uri)
        }
    }, [generateTfaData.data]);

    useEffect(() => {
        if (generateTfaData.error) {
            toast(<NotificationToast type={NotificationType.WARNING} title="TFA Code"
                                     message="Error generating TFA try later"/>, {
                toastId: 'generate-tfa-error'
            });
            setShow(false)
        }
    }, [generateTfaData.error, setShow]);

    useEffect(() => {
        if (onTfaData.data) {
            const {onTFA} = onTfaData.data;

            if (onTFA) {
                toast(<NotificationToast type={NotificationType.SUCCESS} title="TFA" message="Successfully enabled"/>, {
                    toastId: 'on-tfa-success'
                });

                dispatcher(setTfaEnabled(true))
                setShow(false);
            }
        }
    }, [dispatcher, onTfaData, onTfaData.data, setShow]);

    useEffect(() => {
        if (onTfaData.error) {
            toast(<NotificationToast type={NotificationType.WARNING} title="TFA Code"
                                     message={onTfaData.error.message}/>, {
                toastId: 'on-tfa-error'
            });
        }
    }, [onTfaData.error]);

    const validateTFAHandler = useCallback(async () => {
        await onTfa({
            variables: {code}
        })
    }, [code, onTfa]);

    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div onClick={(e) => e.stopPropagation()}
                         className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full">
                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Enabled TFA</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <div className="p-4 pb-0">
                            <div className="mb-4 flex items-center flex-col justify-center transition-all">
                                <div>
                                    {generateTfaData.loading || !tfaUri
                                        ? <div className="p-20">
                                            <AiOutlineLoading size={32} className="transition-all animate-spin"/>
                                        </div>
                                        : <QRCode
                                            ecLevel="L"
                                            eyeRadius={[5, 5, 5]}
                                            fgColor='#223049'
                                            value={tfaUri}
                                            enableCORS={true}
                                            size={250}
                                        />
                                    }
                                </div>
                                <p className="text-center font-medium text-slate-800">Or Copy and Paste this Code:</p>
                                <p className="text-center font-bold">{tfaSecret}</p>

                                <div className="col-span-6 form-control w-full mt-4">
                                    <input
                                        id='tfa-code'
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        placeholder="TFA Code"
                                        className='form-input w-full max-w-[646px] mr-1'
                                        type="text"
                                    />
                                </div>
                            </div>
                            {/*<label className="block text-sm font-medium mb-1" htmlFor="safe_password">TFA Code:</label>*/}
                            {/*<div className="relative mb-2">*/}
                            {/*    <input*/}
                            {/*        id='tfa-code'*/}
                            {/*        value={code}*/}
                            {/*        onChange={(e) => setCode(e.target.value)}*/}
                            {/*        placeholder="TFA Code"*/}
                            {/*        className='form-input w-full max-w-[646px] mr-1'*/}
                            {/*        type="text"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                        {/* Modal footer */}

                        <div className="flex justify-end p-4">
                            <button
                                onClick={() => setShow(false)}
                                className='bg-white border text-gray-700 hover:bg-gray-100 hover btn-sm whitespace-nowrap mr-2'
                            >
                                Cancel
                            </button>
                            <button
                                className={`${!code && 'opacity-40'} bg-indigo-500 hover:bg-indigo-600 btn-sm text-white whitespace-nowrap`}
                                disabled={!code}
                                onClick={validateTFAHandler}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default TFAOnModal;
