import PropTypes from "prop-types";
import {FC} from "react";
import {IconType} from "../../types/auth/icon.type.ts";

const EmptyIcon: FC<IconType> = ({width, height, className, isActive}) => {

    const firstColor = isActive ? '#6366F1' : '#475569'
    const secondColor = isActive ? '#A5B4FC' : '#94A3B8'
    const thirdColor = isActive ? '#4338CA' : '#334155'

    return (
        <svg className={className} width={width} height={height} viewBox="0 0 20 24">
            <path fill={firstColor} className="text-slate-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
            <path fill={secondColor} className="text-slate-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
            <path fill={thirdColor} className="text-slate-400" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
        </svg>
    )
}

EmptyIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isActive: PropTypes.bool,
    className: PropTypes.string,
};

EmptyIcon.defaultProps = {
    width: 24,
    height: 24
};

export default EmptyIcon
