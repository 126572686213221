import {FC, useState} from "react";
import Flatpickr from "react-flatpickr";
import flatpickr from "flatpickr";
import useTimezone from "../../hooks/timezone.hook.ts";
import moment from "moment-timezone";
import {MdClose} from "react-icons/md";
import {DatepickerRangeProps} from "../../interfaces/datepicker.interface.ts";
import PropTypes from "prop-types";

flatpickr.l10ns.default.rangeSeparator = " to ";

const DatepickerRange: FC<DatepickerRangeProps> = ({
   label,
   minDate,
   maxDate,
   isDisabled,
   onChange,
   className
}) => {
    const timezone = useTimezone();
    const [innerValue, setInnerValue] = useState<Date[] | undefined>(undefined);

    return (
        <div className={`relative bg-white rounded ${className}`}>
            <Flatpickr
                className="relative z-10 h-full text-center form-input bg-transparent px-9 text-xs text-slate-500 hover:text-slate-600 font-medium focus:border-slate-300 w-60"
                value={innerValue}
                options={{
                    dateFormat: "d/M/Y",
                    formatDate: (date): string => {
                        return moment(date).tz(timezone).format("DD/MM/YYYY");
                    },
                    minDate: minDate,
                    maxDate: maxDate,
                    clickOpens: !isDisabled,
                    mode: 'range'
                }}
                onChange={(selectedDates: Date[]): void => {
                    setInnerValue(selectedDates);
                    onChange?.(selectedDates);
                }}
                onClose={(selectedDates: Date[]): void => {
                    if (selectedDates.length < 2) {
                        setInnerValue(undefined);
                        onChange?.(undefined);
                    }
                }}
            ></Flatpickr>
            <div className="absolute inset-0 right-auto flex items-center">
                <svg className="w-4 h-4 fill-current text-slate-500 ml-3" viewBox="0 0 16 16">
                    <path
                        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"/>
                </svg>
            </div>
            {(!!innerValue && !isDisabled) &&
                <div
                    onClick={(): void => {
                        setInnerValue(undefined);
                        onChange?.(undefined);
                    }}
                    className="absolute inset-0 left-auto flex items-center z-20 cursor-pointer"
                >
                    <MdClose className="w-4 h-4 fill-current text-slate-500 mr-3 group-hover:text-slate-500"/>
                </div>
            }
            {!innerValue &&
                <div className="absolute text-xs top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-center z-1">
                    {label}
                </div>
            }
        </div>
    );
}

DatepickerRange.propTypes = {
    label: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

DatepickerRange.defaultProps = {
    label: "Select date range",
    minDate: undefined,
    maxDate: undefined,
    isDisabled: true,
    onChange: console.log,
    className: ''
}

export default DatepickerRange
