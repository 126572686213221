import {createSlice, Draft, Slice} from "@reduxjs/toolkit";
import {PAYMENT_SLICE_NAME} from "../common/constants/store.constant.ts";
import {Align} from "../common/enums/align.enum.ts";
import {PaymentStateType} from "../common/types/store/payment-state.type.ts";
import {RootState} from "./index.ts";


const initialState: PaymentStateType = {
    headers: [
        {name: 'ID', value: 'orderId', align: Align.LEFT},
        {name: 'Date', value: 'createdAt', align: Align.LEFT},
        {name: 'Type', value: 'walletType', align: Align.LEFT},
        {name: 'Amount', value: 'amount', align: Align.LEFT},
        {name: 'Fee', value: 'paymentFee', align: Align.LEFT},
        {name: 'Description', value: 'description', align: Align.LEFT},
        {name: 'Address', value: 'paymentAddress', align: Align.LEFT},
        {name: 'Status', value: 'status', align: Align.CENTER}
    ],
    payments: [],
    payment: null,
    pagination: {
        page: 1,
        perPage: 10,
        total: 0
    },
    paymentId: null
}

const slice: Slice = createSlice({
    name: PAYMENT_SLICE_NAME,
    initialState,
    reducers: {
        setPayments: (state: Draft<PaymentStateType>, { payload }): void => {
            state.payments = payload;
        },
        setPayment: (state: Draft<PaymentStateType>, { payload }): void => {
            state.payment = payload;
        },
        setStatus: (state: Draft<PaymentStateType>, { payload }) => {
            const index: number = state.payments.findIndex(p => p.id === payload.id);

            if (index !== -1) {
                state.payments[index].status = payload.status;
            }

            if (state.payment && state.payment.id === payload.id) {
                state.payment.status = payload.status;
            }
        },
        setUserStatus: (state: Draft<PaymentStateType>, { payload }) => {
            const index: number = state.payments.findIndex(p => p.id === payload.id);

            if (index !== -1) {
                state.payments[index].userStatus = payload.userStatus;
            }

            if (state.payment && state.payment.id === payload.id) {
                state.payment.userStatus = payload.userStatus;
            }
        },
        setSysStatus: (state: Draft<PaymentStateType>, { payload }) => {
            const index: number = state.payments.findIndex(p => p.id === payload.id);

            if (index !== -1) {
                state.payments[index].sysStatus = payload.sysStatus;
            }

            if (state.payment && state.payment.id === payload.id) {
                state.payment.sysStatus = payload.sysStatus;
            }
        },
        addPayment: (state: Draft<PaymentStateType>, {payload}) => {
            const index: number = state.payments.findIndex((p: any) => p.id === payload.id);

            if (index === -1) {
                state.payments.unshift(payload);
            }
        },
        setPaymentPagination: (state: Draft<PaymentStateType>, { payload }): void => {
            state.pagination = payload;
        },
        openPaymentPanel: (state: Draft<PaymentStateType>, {payload}): void => {
            state.paymentId = payload;
        },
        closePaymentPanel: (state: Draft<PaymentStateType>): void => {
            state.paymentId = null;
        },
        setErrorToPaymentPanel: (state: Draft<PaymentStateType>, {payload}): void => {
            if (state.payment) {
                if (state.payment.id === payload.operationId) {
                    state.payment.error = payload.error
                }
            }
        },

    }
});

export const paymentSelector = (state: RootState) => state.payment;
export const {
    setPayments,
    setPayment,
    setPaymentPagination,
    openPaymentPanel,
    closePaymentPanel,
    setStatus,
    setUserStatus,
    setSysStatus,
    setErrorToPaymentPanel,
    addPayment
} = slice.actions;
export default slice.reducer;
