import {FC} from "react";
import {RiErrorWarningFill} from 'react-icons/ri'
import {PaymentPublicBlockType} from "../../../types/payment/payment-public-block.type.ts";



const PaymentProcessed: FC<PaymentPublicBlockType> = ({payment}) => {
    return (
        <div className="bg-white px-8 pb-5 rounded-[10px] shadow-lg border">

            <div className="text-center mb-4 pt-7">
                <h1 className="text-[36px] text-gray-800 font-bold">${payment?.amount}</h1>
                <div className="text-[20px] text-gray-500 font-bold mb-2">USDT</div>
                <div className="text-[14px] text-gray-400">{payment?.description}</div>
            </div>

            <div className="flex flex-col items-center justify-center mb-16">
                <div className="rounded-full bg-indigo-100 p-5 mb-5">
                    <RiErrorWarningFill className='text-indigo-500' size={130} />
                </div>
                <p className='font-medium'>Processing the payment</p>
            </div>
        </div>
    );
};


export default PaymentProcessed;
