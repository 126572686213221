import { FC, Fragment, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";
import { DepositModalType } from "../../types/balance/deposit-modal.type.ts";
import { useSelector } from "react-redux";
import { balanceSelector } from "../../../store/balance.store.ts";
import { QRCode } from "react-qrcode-logo";
import { getIconByCurrency } from "../../utils/currency.util.ts";
import { useCopy } from "../../hooks/copy.hook.ts";
import { toShort2 } from "../../utils/common.util.ts";

const DepositModal: FC<DepositModalType> = ({ show, setShow }) => {
	const { walletAccount } = useSelector(balanceSelector);
	const [isCopied, copyApiKey] = useCopy(1000);

	useEffect(() => {
		if (!walletAccount) {
			setShow(false);
		}
	}, [setShow, walletAccount]);

	const copyHandler = useCallback(async () => {
		if (walletAccount?.address) {
			await copyApiKey(walletAccount?.address);
		}
	}, [walletAccount?.address, copyApiKey]);

	// const openTrustWallet = useCallback(() => {
	//     const link: string|undefined = getTrustWalletLingByCurrency(
	//         walletAccount?.currency,
	//         walletAccount?.address,
	//         '1'
	//     )
	//
	//     if (link !== undefined) {
	//         openInNewTab(link)
	//     }
	//
	// }, [walletAccount?.address, walletAccount?.currency]);

	return (
		<>
			<Transition
				as={Fragment}
				show={show}
				enter="transition ease-out duration-100"
				enterFrom="opacity-0 translate-y-1"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in duration-80"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-1"
			>
				<div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
			</Transition>
			<Transition
				as={Fragment}
				show={show}
				enter="transition ease-in-out duration-200"
				enterFrom="opacity-0 translate-y-4"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in-out duration-200"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-4"
			>
				<div className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
					<div
						onClick={(e) => e.stopPropagation()}
						className="relative p-8 pb-4 bg-white rounded shadow-lg overflow-auto max-w-lg"
					>
						<button
							className="absolute top-6 right-6 text-slate-400 hover:text-slate-500"
							onClick={(e) => {
								e.stopPropagation();
								setShow(false);
							}}
						>
							<div className="sr-only">Close</div>
							<svg className="w-4 h-4 fill-current">
								<path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
							</svg>
						</button>
						{/* Modal header */}
						<div className="mb-2 text-center">
							{/* Icon */}
							<div className="mb-3">
								<div className="inline-flex w-12 h-12 rounded-full shrink-0 fill-current">
									<img
										className=" rounded-full"
										src={getIconByCurrency(walletAccount?.currency)}
										alt={walletAccount?.currency}
									/>
								</div>
							</div>
							<div className="text-lg font-semibold text-slate-800">
								Top up balance
							</div>
						</div>
						{/* Modal content */}
						<div className="text-center">
							<div className="text-sm mb-6">
								In order to replenish the balance, transfer{" "}
								<span className="font-semibold">{walletAccount?.currency}</span>{" "}
								to this wallet
							</div>
							<div className="flex items-center justify-center p-4">
								{walletAccount?.address && (
									<QRCode
										ecLevel="L"
										eyeRadius={[10, 10, 10]}
										fgColor="#223049"
										value={walletAccount?.address || "1234"}
										size={210}
										// removeQrCodeBehindLogo={true}
										// logoImage={getIconByCurrency(walletAccount?.currency)}
										// logoWidth={45}
										// logoHeight={45}
										// logoPaddingStyle="circle"
										// quietZone={0}
									/>
								)}
							</div>
							{/* Submit form */}
							<div className="flex max-w-sm m-auto">
								<div className="grow mr-2">
									<label
										className="block text-sm font-medium mb-1"
										htmlFor="transfer_address"
									>
										Address
									</label>
									<div className="relative">
										<input
											id="transfer_address"
											className="form-input text-center w-full px-2 py-1 overflow-hidden text-ellipsis whitespace-nowrap pr-8"
											placeholder="Address"
											type="text"
											value={toShort2(walletAccount?.address, 15, 15) || ""}
											disabled
										/>
										<button
											onClick={copyHandler}
											className="absolute inset-0 left-auto group"
											type="button"
											aria-label="Search"
										>
											<svg
												className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-3"
												viewBox="0 0 14 14"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M2.99999 0.916626C2.44746 0.916626 1.91755 1.13612 1.52685 1.52682C1.13615 1.91752 0.916656 2.44743 0.916656 2.99996V8.33329C0.916656 8.88583 1.13615 9.41573 1.52685 9.80643C1.91755 10.1971 2.44746 10.4166 2.99999 10.4166H3.58332V11C3.58332 12.1506 4.51606 13.0833 5.66666 13.0833H11C12.1506 13.0833 13.0833 12.1506 13.0833 11V5.66663C13.0833 4.51603 12.1506 3.58329 11 3.58329H10.4167V2.99996C10.4167 2.44742 10.1972 1.91752 9.80646 1.52682C9.41576 1.13612 8.88586 0.916626 8.33332 0.916626H2.99999ZM8.91666 3.58329V2.99996C8.91666 2.84525 8.8552 2.69688 8.7458 2.58748C8.63641 2.47808 8.48803 2.41663 8.33332 2.41663H2.99999C2.84528 2.41663 2.69691 2.47808 2.58751 2.58748C2.47811 2.69688 2.41666 2.84525 2.41666 2.99996V8.33329C2.41666 8.488 2.47811 8.63638 2.58751 8.74577C2.69691 8.85517 2.84528 8.91663 2.99999 8.91663H3.58332V5.66663C3.58332 4.51603 4.51606 3.58329 5.66666 3.58329H8.91666ZM5.08332 9.66663V11C5.08332 11.3221 5.34449 11.5833 5.66666 11.5833H11C11.3222 11.5833 11.5833 11.3221 11.5833 11V5.66663C11.5833 5.34446 11.3222 5.08329 11 5.08329H9.66666H5.66666C5.34449 5.08329 5.08332 5.34446 5.08332 5.66663V9.66663Z"
													fill="#6366F1"
												/>
											</svg>
										</button>
										{isCopied && (
											<div className="transition-all absolute text-green-500 text-xs right-0 top-auto">
												address copied
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="flex justify-center p-4 pb-0 pt-6">
								{/*TODO: сделать проверку на пополнение*/}
								{/*<button*/}
								{/*    onClick={openTrustWallet}*/}
								{/*    className='btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3'*/}
								{/*>Open in TrustWallet*/}
								{/*</button>*/}
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</>
	);
};

DepositModal.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
};

export default DepositModal;
