import {DocumentNode, gql} from "@apollo/client";

export const BALANCES_QUERY: DocumentNode = gql(`    
    query GetBalanceOperations($dto: BalanceOperationsRequest!) {
      getBalanceOperations(balanceOperationsDTO: $dto) {
        data {
         ...BalanceTableInfo
        }
        meta {
          page
          perPage
          total
        }
      }
    }
`);
