import PropTypes from "prop-types";
import {FC} from "react";
import {IconType} from "../../types/auth/icon.type";

const BalanceIcon: FC<IconType> = ({ width, height, className, isActive }) => {

    const firstColor = isActive ? '#6366F1' : '#475569'
    const SecondColor = isActive ? '#A5B4FC' : '#94A3B8'
    const ThirdColor = isActive ? '#4338CA' : '#334155'

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 6.06811C15.5184 6.49308 17.5065 8.4823 17.9324 11.001H24C23.5138 5.15487 18.8456 0.486226 13 0.000976562V6.06811Z" fill={firstColor}/>
        <path d="M18.0074 13C17.5331 15.833 15.0881 18 12.1429 18C10.7467 18 9.46465 17.51 8.44852 16.696L4 20.731C6.13148 22.752 8.99231 24 12.1429 24C18.3746 24 23.4929 19.149 24 13H18.0074Z" fill={SecondColor}/>
        <path d="M6.939 15.0068C6.348 14.0862 6 12.9983 6 11.8292C6 8.89221 8.167 6.45311 11 5.97895V0C4.85 0.506825 0 5.61368 0 11.8292C0 14.5247 0.922 17.0044 2.456 19L6.939 15.0068Z" fill={ThirdColor}/>
    </svg>
  )
}

BalanceIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isActive: PropTypes.bool,
    className: PropTypes.string,
};

BalanceIcon.defaultProps = {
    width: 24,
    height: 24
};

export default BalanceIcon
