// BSC
export const BNB_ASSET = "c20000714";
export const BEP20_CONTRACT =
	import.meta.env.VITE_BEP20_CONTRACTADDRESS ||
	"0x55d398326f99059fF775485246999027B3197955";
export const BSC_BEP20_ASSET = `${BNB_ASSET}_t${BEP20_CONTRACT}`;

// TRON
export const TRX_ASSET = "c195";
export const TRC20_CONTRACT =
	import.meta.env.VITE_TRC20_CONTRACTADDRESS ||
	"TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
export const TRON_TRC20_ASSET = `${TRX_ASSET}_t${TRC20_CONTRACT}`;

// TON
export const TON_ASSET = "c607";
export const JETTON_CONTRACT =
	import.meta.env.VITE_JETTON_CONTRACTADDRESS ||
	"EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs";
export const TON_JETTON_ASSET = `${TON_ASSET}_t${JETTON_CONTRACT}`;
