import {Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Transition} from "@headlessui/react";
import {useMutation} from "@apollo/client";
import {AppDispatch} from "../../../store";
import {useDispatch} from "react-redux";
import {TFA_OFF_MUTATION} from "../../../api/graphql/mutations/tfa-off.mutation.ts";
import {toast} from "react-toastify";
import NotificationToast from "../toasts/notification.toast.tsx";
import {NotificationType} from "../../enums/notification.enum.ts";
import {setTfaEnabled} from "../../../store/user.store.ts";

type TFAOffModalProps = {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}

const TFAOffModal: FC<TFAOffModalProps> = ({show, setShow}) => {
    const dispatcher: AppDispatch = useDispatch();
    const [code, setCode] = useState<string>('');
    const [offTfa, offTfaData] = useMutation(TFA_OFF_MUTATION);

    useEffect(() => {
        if (offTfaData.data) {
            const {offTFA} = offTfaData.data;

            if (offTFA) {
                toast(<NotificationToast type={NotificationType.SUCCESS} title="TFA"
                                         message="Successfully disabled"/>, {
                    toastId: 'off-tfa-success'
                });

                dispatcher(setTfaEnabled(false));
                setShow(false);
            }
        }
    }, [dispatcher, offTfaData.data, setShow]);

    useEffect(() => {
        if (offTfaData.error) {
            toast(<NotificationToast type={NotificationType.WARNING} title="TFA"
                                     message={offTfaData.error.message} />, {
                toastId: 'off-tfa-error'
            });
        }
    }, [offTfaData.error]);

    const validateTFAHandler = useCallback(async () => {
        await offTfa({
            variables: {code}
        })
    }, [code, offTfa]);

    return (
        <>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-80"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"></div>
            </Transition>
            <Transition
                as={Fragment}
                show={show}
                enter="transition ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
            >
                <div
                    className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6">
                    <div onClick={(e) => e.stopPropagation()}
                         className="bg-white rounded shadow-lg overflow-auto max-w-lg w-full max-h-full">
                        {/* Modal header */}
                        <div className="p-4">
                            <div className="text-lg font-semibold text-slate-800">Disabled TFA</div>
                        </div>
                        <div className='divide-y'>
                            <div></div>
                            <div></div>
                        </div>
                        {/* Modal content */}
                        <div className="p-4 pb-0">
                            <div className="col-span-6 form-control w-full mt-4">
                                <input
                                    id='tfa-code'
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="TFA Code"
                                    className='form-input w-full max-w-[646px] mr-1'
                                    type="text"
                                />
                            </div>
                        </div>
                        {/* Modal footer */}

                        <div className="flex justify-end p-4">
                            <button
                                onClick={() => setShow(false)}
                                className='bg-white border text-gray-700 hover:bg-gray-100 hover btn-sm whitespace-nowrap mr-2'
                            >
                                Cancel
                            </button>
                            <button
                                className={`${!code && 'opacity-40'} bg-indigo-500 hover:bg-indigo-600 btn-sm text-white whitespace-nowrap`}
                                disabled={!code}
                                onClick={validateTFAHandler}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default TFAOffModal;
