import {FC} from "react";
import NetworkItem from "../../common/components/networks/network-item.component.tsx";
import {useSelector} from "react-redux";
import {networksSelector} from "../../store/networks.store.ts";
import {NetworkInfo} from "../../common/types/networks/networks.type.ts";

const NetworksPage: FC = () => {
    const {blockchains} = useSelector(networksSelector);

    return (
        <main>
            <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
                <div className="sm:flex sm:justify-between sm:items-center">
                    <div className='flex items-center'>
                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mr-4">Networks</h1>
                    </div>
                </div>

            </div>

            <div className="px-4 sm:px-6 lg:px-8 pb-8 pt-4 w-full max-w-9xl mx-auto grid grid-cols-4 gap-2">
                {blockchains.map((item: NetworkInfo, i: number) => (
                    <NetworkItem
                        key={i}
                        name={item.name}
                        fee={item.fee}
                        nativeSymbol={item.nativeSymbol}
                        tetherSymbol={item.tetherSymbol}
                        networkName={item.networkName}
                        explorerLink={item.explorerLink}
                        contractAddress={item.contractAddress}
                        faucetCoinLink={item.faucetCoinLink}
                        faucetTokenLink={item.faucetTokenLink}
                    />
                ))}
            </div>
        </main>
    );
};

export default NetworksPage;
