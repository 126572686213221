import {FC, useCallback, useEffect, useState} from "react";
import {
    ACCOUNT_PAGE_DESCRIPTION,
    ACCOUNT_PAGE_TITLE,
    API_KEY_TITLE,
    MASTER_ADDRESSES_DESC,
    MASTER_ADDRESSES_TITLE, SECRET_KEY_TITLE
} from "../../common/constants/account.constant.ts";
import {useDispatch, useSelector} from "react-redux";
import {setApiKey, setMasterAddresses, userSelector} from "../../store/user.store.ts";
import {useCopy} from "../../common/hooks/copy.hook.ts";
import {QueryResult, useMutation, useQuery} from "@apollo/client";
import {UPDATE_API_KEY_MUTATION} from "../../api/graphql/mutations/update-api-key.mutation.ts";
import {UpdateApiKeyResponseType} from "../../common/types/account/update-api-key.type.ts";
import {AppDispatch} from "../../store";
import {getIconByCurrency} from "../../common/utils/currency.util.ts";
import {MASTER_ADDRESSES_QUERY} from "../../api/graphql/queries/master-addresses.query.ts";
import {
    GetMasterAddressesResponseType,
    GetMasterAddressType
} from "../../common/types/account/master-addresses.type.ts";
import MasterAddressModal from "../../common/components/modals/master-address.modal.tsx";
import {WalletType} from "../../common/enums/wallet-type.enum.ts";
import {isError} from "../../common/helpers/apollo.helper.ts";
import {ErrorEnum} from "../../common/enums/error.enum.ts";
import {toast} from "react-toastify";
import NotificationToast from "../../common/components/toasts/notification.toast.tsx";
import {NotificationType} from "../../common/enums/notification.enum.ts";
import {Position} from "../../common/enums/position.enum.ts";
import Tooltip from "../../common/components/tooltip/tooltip.component.tsx";
import UpdateSecretKeyModal from "../../common/components/modals/update-secret-key.modal.tsx";



const AccountPage: FC = () => {
    const dispatcher: AppDispatch = useDispatch();

    const {profile: {apiKey}, masterAddresses} = useSelector(userSelector);
    const [isCopiedApiKey, copyApiKey] = useCopy(1000);
    const [isCopiedSecretKey, copySecretKey] = useCopy(1000);

    const [updateApiKey, updateApiKeyData] = useMutation<UpdateApiKeyResponseType>(UPDATE_API_KEY_MUTATION);
    const [showChangeMasterAddressModal, setShowChangeMasterAddressModal] = useState<boolean>(false);
    const [showUpdateSecretModal, setShowUpdateSecretModal] = useState<boolean>(false);
    const [secretKey, setSecretKey] = useState<string>('');
    const [masterAddressType, setMasterAddressType] = useState<WalletType>();

    const getMasterAddresses: QueryResult<GetMasterAddressesResponseType> = useQuery<GetMasterAddressesResponseType>(MASTER_ADDRESSES_QUERY);

    const updateApiKeyHandler = useCallback(async () => {
        await updateApiKey()
    }, [updateApiKey]);

    const copyApiKeyHandler = useCallback(async () => {
        await copyApiKey(apiKey);
    }, [copyApiKey, apiKey]);

    const copySecretKeyHandler = useCallback(async () => {
        await copySecretKey(secretKey);
        setSecretKey('');
    }, [copySecretKey, secretKey]);

    const changeCoinAddress = useCallback(async (address: GetMasterAddressType) => {
        setMasterAddressType(address.type);
        setShowChangeMasterAddressModal(true)
    }, []);

    useEffect(() => {
        if (!showChangeMasterAddressModal) {
            setMasterAddressType(undefined)
        }
    }, [showChangeMasterAddressModal])

    useEffect((): void => {
        if (updateApiKeyData.error) {
            if (isError(updateApiKeyData.error, ErrorEnum.TFA_CODE_REQUIRED)) {
                console.log('TFA REQUIRED')
            } else {
                toast(<NotificationToast type={NotificationType.ERROR} title="Update API KEY"
                                         message={updateApiKeyData.error.message}/>, {
                    toastId: 'update-api-key-bad-request'
                });
            }
        }
    }, [updateApiKeyData.error]);

    useEffect((): void => {
        if (isCopiedSecretKey) {
            toast(<NotificationToast type={NotificationType.INFO} title="Secret Key" message="Copied!" />, {
                toastId: 'copy-secret-key'
            });
        }
    }, [isCopiedSecretKey]);

    useEffect((): void => {
        if (updateApiKeyData.data) {
            dispatcher(setApiKey(updateApiKeyData.data.updateApiKey.apiKey))
        }
    }, [dispatcher, updateApiKeyData.data]);

    useEffect(() => {
        if (getMasterAddresses.data) {
            const response: GetMasterAddressType[] = getMasterAddresses.data.getMasterAddresses
            dispatcher(setMasterAddresses(response));
        }
    }, [getMasterAddresses, dispatcher]);

    const handleCopy = useCallback(() => {
        toast(<NotificationToast type={NotificationType.INFO} title="Secret Key" message="Copied!" />, {
            toastId: 'copy-secret-key'
        });
        setSecretKey('')
    }, []);

    return (
        <main>
            <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center">
                    {/* Left: Title */}
                    <div className='flex items-center'>
                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mr-4">{ACCOUNT_PAGE_TITLE}</h1>
                    </div>
                </div>

            </div>
            <div className="px-4 sm:px-6 lg:px-8 pb-8 pt-4 w-full max-w-9xl mx-auto">
                <p>{ACCOUNT_PAGE_DESCRIPTION}</p>
                <div className="w-full">
                    <div className='mt-8 w-full'>
                        <h1 className="text-xl md:text-2xl text-slate-800 font-bold mb-3">{API_KEY_TITLE}</h1>
                        <label className="block text-sm font-medium mb-1" htmlFor="api_key">{API_KEY_TITLE}:</label>
                        <div className="relative flex items-center">
                            {apiKey &&
                                <button
                                    className="absolute inset-0 right-auto group ml-3"
                                    aria-label="copy"
                                    onClick={copyApiKeyHandler}
                                >
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.99999 0.916626C2.44746 0.916626 1.91755 1.13612 1.52685 1.52682C1.13615 1.91752 0.916656 2.44743 0.916656 2.99996V8.33329C0.916656 8.88583 1.13615 9.41573 1.52685 9.80643C1.91755 10.1971 2.44746 10.4166 2.99999 10.4166H3.58332V11C3.58332 12.1506 4.51606 13.0833 5.66666 13.0833H11C12.1506 13.0833 13.0833 12.1506 13.0833 11V5.66663C13.0833 4.51603 12.1506 3.58329 11 3.58329H10.4167V2.99996C10.4167 2.44742 10.1972 1.91752 9.80646 1.52682C9.41576 1.13612 8.88586 0.916626 8.33332 0.916626H2.99999ZM8.91666 3.58329V2.99996C8.91666 2.84525 8.8552 2.69688 8.7458 2.58748C8.63641 2.47808 8.48803 2.41663 8.33332 2.41663H2.99999C2.84528 2.41663 2.69691 2.47808 2.58751 2.58748C2.47811 2.69688 2.41666 2.84525 2.41666 2.99996V8.33329C2.41666 8.488 2.47811 8.63638 2.58751 8.74577C2.69691 8.85517 2.84528 8.91663 2.99999 8.91663H3.58332V5.66663C3.58332 4.51603 4.51606 3.58329 5.66666 3.58329H8.91666ZM5.08332 9.66663V11C5.08332 11.3221 5.34449 11.5833 5.66666 11.5833H11C11.3222 11.5833 11.5833 11.3221 11.5833 11V5.66663C11.5833 5.34446 11.3222 5.08329 11 5.08329H9.66666H5.66666C5.34449 5.08329 5.08332 5.34446 5.08332 5.66663V9.66663Z"
                                              fill="#6366F1"/>
                                    </svg>
                                </button>
                            }
                            <input
                                id='api_key'
                                placeholder='API Key'
                                className={`form-input w-full sm:max-w-[646px] mr-1 ${apiKey && 'pl-10'} overflow-hidden text-ellipsis whitespace-nowrap`}
                                type="text"
                                value={apiKey}
                                disabled
                            />
                            <Tooltip title="Create a new api key" message="" position={Position.TOP}>
                                <button
                                    onClick={updateApiKeyHandler}
                                    disabled={updateApiKeyData.loading}
                                    className={`btn ${updateApiKeyData.loading ? 'disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed shadow-none' : ''} bg-indigo-500 border-slate-200 hover:border-slate-300 text-white w-full sm:w-auto`}
                                >
                                    <p className="text-xs sm:text-sm">Generate New</p>
                                </button>
                            </Tooltip>
                            {updateApiKeyData.loading &&
                                <svg className="hidden sm:visible animate-spin ml-2 w-4 h-4 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"/>
                                </svg>
                            }
                        </div>

                        {isCopiedApiKey &&
                            <div className="relative max-w-[645px]">
                                <p className='transition-all absolute text-green-500 text-xs right-0 top-0'>API Key was copied</p>
                            </div>
                        }
                    </div>
                    <div className='mt-8 w-full'>
                        <h1 className="text-xl md:text-2xl text-slate-800 font-bold mb-3">{SECRET_KEY_TITLE}</h1>
                        <div className="relative flex items-center">
                            {secretKey &&
                                <>
                                    <button
                                        className="absolute inset-0 right-auto group ml-3"
                                        aria-label="Search"
                                        onClick={copySecretKeyHandler}
                                    >
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M2.99999 0.916626C2.44746 0.916626 1.91755 1.13612 1.52685 1.52682C1.13615 1.91752 0.916656 2.44743 0.916656 2.99996V8.33329C0.916656 8.88583 1.13615 9.41573 1.52685 9.80643C1.91755 10.1971 2.44746 10.4166 2.99999 10.4166H3.58332V11C3.58332 12.1506 4.51606 13.0833 5.66666 13.0833H11C12.1506 13.0833 13.0833 12.1506 13.0833 11V5.66663C13.0833 4.51603 12.1506 3.58329 11 3.58329H10.4167V2.99996C10.4167 2.44742 10.1972 1.91752 9.80646 1.52682C9.41576 1.13612 8.88586 0.916626 8.33332 0.916626H2.99999ZM8.91666 3.58329V2.99996C8.91666 2.84525 8.8552 2.69688 8.7458 2.58748C8.63641 2.47808 8.48803 2.41663 8.33332 2.41663H2.99999C2.84528 2.41663 2.69691 2.47808 2.58751 2.58748C2.47811 2.69688 2.41666 2.84525 2.41666 2.99996V8.33329C2.41666 8.488 2.47811 8.63638 2.58751 8.74577C2.69691 8.85517 2.84528 8.91663 2.99999 8.91663H3.58332V5.66663C3.58332 4.51603 4.51606 3.58329 5.66666 3.58329H8.91666ZM5.08332 9.66663V11C5.08332 11.3221 5.34449 11.5833 5.66666 11.5833H11C11.3222 11.5833 11.5833 11.3221 11.5833 11V5.66663C11.5833 5.34446 11.3222 5.08329 11 5.08329H9.66666H5.66666C5.34449 5.08329 5.08332 5.34446 5.08332 5.66663V9.66663Z"
                                                  fill="#6366F1"/>
                                        </svg>
                                    </button>

                                    <input
                                        id='secret_key'
                                        placeholder='Secret Key'
                                        className={`form-input w-full sm:max-w-[646px] mr-1 ${apiKey && 'pl-10'} overflow-hidden text-ellipsis whitespace-nowrap`}
                                        type="text"
                                        onCopy={handleCopy}
                                        value={secretKey}
                                        disabled
                                    />
                                </>
                            }
                            <Tooltip title="Create a new secret key" message="" position={Position.TOP}>
                                <button
                                    onClick={() => setShowUpdateSecretModal(true)}
                                    className="btn shadow-none bg-indigo-500 border-slate-200 hover:border-slate-300 text-white w-full sm:w-auto"
                                >
                                    <p className="text-xs sm:text-sm">Generate New</p>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='mt-8 w-full'>
                        <h1 className="text-xl md:text-2xl text-slate-800 font-bold mb-3">{MASTER_ADDRESSES_TITLE}</h1>
                        <div className="text-sm mt-1 italic max-w-[640px] mb-3">{MASTER_ADDRESSES_DESC}</div>
                        <div className='w-full max-w-[768px]'>
                            {getMasterAddresses.loading
                                ? <div className='flex items-center'>
                                    <svg className="text-center animate-spin w-4 h-4 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                    </svg>
                                </div>
                                : masterAddresses.map((address, i) => (
                                    <div key={i} className='bg-white w-full flex items-center justify-between p-3 mb-2 rounded border transition-all shadow overflow-hidden'>
                                        <div className='flex items-center overflow-hidden'>
                                            <div className='mr-4'>
                                                <img className="w-12 h-12 rounded-full" src={getIconByCurrency(address.currency)} alt={address.currency} />
                                            </div>
                                            <div className="flex-col text-left overflow-hidden">
                                                <div className='text-gray-700 font-medium m-0'>{address.type}</div>
                                                {address.address
                                                    ?  <div className='text-sm text-gray-700 m-0 font-medium overflow-hidden text-ellipsis whitespace-nowrap'>
                                                        {address.address}
                                                    </div>
                                                    :  <div className='text-sm text-gray-400 m-0'>No Address</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            {!address.address
                                                ? <button onClick={() => changeCoinAddress(address)} className={`${!address.isActive ? 'opacity-70 cursor-not-allowed' : ''} btn bg-green-500 hover:bg-green-600 border-slate-200 text-white`} disabled={!address.isActive || getMasterAddresses.loading}>Add Address</button>
                                                : <button onClick={() => changeCoinAddress(address)} className={`${!address.isActive ? 'opacity-70 cursor-not-allowed' : ''} btn border-slate-200 hover:bg-slate-100 text-indigo-500`} disabled={!address.isActive || getMasterAddresses.loading}>Change</button>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>
            </div>

            {/*<SafePasswordModal show={showSafePasswordModal} setShow={setShowSafePasswordModal} setShowChangeMasterAddressModal={setShowChangeMasterAddressModal}/>*/}
            <MasterAddressModal show={showChangeMasterAddressModal} setShow={setShowChangeMasterAddressModal} type={masterAddressType} />
            <UpdateSecretKeyModal show={showUpdateSecretModal} setShow={setShowUpdateSecretModal} setSecretKey={setSecretKey} />
        </main>
    );
};

export default AccountPage;
